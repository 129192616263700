import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { RetailUserService } from './retailuser.service';
import { Observable } from 'rxjs';


export enum Reports {
    OnRent = 2,
    OnRentWithCostAnalysis = 3,
    OffRent = 4,
    ActualCostToDate = 9,
    CustomerBalance = 15,
    ActiveMaintenance = 21,
    HistoricalMaintenance = 24,
    RentToDateAndProjected = 37,
    PlannedVsActual = 43,
    Reservation = 44
}

@Injectable()
export class ReportService {
    private static reportRestrictions: Array<any> = null;

    custUserSubscription: any;
    private loggedIn = false;
    constructor(private retailCustomerService: RetailUserService) {
        this.custUserSubscription = this.retailCustomerService.loggedIn$.subscribe(
            data => {
                this.loggedIn = data;

                if (this.loggedIn) {
                    ReportService.reportRestrictions = this.retailCustomerService.retailUser['BirchReports'];
                }
            });
    }


    // check if the report is visible to the users
    // waits for report restrictions to load
    public checkReportVisiblity(reportID): boolean {
        let isVisible = true;
        if (ReportService.reportRestrictions !== null) {
            if (ReportService.reportRestrictions.filter(item => item.BirchReportID === reportID).length > 0) {
                isVisible = false;
            }
        }
        return isVisible;
    }

    gridContents(cols, rows): string {
        let csv = '';
        let timeRegX = new RegExp('T[0-9][0-9]:[0-9][0-9]:[0-9][0-9](\.([0-9]{0,3})){0,1}');
        for (let i = 0; i < cols.length; i++) {
            if (cols[i].header) {
                csv += cols[i].header;
                if (i < (cols.length - 1)) {
                    csv += ',';
                }
            }
        }

        // body
        rows.forEach(row => {
            csv += '%0D%0A';
            for (let i = 0; i < cols.length; i++) {
                let field = cols[i].field;
                let styleClass = cols[i].styleClass;

                if (field) {
                    let colData = row[field];
                    if (colData == null || colData === undefined) {
                        colData = '';
                    }
                    else if (typeof colData === 'string') {
                        let strData = String(colData);
                        colData = strData.split(',').join('');      // strip out the commas
                        colData = colData.replace(timeRegX, '');    // Strip the times off
                    }
                    else if (typeof colData === 'number') {
                        if (styleClass === 'product-money-col') {
                            // Format as $
                            colData = new CurrencyPipe('USD').transform(colData, 'USD', true, '1.2-2');
                            colData = colData.split(',').join('');  // remove the commas from $ excel will put htem back
                        }
                    }
                    // add the data to the file
                    csv += colData;
                    if (i < (cols.length - 1)) {
                        csv += ',';
                    }
                }
            }
        });
        return csv;
    }

    public exportData(cols, rows, csvName) {
        let link = document.createElement('a');
        link.setAttribute('data', 'text/csv;charset=utf-8');
        link.setAttribute('download', csvName);
        link.href = 'data:,' + this.gridContents(cols, rows);
        link.click();
    }
}
