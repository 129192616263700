import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { ItemsRoutes } from './items/items.routes';

import { LoginComponent } from './login';
import { CreateAccountComponent } from './create-account';
import { OrderHistoryComponent } from './order-history';
import { CartComponent } from './cart';
import { DeliveryComponent } from './cart/cart-delivery';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { ItemsComponent } from './items';
//import { PlanNewComponent } from './+plan/+plan-new';
import { SpecialsComponent } from './specials';
//import { ReportComponent } from './+report';
import { TrainComponent } from './+train';
import { TrainingRequestComponent } from './+train/training-request';
//import { MaintenanceComponent } from './+maintenance';
import { MaintenanceRequestComponent } from './+maintenance/+maintenance-request';
import { PartsRequestComponent } from './parts-request';
import { PurchaseQuoteComponent } from './purchase-quote';

import { RetailUserService } from './service/retailuser.service';
import { AuthGuard} from './app.auth.guard';
import { CanDeactivateGuard} from './app.deactivate.guard';
import {UsedComponent} from "./used/used.component";

export const AUTH_PROVIDERS = [AuthGuard, CanDeactivateGuard, RetailUserService];
export const routes: Routes = [
  { path: '', component: ItemsComponent },
  { path: 'product_qc', component: ItemsComponent },

  { path: 'login', component: LoginComponent },
  { path: 'create-account', component: CreateAccountComponent },
  { path: 'order-history', component: OrderHistoryComponent },
  { path: 'cart', component: CartComponent}, //, canActivate: [AuthGuard]
  { path: 'delivery', component: DeliveryComponent },

  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'specials', component: SpecialsComponent },
  { path: 'used', component: UsedComponent },
  { path: 'train', component: TrainComponent },
  { path: 'training-request', component: TrainingRequestComponent },
  { path: 'maintenance-request', component: MaintenanceRequestComponent},
  { path: 'parts-request', component: PartsRequestComponent },
  { path: 'purchase-quote', component: PurchaseQuoteComponent },
  ...ItemsRoutes,

  //{ path: 'plan/:view', component: PlanComponent },
   { path: '**', component: ItemsComponent }
];

export const routing = RouterModule.forRoot(routes);
