import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
// import { FORM_DIRECTIVES } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { Item, ItemService } from '../../service/item.service';
import { MessagebarService } from '../../service/messagebar.service';
// import { BsModalComponent } from 'ng2-bs3-modal';
import { NewItems } from '../new-item';
import { CartItem, CartItemService } from '../../service/cart.service';

import { MatButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatInput } from '@angular/material/input';
import { MatSpinner } from '@angular/material/progress-spinner';

import { Subscription } from 'rxjs';
import { AppComponent } from '../../app.component';
import { ProjectItem } from '../../service/project.service';
import { RetailUserService } from '../../service/retailuser.service';

import { BirchErrorHandler } from '../../error.module';
import { UnlistedEquipmentDialog } from './unlisted-equipment-dialog/unlisted-equipment-dialog';
import { MatDialog } from '@angular/material';

@Component({
	selector: 'app-all',
	templateUrl: './all.component.html',
	styleUrls: ['./all.component.scss']
})

export class ItemsAllComponent implements OnInit {
	static URLcat = '';
	static URLdep = '';
	static URLgrp = '';
	static URLsearch = '';

	// @ViewChild('AddOtherModal', { static: false }) AddOtherModal: BsModalComponent;
	// public searchTerm: string;
	@Input() searchTerm: string;
	// public searchTermChange = new EventEmitter();
	@Output() serchTermChange = new EventEmitter();

	public items: Array<Item>;
	public searchedItems: Array<Item>;
	public categories: Array<any>;
	public departments: Array<any>;
	public groups: Array<any>;
	public groupsDefault: Array<any>;

	public otherItem: Item;
	public otherProjectItem: ProjectItem;

	public page = 1;
	// public length: number;
	public itemsPerPage = 40;

	public category: any;
	public department: any;
	public group: any;

	public categoryUpdate: any;

	public refreshed: boolean = NewItems.refreshed;
	public searchTerms: string[] = [];

	private isBusy = false;   // show the loading spinner
	private projectExists = false;  // do we have a project or are we just browsing

	private subscription: Subscription;

	// subscription: any;

	constructor(
		public router: Router,
		private activatedRoute: ActivatedRoute,
		private itemService: ItemService,
		private retailUserService: RetailUserService,
		private cartItemService: CartItemService,
		private msgBar: MessagebarService,
		private location: PlatformLocation,
		private birchErrorHandler: BirchErrorHandler,
		private dialog: MatDialog,
	) {
		this.router.events.subscribe((event) => { this.URL_Relocate(); });
		// this.reloadLocation();
		this.items = NewItems.currentItems;
		this.searchedItems = NewItems.searchedItems;
		this.categories = NewItems.categories;
		this.departments = new Array();
		this.groups = new Array();
		this.otherItem = new Item('RENTAL', -1, '', '', -1, '', '', '', '', '-1', null, null, '-1', false, false, false, false, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
		this.otherProjectItem = new ProjectItem(null, '', 1, 1, new Date(), new Date(), '', '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '', '', '', '', '', -1, false, [], [], 0, '', 0, 0, 0);

		this.categoryUpdate = NewItems.categoryUpdate;
		this.subscription = NewItems.categoryChange.subscribe(category => this.setAndOpenCategory(NewItems.category));

		NewItems.setCategory({
			CategoryNbr: 0,
			CategoryNm: 'All Categories'
		});
	}

	get searchTermModel() {
		return this.searchTerm;
	}

	set searchTermModel(value) {
		this.serchTermChange.emit(value);
		this.searchTerm = value;
	}

	ngOnInit() {
		NewItems.viewedItem = null;

		try {
			// Grab URL Parameters and Relocate my position from URL Parameters
			this.subscription = this.activatedRoute.params.subscribe(
				(param: any) => {
					ItemsAllComponent.URLcat = param['cat'];
				});
			this.subscription = this.activatedRoute.params.subscribe(
				(param: any) => {
					ItemsAllComponent.URLdep = param['dep'];
				});
			this.subscription = this.activatedRoute.params.subscribe(
				(param: any) => {
					ItemsAllComponent.URLgrp = param['grp'];
				});
			this.subscription = this.activatedRoute.params.subscribe(
				(param: any) => {
					ItemsAllComponent.URLsearch = param['search'];
				});

			// if we have already loaded then call this here
			if (NewItems.refreshed === true) {
				this.URL_Relocate();
			}

			// If Side category nav is hit
			let navCat = sessionStorage.getItem('navCategory');
			if (navCat !== undefined && navCat !== null && navCat !== 'undefined') {
				this.clearSearch();
				this.setAndOpenCategory(JSON.parse(navCat));
				sessionStorage.removeItem('navCategory');
			}

			// Relocate my position from "Back" button on item detail's page
			if (JSON.parse(sessionStorage.getItem('item')) != null && JSON.parse(sessionStorage.getItem('itemDepth')) != null) {

				if (JSON.parse(sessionStorage.getItem('itemDepth')) >= 2
					&& JSON.parse(sessionStorage.getItem('item')).CategoryNbr !== undefined
					&& JSON.parse(sessionStorage.getItem('item')).CategoryNbr != null) {
					this.setCategory(JSON.parse(sessionStorage.getItem('item')));
				}
				if (JSON.parse(sessionStorage.getItem('itemDepth')) >= 3
					&& JSON.parse(sessionStorage.getItem('item')).DepartmentNbr !== undefined
					&& JSON.parse(sessionStorage.getItem('item')).DepartmentNbr != null) {
					let department = JSON.parse(sessionStorage.getItem('item'));
					this.setDepartment(department);
				}
				if (JSON.parse(sessionStorage.getItem('itemDepth')) >= 4
					&& JSON.parse(sessionStorage.getItem('item')).ItemGroupNm !== undefined
					&& JSON.parse(sessionStorage.getItem('item')).ItemGroupNm != null) {
					this.setGroup(JSON.parse(sessionStorage.getItem('item')));
				}

				sessionStorage.removeItem('item');
				sessionStorage.removeItem('itemDepth');

				if (this.group) {
					this.openGroup();
				} else if (this.department) {
					this.openDepartment();
				} else if (this.category) {
					this.openCategory();
				}
			}
		} catch (error) {
			let msg = 'Error loading items form ';
			console.log(<any>error);
			if (typeof error === 'string' && error.length > 0) {
				msg += error;
			}
			this.msgBar.addAlert(msg, 'danger');
		}

		//
		if (this.itemService.isLoaded) {
			this.Loading();
		} else {
			this.itemService.loaded$.subscribe(
				data => {
					this.Loading();
				});
		}
	}

	Loading() { // {Check For Items Arrival, Refresh When Available}
		if (NewItems.categories.length > 1 && NewItems.refreshed === false) { // NewItems Is Static Class Holding Items/Categorys/Departments/Groups
			this.items = NewItems.currentItems; // Set Fields With Values
			this.searchedItems = NewItems.searchedItems;
			this.categories = NewItems.categories;
			this.departments = new Array();
			this.groups = new Array();

			this.categoryUpdate = NewItems.categoryUpdate;
			this.subscription = NewItems.categoryChange.subscribe(category => this.setAndOpenCategory(NewItems.category));

			NewItems.refreshed = true; // Stop All Loops From Refreshing Again Some Do Not Have Timers (My Poor Design Choice)
			this.refreshed = true;

			this.URL_Relocate();
		}
	}

	URL_Relocate() { // Relocate my position from URL Parameters
		let category = null;
		let department = null;
		let group = null;

		if (ItemsAllComponent.URLcat !== undefined) {
			category = NewItems.categories.find(item => item.CategoryNm.localeCompare(ItemsAllComponent.URLcat) === 0);
		}

		if (ItemsAllComponent.URLdep !== undefined) {
			department = NewItems.departments.find(item => item.DepartmentNm.localeCompare(ItemsAllComponent.URLdep) === 0);
		}

		if (ItemsAllComponent.URLgrp !== undefined) {
			group = NewItems.groups.find(item => item.GroupNm.localeCompare(ItemsAllComponent.URLgrp) === 0);
		}

		this.setCategory(category);
		this.setDepartment(department);
		this.setGroup(group);

		this.searchTerms.length = 0;
		if (ItemsAllComponent.URLsearch !== undefined) {
			let searchTerms = ItemsAllComponent.URLsearch.split(',');
			for (let term of searchTerms) {
				this.searchTerm = term;
				this.addTerm();
			}
		}

		this.changeSearch();

		this.scrollToTop();
	}

	public openItem(item: Item) { // {Html Inner Router, Pass Key In Address For Page To Find Item}
		this.router.navigate(['/items/' + item.ItemKey]);
	}

	public openPrintableUsed() { // {Html Inner Router, Pass Key In Address For Page To Find Item}
		this.router.navigate(['/used']);
	}

	setAndOpenGroup(group: any) {
		this.setGroup(group);
		this.openGroup();
	}

	setAndOpenDepartment(department: any) {
		this.setDepartment(department);
		this.openDepartment();
	}

	setAndOpenCategory(category: any) {
		this.setCategory(category);
		this.openCategory();
	}

	public openGroup() {
		if (this.group && this.category && this.department) {
			this.router.navigate(['/items',
				{ cat: this.category.CategoryNm, dep: this.department.DepartmentNm, grp: this.group.ItemGroupNm || this.group.GroupNm }]);
		}
	}

	public openDepartment() {
		if (this.category && this.department) {
			this.router.navigate(['/items',
				{ cat: this.category.CategoryNm, dep: this.department.DepartmentNm }]);
		}
	}

	public openCategory() {
		if (this.category) {
			this.router.navigate(['/items', { cat: this.category.CategoryNm }]);
		} else {
			this.router.navigate(['/items']);
		}
	}

	public setGroup(group: any) { // {NewItems Holds Cat/Dep/Grp And Functions That Filter Them}
		// if (typeof group === 'string') { // Using Item's Group:StringValue From Item Page To Return To It's Group. LocalStorage
		//   NewItems.setGroup(group, null, null);
		// } else {
		this.group = group;

		if (!group) {
			return this.group;
		}

		NewItems.setCategory(this.category);
		NewItems.setDepartment(this.department.DepartmentNbr, this.category.CategoryNbr);

		if (group.ItemGroupNm) {
			// Other Wise The Group Object Is Passed By The Html Page
			NewItems.setGroup(this.group.ItemGroupNm, this.department.DepartmentNbr, this.category.CategoryNbr);
		} else {
			NewItems.setGroup(this.group.GroupNm, this.department.DepartmentNbr, this.category.CategoryNbr);
		}

		// }
		return this.group;
	}

	public setDepartment(department: any) { // {NewItems Holds Cat/Dep/Grp And Functions That Filter Them}
		this.group = null; // Filter Checks All Cat>Dep>Grp So Remove Group To Stop At Dep.
		this.department = department; // Target Department

		if (!this.department) {
			return this.department;
		}

		if (this.department.DepartmentNbr !== 0) { // If 0 The Filter Ignors The Dep And Returns All Cat Items (No FIlter)
      this.groups = new Array();
      for (let i = 0; i < NewItems.groups.length; i++) {
        if (NewItems.groups[i].DepartmentNbr === this.department.DepartmentNbr && NewItems.groups[i].CategoryNbr === this.category.CategoryNbr) {
          this.groups.push(NewItems.groups[i]);
        }
      }
    }

    NewItems.setCategory(this.category);
    NewItems.setDepartment(this.department.DepartmentNbr, this.category.CategoryNbr);

    return this.department;
  }

  public setCategory(category: any) { // {NewItems Holds Cat/Dep/Grp And Functions That Filter Them}
    this.group = null; // Filter Checks All Cat>Dep>Grp So Remove Group&Dep To Stop At Category.
    this.department = null;
    this.category = category;

    if (!this.category) {
      return this.category;
    }

    if (this.category.CategoryNbr !== 0) { // If 0 The Filter Ignors The Cat And Returns All Items (No FIlter)
      this.departments = new Array();
      for (let i = 0; i < NewItems.departments.length; i++) {
        if (NewItems.departments[i].CategoryNbr === this.category.CategoryNbr) {
          this.departments.push(NewItems.departments[i]);
        }
      }
    }

    NewItems.setCategory(this.category);

    return this.category;
  }


  isUsedCategory() {
    if (this.category) {
      return this.category.CategoryNm === 'Sales - Used Equipment';
    }
  }


  scrollToTop() {
    document.getElementById('SMS_TOOLBAR').scrollIntoView();

    /*TODO: get the smooth scrolling working
				let to = document.getElementById('SMS_TOOLBAR').offsetTop;
				let duration = 100;
				if (document.body.scrollTop == to) return;

				let start = document.body.scrollTop;
				let diff = to - start;
				let scrollStep = Math.PI / (duration / 10);
				let count = 0, currPos;

				let scrollInterval = setInterval(function () {
					if (document.documentElement.scrollTop !== to && currPos < document.documentElement.scrollHeight) {
						count = count + 1;
						currPos = start + diff * (0.5 - 0.5 * Math.cos(count * scrollStep));
						document.documentElement.scrollTop = currPos;
					} else {
						clearInterval(scrollInterval);
					}
				}, 10);*/
  }

  public changeSearch() { // {Sends Search Terms To Search Filter}
    try {
      NewItems.setSearch(this.searchTerms);
    } catch (error) {
      this.birchErrorHandler.handleError(error)
    }
  }

  public addTermAndRefresh() {
    if (this.searchTerm != null || this.searchTerm !== undefined) {
      try {
        this.addTerm();
        this.RefreshURL();
      } catch (error) {
        this.birchErrorHandler.handleError(error)
      }
    }
  }

  public RefreshURL() {
    if (this.searchTerms.length > 0) {
      if (this.group) {
        this.router.navigate(['/items',
          {
            cat: this.category.CategoryNm,
            dep: this.department.DepartmentNm,
            grp: this.group.ItemGroupNm || this.group.GroupNm,
            search: this.searchTerms
          }]);
      } else if (this.department) {
        this.router.navigate(['/items',
          { cat: this.category.CategoryNm, dep: this.department.DepartmentNm, search: this.searchTerms }]);
      } else if (this.category) {
        this.router.navigate(['/items',
          { cat: this.category.CategoryNm, search: this.searchTerms }]);
      } else {
        this.router.navigate(['/items',
          { search: this.searchTerms }]);
      }
    } else {
      if (this.group) {
        this.router.navigate(['/items',
          {
            cat: this.category.CategoryNm,
            dep: this.department.DepartmentNm,
            grp: this.group.ItemGroupNm || this.group.GroupNm
          }]);
      } else if (this.department) {
        this.router.navigate(['/items',
          { cat: this.category.CategoryNm, dep: this.department.DepartmentNm }]);
      } else if (this.category) {
        this.router.navigate(['/items',
          { cat: this.category.CategoryNm }]);
      } else {
        this.router.navigate(['/items']);
      }
    }
  }

  public addTerm() {
    this.searchTerm = this.searchTerm.replace(/[^a-zA-Z0-9 ]/g, '');
    // this.searchTerm = this.searchTerm.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'');
    if (this.searchTerm !== '' && this.searchTerm !== undefined) {
      let searchTerms = this.searchTerm.split(' ');
      for (let search of searchTerms) {
        if (search !== '') {
          this.searchTerms.push(search); // Add Term
        }
      }
      this.searchTerm = ''; // Clears User Field
    }
  }

  public clearSearch() {
    while (this.searchTerms.length > 0) {
      this.searchTerms.pop();
    }
    this.changeSearch();
  }

  public removeTerm(index: number) {
    // console.log('Removing Search Term: ' + this.searchTerms[index]);
    this.searchTerms.splice(index, 1);
    this.RefreshURL();
  }

  public pageRefresh(next: any) { // Paging That Handles What Items Are Shown For What Page # You're On (Check => NewItems.setPage)
    if (next != null) {
      if (next === -1 && this.page > 1) { this.page -= 1; }
      if (next === -2 && this.page < NewItems.searchedItems.length / this.itemsPerPage) { this.page += 1; }
      if (next === -3) { this.page = 1; }
      if (next === -4) { this.page = Math.ceil(NewItems.searchedItems.length / this.itemsPerPage); }
      if (next > 0) { this.page = next; }
    }

    NewItems.setPage(this.page - 1, this.itemsPerPage);
    if (document.getElementById('pageNumber') != null) { // Refresh Html Item
      document.getElementById('pageNumber').textContent = 'Page: ' + this.page + ' / ' + Math.ceil(NewItems.searchedItems.length / this.itemsPerPage);
    }
  }

  public thisPage() {
    document.getElementById('pageNumber').textContent = 'Page: ' + this.page + ' / ' + Math.ceil(NewItems.searchedItems.length / this.itemsPerPage);
  }

  AddDefaultItem(group: any) {
    this.groupsDefault = NewItems.getGroupDefault(group.GroupNm, group.DepartmentNbr, group.CategoryNbr);
    if (this.groupsDefault.length > 0) {
      this.groupsDefault.forEach(element => {
        if (element.DefaultGrpItemKeyInd === true) {
          this.openItem(element);
          // break;
        } else {
        }
      });
    } else {
      alert('No Item Found In Group');
    }
  }

  AddOtherItemModal() {
    this.otherItem = new Item('RENTAL', -1, '', '', -1, '', '', '', '', '-1', null, null, '-1', false, false, false, false, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    this.otherProjectItem = new ProjectItem(null, '', 1, 1, new Date(), new Date(), '', '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '', '', '', '', '', -1, false, [], [], 0, '', 0, 0, 0);

    const dialogRef = this.dialog.open(UnlistedEquipmentDialog, {
      width: '90%',
      data: this.otherItem
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.otherItem = result;
        this.AddOtherToCart();
      }
    });
  }


  AddOtherToCart() {
    AppComponent.toggleCart();

    let item: any = this.otherItem;

    let startDate = new Date();
    let endDate = new Date();

    let cartItem = new CartItem(
      null, // CartID
      null, // CartItemID
      item.ItemImageURL, // Image URL src
      item.ItemNm, // Name
      item.ItemNum, // Name
      item.ItemKey, // Key
      startDate, // Start Date
      startDate.getMonth().toString() + '/' + startDate.getDate().toString() + '/' + startDate.getFullYear().toString(), // Start Date
      endDate, // End Date
      endDate.getMonth().toString() + '/' + endDate.getDate().toString() + '/' + endDate.getFullYear().toString(), // End Date

      this.otherProjectItem.ProjectionItemQuantity, // Quantity 1, //
      this.otherProjectItem.PONo, // Purchase Order Number
      this.otherProjectItem.JobNo, // Job Number
      this.otherProjectItem.PickedUpBy, // Picked Up By
      this.otherProjectItem.OrderedBy, // Ordered By
      false, // Is Optional Item
      [], // Attached Suggested Items
      [], // Attached Optional Items
      item.SalePrice, // Sale Price
      item.HierarchyTypNm, // Hierarchy Type Name
      item.DailyRate, // Daily Rate,
      item.WeeklyRate, // Weekly Rate
      item.MonthlyRate, // Monthly Rate

      null, // Parent Item ID

      '1', // Equipment ID?
      this.otherProjectItem.ProjectionItemShifts,
      this.otherProjectItem.ProjectionItemOperatorCertification,
      this.otherProjectItem.ProjectionItemDelivery,
      this.otherProjectItem.ProjectionItemContractRateDay,
      this.otherProjectItem.ProjectionItemContractRateWeek,
      this.otherProjectItem.ProjectionItemContractRateMonth,
      this.otherProjectItem.ProjectionItemListRateDay,
      this.otherProjectItem.ProjectionItemListRateWeek,
      this.otherProjectItem.ProjectionItemListRateMonth,
      this.otherProjectItem.ProjectionItemCustomRateDay,
      this.otherProjectItem.ProjectionItemCustomRateWeek,
      this.otherProjectItem.ProjectionItemCustomRateMonth,
      this.otherProjectItem.ProjectionItemMarketRateDay,
      this.otherProjectItem.ProjectionItemMarketRateWeek,
      this.otherProjectItem.ProjectionItemMarketRateMonth,
      0, // Estimated Cost #
      '0',
      item.CategoryDescription,
      item.CategoryNm,
      item.CategoryDescription,
    );

    this.cartItemService.PostUserCartItem(cartItem).subscribe(
      data => {
        return data;
      },
      error => {
        console.error('Could not add other item to cart')
      }
    );
  }

  itemImage(imgUrl: string): string {
    let img = 'http://i.imgur.com/hNcsOlE.png';
    if (imgUrl !== null) {
      img = imgUrl;
    }
    return img;
  }

  formatDollar(num) {
    let p = num.toFixed(2).split('.');
    return '$' + p[0].split('').reverse().reduce(function (acc, num, i, orig) {
      return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc;
    }, '') + '.' + p[1];
  }
}
