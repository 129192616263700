import { Component, OnInit } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { Response } from '@angular/http';
import { MessagebarService } from '../../service/messagebar.service';
import { RetailUserService } from '../../service/retailuser.service';
import { Router } from '@angular/router';
import { FormsModule, FormBuilder, Validators } from '@angular/forms';
import { MatSpinner } from '@angular/material/progress-spinner';
import { APIService } from '../../service/api.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    providers: [APIService]
})
export class ChangePasswordComponent implements OnInit {

    userName: string;
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;

    private isBusy: boolean = false;
    constructor(
        private apiService: APIService,
        private retailCustomerService: RetailUserService,
        private router: Router,
        private msgBar: MessagebarService) {
    }

    ngOnInit() {
    }

    changePassword(userName, oldPassword: string, newPassword: string, newPasswordConfirm: string) {
        // verify the new and confirm are the same
        if (newPassword.localeCompare(newPasswordConfirm) !== 0) {
            this.msgBar.addAlert('Your new password and confirm password do not match', 'warning');
            return;
        }
        this.isBusy = true;

        let url: string = 'retailuser/changepassword?emailaddress=' + userName;
        url += '&password=' + oldPassword;
        url += '&newpassword=' + newPassword;

        try {
            this.apiService.Get(url)
                .subscribe(data => {
                    this.msgBar.addAlert('Your password has been changed', 'success');
                    history.back();
                },
                err => {
                    let errMsg = 'Unable to change your password! ';
                    if (err instanceof Response) {
                        let json = err.json;
                        if (json['message'] !== undefined && json['message'].length > 0) {
                            errMsg += json['message'];
                        }
                    }
                    this.msgBar.addAlert(errMsg, 'danger');
                    this.isBusy = false;
                },
                () => {
                    this.isBusy = false;
                });
        }
        catch (error) {
            let msg = 'Error changing password ';
            console.log(<any>error);
            if (typeof error === 'string' && error.length > 0) {
                msg += error;
            }
            this.msgBar.addAlert(msg, 'danger');
        }

    }

    cancel() {
        history.back();
    }
}
