
import {throwError as observableThrowError, Observer, Observable} from 'rxjs';
import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Http, Response } from '@angular/http';
import { APIService }  from '../service/api.service';

@Injectable()
export class RetailUserService {
    private loggedIn = false;
    loggedIn$: Observable<any>;
    private _observer: Observer<boolean>;

    public retailUser: any = null;
    public retailUserID: string;
    public _firstName: string;
    public _lastName: string;
    public _company: string;
    public _email: string;
    public _phone: string;
    public _altPhone: string;
    public _defaultStore: string;

    public _address1: string;
    public _address2: string;
    public _state_prov_region: string;
    public _zip: string;
    public _country: string;

    constructor(
        private apiService: APIService
        ) {
        this.loggedIn$ = new Observable(observer => this._observer = observer).pipe(share());
    }

    login(email: String, password: String) {
        // let headers = new Headers();
        // headers.append('Content-Type', 'application/json');

        let cmd = 'retailuser/login?username=' + email + '&password=' + password;
        let obser: any = this.apiService.Get(cmd).pipe(share());
        obser.subscribe(
            data => {
                console.log(data);
                sessionStorage.setItem('retailuser', JSON.stringify(data));
                this.retailUser = data;
                this.retailUserID = data['RetailUserID'];
                this._firstName = data['FirstName'];
                this._lastName = data['LastName'];
                this._company = data['Company'];
                this._email = data['Email'];
                this._phone = data['Phone'];
                this._altPhone = data['AltPhone'];
                this._defaultStore = data['DefaultStore'];

                this.apiService.setToken(data['AccessToken']);
                this.loginSet(true);

                // // If user logged in then check if they have a saved cart address
                // let cmd = 'retailuser/' + this.retailUserID + '/cart/' + password;
                // let delivery: any = this.apiService.Get(cmd).share();
                // delivery.subscribe(
                //   data => {
                //   this._address1 = data['Address1'];
                //   this._address2 = data['Address2'];
                //   this._state_prov_region = data['State_Prov_Region'];
                //   this._zip = data['Zip'];
                //   this._country = data['Country'];
                // },
                // err => {  });
            },
            err => {
              this.loginSet(false);
            });
        return obser;
    }

    public rId(): string {
        let rId = '';
        if (this.retailUserID && this.retailUserID.length > 0) {
            rId = this.retailUserID;
        }
        else if (!this.retailUserID && this.retailUser) {
            this.retailUserID = this.retailUser['Id'];
            rId = this.retailUserID;
        }
        return rId;
    }

    public firstName(): string {
        let fname = '';
        if (this._firstName && this._firstName.length > 0) {
            fname = this._firstName;
        }
        else if (!this._firstName && this.retailUser) {
            this._firstName = this.retailUser['FirstName'];
            fname = this._firstName;
        }
        return fname;
    }

    public lastName(): string {
        let lname = '';
        if (this._lastName && this._lastName.length > 0) {
            lname = this._lastName;
        }
        else if (!this._lastName && this.retailUser) {
            this._lastName = this.retailUser['LastName'];
            lname = this._lastName;
        }
        return lname;
    }

    public company(): string {
        let company = '';
        if (this._company && this._company.length > 0) {
            company = this._company;
        }
        else if (!this._company && this.retailUser) {
            this._company = this.retailUser['Company'];
            company = this._company;
        }
        return company;
    }

    public email(): string {
        let email = '';
        if (this._email && this._email.length > 0) {
            email = this._email;
        }
        else if (!this._email && this.retailUser) {
            this._email = this.retailUser['Email'];
            email = this._email;
        }
        return email;
    }

    public phone(): string {
        let phone = '';
        if (this._phone && this._phone.length > 0) {
            phone = this._phone;
        }
        else if (!this._phone && this.retailUser) {
            this._phone = this.retailUser['Phone'];
            phone = this._phone;
        }
        return phone;
    }

    public altPhone(): string {
        let altPhone = '';
        if (this._altPhone && this._altPhone.length > 0) {
            altPhone = this._altPhone;
        }
        else if (!this._altPhone && this.retailUser) {
            this._altPhone = this.retailUser['AltPhone'];
            altPhone = this._altPhone;
        }
        return altPhone;
    }

    public defaultStore(): string {
        let defaultStore = '';
        if (this._defaultStore && this._defaultStore.length > 0) {
            defaultStore = this._defaultStore;
        }
        else if (!this._defaultStore && this.retailUser) {
            this._defaultStore = this.retailUser['DefaultStore'];
            defaultStore = this._defaultStore;
        }
        return defaultStore;
    }

    public address1(): string {
        let address1 = '';
        if (this._address1 && this._address1.length > 0) {
            address1 = this._address1;
        }
        else if (!this._address1 && this.retailUser) {
            this._address1 = this.retailUser['Address1'];
            address1 = this._address1;
        }
        return address1;
    }

    public address2(): string {
        let address2 = '';
        if (this._address2 && this._address2.length > 0) {
            address2 = this._address2;
        }
        else if (!this._address2 && this.retailUser) {
            this._address2 = this.retailUser['Address2'];
            address2 = this._address2;
        }
        return address2;
    }

    public state_prov_region(): string {
        let state_prov_region = '';
        if (this._state_prov_region && this._state_prov_region.length > 0) {
            state_prov_region = this._state_prov_region;
        }
        else if (!this._state_prov_region && this.retailUser) {
            this._state_prov_region = this.retailUser['State_Prov_Region'];
            state_prov_region = this._state_prov_region;
        }
        return state_prov_region;
    }

    public zip(): string {
        let zip = '';
        if (this._zip && this._zip.length > 0) {
            zip = this._zip;
        }
        else if (!this._zip && this.retailUser) {
            this._zip = this.retailUser['Zip'];
            zip = this._zip;
        }
        return zip;
    }

    public country(): string {
        let country = '';
        if (this._country && this._country.length > 0) {
            country = this._country;
        }
        else if (!this._country && this.retailUser) {
            this._country = this.retailUser['Country'];
            country = this._country;
        }
        return country;
    }

    // log the user out clear the cache
    logout() {
        sessionStorage.removeItem('retailuser');
        // clear the user defaults
        sessionStorage.removeItem('orderedBy');
        sessionStorage.removeItem('pickedUpBy');
        sessionStorage.removeItem('shift');
        sessionStorage.removeItem('deliverRequired');

        // Clear Cart
        // CartItems.clearItems();
        // sessionStorage.removeItem('CartItems');

        this.retailUserID = null;
        this.retailUser = null;

        this._firstName = null;
        this._lastName = null;
        this._company = null;
        this._email = null;
        this._phone = null;
        this._altPhone = null;
        this._defaultStore = null;

        this.loggedIn = false;
        if (this._observer) {
            this._observer.next(this.loggedIn);
        }
        // this.msgBar.addAlert('Successfully Logged Out', 'success');
    }

    isLoggedIn(): boolean {
        // if we are not logged in logged check the session storage
        // it may be a page refresh
        if (this.loggedIn === false) {
            let storageCustomer = sessionStorage.getItem('retailuser');

            if (storageCustomer != null) {
                // TODO: check for expiration
                this.retailUser = JSON.parse(storageCustomer);

                this.retailUserID = this.retailUser['RetailUserID'];
                this._firstName = this.retailUser['FirstName'];
                this._lastName = this.retailUser['LastName'];
                this._company = this.retailUser['Company'];
                this._email = this.retailUser['Email'];
                this._phone = this.retailUser['Phone'];
                this._altPhone = this.retailUser['AltPhone'];
                this._defaultStore = this.retailUser['DefaultStore'];

                this.apiService.setToken(this.retailUser['AccessToken']);

                // Download Cart Items

                if (this.loggedIn === false) {
                  this.loginSet(true);
                }
            }
        }
        return this.loggedIn;
    }

    public loginSet(loggedIn: boolean) {
        this.loggedIn = loggedIn;
        if (this._observer) {
            this._observer.next(this.loggedIn);
        }
    }

    private extractData(res: Response) {
        let body = res.json();
        if (res.status === 200) {
            // sessionStorage.setItem('retailuser', JSON.stringify(body));
            // this.retailUser = body;
            // this.retailUserID = body['RetailUserID'];
            // this._firstName = body['FirstName'];
            // this._lastName = body['LastName'];
            // this._company = body['Company'];
            // this._email = body['Email'];
            // this._phone = body['Phone'];
            // this._altPhone = body['AltPhone'];
            // this._defaultStore = body['DefaultStore'];

            // this.apiService.setToken(body['AccessToken']);
        }
        return body;
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        if (error instanceof Response) {
            let resp: Response = error;
            let body = resp.json();
            if (body.Message) {
                errMsg = body.Message;
            }
        }

        console.error(errMsg); // log to console instead
        return observableThrowError(errMsg);
    }




}
