import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CurrencyPipe } from '@angular/common';

import { SpecialItem } from '../service/specials.service';
import {ItemService, Item} from '../service/item.service';
import { NewItems } from '../items/';


declare let jsPDF;

@Component({
  selector: 'app-specials',
  templateUrl: './specials.component.html',
  styleUrls: ['./specials.component.scss']
})
export class SpecialsComponent implements OnInit {

  private specialItems: SpecialItem[] = NewItems.specialItems;
  specialRentalItems: SpecialItem[];
  specialSaleItems: SpecialItem[];

  public refreshed: boolean = NewItems.refreshed;

  constructor(
    private router: Router,
    private itemService: ItemService,
  ) { }

  ngOnInit() {
    document.getElementById('SMS_TOOLBAR').scrollIntoView();
    this.calcSales();
    this.specialRentalItems = this.specialItems.filter(this.specialRental);
    this.specialSaleItems = this.specialItems.filter(this.specialSales);

    this.specialRentalItems = this.removeDuplicates(this.specialRentalItems);
    this.specialSaleItems = this.removeDuplicates(this.specialSaleItems);

    // this.specialRentalItems = this.sortItems(this.specialRentalItems);


    if (this.itemService.isLoaded) {
      this.Loading();
    }
    else {
      this.itemService.loaded$.subscribe(
        data => {
          this.Loading();
        });
    }
  }

  specialRental (item) {return item.HierarchyTypNm=='RENTAL'}
  specialSales (item) {return item.HierarchyTypNm!='RENTAL'}

  // sortItems (items) {
    // let newItems: SpecialItem[] = [];

      // newItems = items.sort(
      //     function (a, b) { return (a. > b.CategoryNm) ? 1 : ((b.CategoryNm > a.CategoryNm) ? -1 : 0); }
      //     )

    // return newItems;
  // }

  removeDuplicates(items) {
    let newItems: SpecialItem[] = [];

      for (let item of items) { // Push each item to new array, if a duplicate arrives remove it.
        let duplicate = false;
          for (let newItem of newItems) {
            if(item.ItemKey==newItem.ItemKey){duplicate = true;} // If item has duplicate remember not to add it
          }
        if (duplicate==false){newItems.push(item)} // If item has duplicate do not add it
      }

    return newItems;
  }

  calcSales(){
    for (let item of this.specialItems) {
      item.SaleFeatureValue = +(item.SalePrice-(item.SalePrice*item.FeatureItemDiscountPercent/100)).toFixed(2);
      item.DailyFeatureValue = +(item.DailyRate-(item.DailyRate*item.FeatureItemDiscountPercent/100)).toFixed(2);
      item.WeeklyFeatureValue = +(item.WeeklyRate-(item.WeeklyRate*item.FeatureItemDiscountPercent/100)).toFixed(2);
      item.MonthlyFeatureValue = +(item.MonthlyRate-(item.MonthlyRate*item.FeatureItemDiscountPercent/100)).toFixed(2);
      item.FeatureItemDiscountPercentDisplay = item.FeatureItemDiscountPercent;
    }
    for (let item of this.specialItems) {
      item.SaleFeatureValue = +(item.SalePrice-(item.SalePrice*item.FeatureItemDiscountPercent/100)).toFixed(2);
      item.DailyFeatureValue = +(item.DailyRate-(item.DailyRate*item.FeatureItemDiscountPercent/100)).toFixed(2);
      item.WeeklyFeatureValue = +(item.WeeklyRate-(item.WeeklyRate*item.FeatureItemDiscountPercent/100)).toFixed(2);
      item.MonthlyFeatureValue = +(item.MonthlyRate-(item.MonthlyRate*item.FeatureItemDiscountPercent/100)).toFixed(2);
      item.FeatureItemDiscountPercentDisplay = item.FeatureItemDiscountPercent;
    }
  }

  routeRentalItem(index){
    this.router.navigate(['/items/'+this.specialRentalItems[index].ItemKey]);
  }

  routeSaleItem(index){
    this.router.navigate(['/items/'+this.specialSaleItems[index].ItemKey]);
  }

  routeItem(itemNum){
    this.router.navigate(['/items/'+itemNum]);
  }

  storeRoute() {
    this.router.navigate(['/items']);
  }

  updateUrl(event) {
    event.path[0].src = 'http://i.imgur.com/hNcsOlE.png';
  }

  scrollRight(element) {
    if (element==1) {
      document.getElementById('RentalSpecial').scrollLeft += document.getElementById('RentalSpecial').parentElement.scrollWidth;
    }
    if (element==2) {
      document.getElementById('SalesSpecial').scrollLeft += document.getElementById('SalesSpecial').parentElement.scrollWidth;
    }
  }
  scrollLeft(element) {
    if (element==1) {
      document.getElementById('RentalSpecial').scrollLeft -= +document.getElementById('RentalSpecial').parentElement.scrollWidth;
    }
    if (element==2) {
      document.getElementById('SalesSpecial').scrollLeft -= +document.getElementById('SalesSpecial').parentElement.scrollWidth;
    }
  }

  itemImage(imgUrl: string): string {
    let img = 'http://i.imgur.com/hNcsOlE.png';
    if (imgUrl !== null) {
      img = imgUrl;
    }
    return img;
  }


  Loading() { // {Check For Items Arrival, Refresh When Available}
    if (NewItems.categories.length > 1 && NewItems.refreshed === false) { // NewItems Is Static Class Holding Items/Categorys/Departments/Groups

      NewItems.refreshed = true; // Stop All Loops From Refreshing Again Some Do Not Have Timers (My Poor Design Choice)
      this.refreshed = true;

      this.specialItems = NewItems.specialItems;
      this.calcSales();
      this.specialRentalItems = this.specialItems.filter(this.specialRental);
      this.specialSaleItems = this.specialItems.filter(this.specialSales);

      this.specialRentalItems = this.removeDuplicates(this.specialRentalItems);
      this.specialSaleItems = this.removeDuplicates(this.specialSaleItems);

      // console.log(this.specialRentalItems);
      // console.log(this.specialSaleItems);
    }
  }

  formatDollar(num) {
    var p = num.toFixed(2).split(".");
    return "$" + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
        return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
      }, "") + "." + p[1];
  }
}
