import { Component, Inject, OnInit, ViewChild, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CartSubmitDialog } from './cart-submit-dialog';
import { CartLoginDialog } from './cart-login-dialog';
import { specialRentalItems, specialSaleItems, SpecialItem } from '../service/examples.service';
import { CartItem, CartItemService } from '../service/cart.service';

import { CalendarModule, Calendar } from 'primeng/primeng';


import { RetailUserService } from '../service/retailuser.service';
import { Location, LocationService } from '../service/location.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [CartItemService, LocationService],
})
export class CartComponent implements OnInit {

  public static store: Location;
  public static notes: string;

  private stores: Location[] = LocationService.locations;

  cartItems: CartItem[] = CartItemService.currentItems;
  cartTotalCost = 0;
  displayCartTotalCost = '0';
  delivery = false;
  updating = false;
  saving = false;


  constructor(
    private router: Router,
    private retailUserService: RetailUserService,
    private cartItemService: CartItemService,
    private locationService: LocationService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.checkDelivery();
    if (this.retailUserService.retailUserID) {
      const user = this.retailUserService.retailUser;
      const stores = this.stores.filter(x => x.Branch === user['DefaultStore']);
      if (stores.length > 0) {
        CartComponent.store = stores[0];
      }
    }
  }

  loginPage() {
    this.router.navigate(['/login']);
  }

  createAccountPage() {
    this.router.navigate(['/create-account']);
  }

  specialsPage() {
    this.router.navigate(['/specials']);
  }

  routeStore() {
    this.router.navigate(['/items']);
  }

  routeDelivery() {
    this.router.navigate(['/delivery']);
  }

  updateUrl(event) {
    event.path[0].src = 'http://i.imgur.com/hNcsOlE.png';
  }

  LinkItem(item) {
    this.router.navigate(['/items/' + item]);
  }
  // Conditional Styles -------------------------------------------------------------------------------------------------------------------------------
  getCartLeftStyle(Child) {
    if (Child) {
      return 'Cart-Item-Left-Child';
    } else {
      return 'Cart-Item-Left';
    }
  }

  getCartRightStyle(Child) {
    if (Child) {
      return 'Cart-Item-Right-Child';
    } else {
      return 'Cart-Item-Right';
    }
  }

  getCartStyle(Child) {
    if (Child) {
      return 'Cart-Item Cart-Item-Child';
    } else {
      return 'Cart-Item';
    }
  }
  // Conditional Styles -------------------------------------------------------------------------------------------------------------------------------

  checkDelivery() {
    this.delivery = false;
    for (const cartItem of this.cartItems) {
      if (cartItem.Delivery && cartItem.Delivery.toString() === 'true') {
        this.delivery = true;
        console.log('Setting Delivery:' + this.delivery);
      }
    }
  }

  itemImage(imgUrl: string): string {
    let img = 'http://i.imgur.com/hNcsOlE.png';
    if (imgUrl !== null) {
      img = imgUrl;
    }
    return img;
  }

  // Cart Controls ----------------------------------------------------------------------------------------------------------------------------------



  public removeAllCartItem(index) {
    console.log(this.cartItems);

    for (const selectedItem of this.cartItems) {
      console.log(selectedItem);
      this.cartItemService.DelCartItem(selectedItem).subscribe(CI => {
        console.log('Successfully Removed Item: ');
        console.log(CI);
      });
    }

    this.updatePrice(); // Now the view Updates Price
  }

  public removeCartItem(index) {
    const selectedItem = this.cartItems[index];
    console.log(this.cartItems);

    for (const item of this.cartItems.concat()) { // Suggested Search/Delete
      if (item.IsOptionalItem === false && item.ParentCartItemID != null && item.ParentCartItemID === selectedItem.CartItemID) {
        console.log('Parent Item Index:' + index);
        this.cartItemService.DelCartItem(item).subscribe(oCI => {
          console.log('Successfully Removed Suggested Item: ');
          console.log(oCI);
        });
      }

      if (item.IsOptionalItem === true && item.ParentCartItemID != null && item.ParentCartItemID === selectedItem.CartItemID) {
        console.log('Parent Item Index:' + index);

        this.cartItemService.DelCartItem(item).subscribe(CI => {
          console.log('Successfully Removed Item: ');
          console.log(CI);
        });
      }
    }

    this.cartItemService.DelCartItem(selectedItem).subscribe(CI => {
      console.log('Successfully Removed Item: ');
      console.log(CI);
    });

    this.updatePrice(); // Now the view Updates Price
  }

  public updatePrice() {
    if (!this.updating) {
      this.updating = true;
      this.cartTotalCost = 0;
      this.cartItems.forEach(item => { if (!item.IsOptionalItem) { this.cartTotalCost += (item.CartItemEstCost * item.Quantity); } });
      this.cartTotalCost = +this.cartTotalCost.toFixed(2);
      this.displayCartTotalCost = this.cartTotalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      this.updating = false;
    }
  }

  public submitCart() {
    if (this.retailUserService.isLoggedIn() && this.cartItems.length > 0) {
      // this.delivery= false;
      // for (let cartItem of this.cartItems){
      //   if (cartItem.Delivery.toString() === 'true') {
      //     this.routeDelivery();
      //     this.delivery= true;
      //   }
      // }

      console.log('Delivery:' + this.delivery);

      if (this.delivery === false) {
        this.cartItemService.PutCartNotes(this.notes).subscribe(
          cart => {
            console.log('Updated Notes: ' + cart);
            this.cartItemService.SubmitCart(this.defaultStore).subscribe(
              cart => {
                console.log('Submitted: ' + cart);
                this.showSubmitModal(); // Open Confirmation Modal
                this.cartItemService.GetUserCurrentCart();
                this.notes = '';
              },
              error => {
                const msg = 'Unable to submit cart';
                console.log(<any>error);
              }
            );
          },
          error => {
            const msg = 'Unable to update notes';
            console.log(<any>error);
          }
        );
        console.log('Submitting Cart:');
      } else {
        this.routeDelivery();
      }
    } else {
      this.showLoginModal();
    }
  }

  showSubmitModal(): void {
    const dialogRef = this.dialog.open(CartSubmitDialog, { width: '40%' });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      this.routeStore();
    });
  }

  showLoginModal(): void {
    const dialogRef = this.dialog.open(CartLoginDialog, { width: '40%' });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.router.navigate([result]);
      }
    });
  }

  // public sendSubmitCart() {
  //   const dialogRef = this.dialog.open(CartSubmitDialog, { width: '40%' });
  //   dialogRef.afterClosed().subscribe(result => {console.log(`Dialog result: ${result}`); });
  // }

  minStartDate(): Date {
    return new Date();
  }

  setEndDate(cartItem) {
    console.log('Fixing End Date');
    const temp = this;

    if (cartItem.StartDate > cartItem.EndDate) {
      cartItem.EndDate = cartItem.StartDate;
    }
  }

  public saveItem(cartItem) {
    if (!this.saving) {
      this.saving = true;
      console.log(cartItem);
      this.cartItemService.PutUserCartItem(cartItem).subscribe(CI => {
        console.log('Successfully Updated Item: ');
        console.log(CI);
        this.saving = false;
      });
    }
  }

  // Cart Controls ----------------------------------------------------------------------------------------------------------------------------------

  EstimateCost(cartItem) {

    // cartItem.StartDate.setHours(0,0,0,0);

    // cartItem.EndDate.setHours(0,0,0,0);
    // cartItem.EndDate.setDate(cartItem.EndDate.getDate()+1); // +1 Day is making price view selected date as full day
    cartItem.CartItemEstCost = this.cartItemService.GetEstimateCost(cartItem, new Date(cartItem.StartDate), new Date(cartItem.EndDate), cartItem.DailyRate, cartItem.WeeklyRate, cartItem.MonthlyRate, cartItem.SalePrice, cartItem.HierarchyTypNm);

    // cartItem.CartItemEstCost = this.GetEstimateCost(new Date(cartItem.StartDate), new Date(cartItem.EndDate), cartItem.DailyRate, cartItem.WeeklyRate, cartItem.MonthlyRate, cartItem.SalePrice, cartItem.HierarchyTypNm);
    cartItem.DisplayCartItemEstCost = cartItem.CartItemEstCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  // public GetEstimateCost(StartDate, EndDate, dailyRate: number, weeklyRate: number, monthlyRate: number, SalePrice, HierarchyTypNm) {

  //   StartDate.setHours(0,0,0,0);
  //   EndDate.setHours(0,0,0,0);

  //   if (HierarchyTypNm!='RENTAL'){
  //     return SalePrice;
  //   } else{
  //     let daysOnRent = 0;
  //     // Server Code Adapted
  //     if(EndDate.getTime() - StartDate.getTime() == 0){
  //       daysOnRent = 1;
  //       console.log("Rental Days: "+daysOnRent);
  //     } else {
  //       daysOnRent = ((EndDate.getTime() - StartDate.getTime()) / 86400000); // 86400000ms in a day
  //       console.log("Rental Days: "+daysOnRent);
  //     }
  //     let estCost: number = 0;


  //     if (dailyRate == 0){
  //       if (weeklyRate != 0){
  //         dailyRate = weeklyRate;
  //       }else{
  //         dailyRate = monthlyRate;
  //       }
  //     }
  //     if (weeklyRate == 0){
  //       if (dailyRate != 0){
  //         weeklyRate = dailyRate * 7;
  //       }else{
  //         weeklyRate = monthlyRate;
  //       }
  //     }
  //     if (monthlyRate == 0){
  //       if (dailyRate != 0){
  //         monthlyRate = weeklyRate * 4;
  //       }else{
  //         monthlyRate = dailyRate * 28;
  //       }
  //     }

  //     let MonthsRemainder: number = daysOnRent % 28;
  //     let decimal_Months: number = daysOnRent / 28;
  //     let whole_Months: number = Math.floor(decimal_Months);

  //     let WeeksRemainder: number = MonthsRemainder % 7;
  //     let decimal_Weeks: number = MonthsRemainder / 7;
  //     let whole_Weeks: number = Math.floor(decimal_Weeks);

  //     let whole_Days: number = WeeksRemainder;

  //     console.log("Days:"+whole_Days);
  //     console.log("Weeks:"+whole_Weeks);
  //     console.log("Months:"+whole_Months);

  //     let daysTotal: number = whole_Days * dailyRate;
  //     console.log("Days Cost:"+daysTotal);
  //     let weeksTotal: number = whole_Weeks * weeklyRate;
  //     console.log("Weeks Cost:"+weeksTotal);
  //     let monthTotal: number = whole_Months * monthlyRate;
  //     console.log("Months Cost:"+monthTotal);

  //     //Roll things up to the next unit if its more at the lower time interval
  //     if (daysTotal > weeklyRate){
  //         daysTotal = 0;
  //         whole_Weeks++;
  //         weeksTotal = whole_Weeks * weeklyRate;
  //     }
  //     if (weeksTotal+daysTotal > monthlyRate){
  //         daysTotal = 0;
  //         weeksTotal = 0;
  //         whole_Months++;
  //         monthTotal = whole_Months * monthlyRate;
  //     }

  //     estCost = daysTotal + weeksTotal + monthTotal;
  //     return estCost;
  //   }
  // }

  private get defaultStore() {
    return CartComponent.store;
  }

  private set defaultStore(value) {
    CartComponent.store = value;
  }

  private get notes() {
    return CartComponent.notes;
  }

  private set notes(value) {
    CartComponent.notes = value;
  }

  private setLocation() {
    this.locationService.setNearByLocations().subscribe((locations) => {
      CartComponent.store = this.stores[0];
    });
  }

  formatDollar(num) {
    const p = num.toFixed(2).split('.');
    return '$' + p[0].split('').reverse().reduce(function (acc, num, i, orig) {
      return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc;
    }, '') + '.' + p[1];
  }
}
