import { Component, OnInit } from '@angular/core';
import { MessagebarService } from '../service/messagebar.service';
import { Router } from '@angular/router';

import { CartItemService, CartItem } from '../service/cart.service';

import { RetailUserService } from '../service/retailuser.service';
import { APIService } from '../service/api.service';
import { Response } from '@angular/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BirchUtil } from '../service/birchutil';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})

export class OrderHistoryComponent implements OnInit {

  carts: any = [];
  Loading = false;

  constructor(
    private cartItemService: CartItemService,
    private router: Router,
    private msgBar: MessagebarService,
    private apiService: APIService,
    private retailUserService: RetailUserService
  ) {

  }

  ngOnInit() {
    this.getCarts();
  }

  getCarts() {
    this.Loading = true;
    this.cartItemService.GetAllCarts().subscribe(
      (Carts)=>{
        this.carts = Carts;
        this.Loading = false;
        // this.findTotals();
      }
    );
  }

  // findTotals() {
  //   for (let Cart of this.carts) {
  //     Cart.Total = 0;
  //     for (let CItem of Cart.CartItems) {
  //       Cart.Total += (CItem.CartItemEstCost*CItem.Quantity);
  //     }
  //     console.log("Cart.Total:");
  //     console.log(Cart.Total);
  //   }
  // }

  findTotal(Cart) {
    let Total = 0;
    for (let CItem of Cart.CartItems) {
      Total += (CItem.CartItemEstCost*CItem.Quantity);
    }
    return Total;
  }

  castNumber(String){
    return +String;
  }

  updateUrl(event) {
    event.path[0].src = 'http://i.imgur.com/hNcsOlE.png';
  }

  itemImage(imgUrl: string): string {
    let img = 'http://i.imgur.com/hNcsOlE.png';
    if (imgUrl !== null) {
      img = imgUrl;
    }
    return img;
  }

  LinkItem(item){
    this.router.navigate(['/items/'+item]);
  }

  formatDollar(num) {
    var p = num.toFixed(2).split(".");
    return "$" + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
        return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
      }, "") + "." + p[1];
  }

  formatDate(date: Date) {
    var temp = new Date(date);
    return (temp.getMonth() + 1) + '/' + temp.getDate() + '/' +  temp.getFullYear();
  }
}
