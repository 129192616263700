import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Response } from '@angular/http';

import { MessagebarService } from '../service/messagebar.service';
import { RetailUserService } from '../service/retailuser.service';

import { MatButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatInput } from '@angular/material/input';
import { MatSpinner } from '@angular/material/progress-spinner';

@Component({
  // moduleId: module.id,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public email: string;
  public password: string;
  errorMsg: string;
  private isBusy = false;

  constructor(
    private router: Router,
    private retailCustomerService: RetailUserService,
    private msgBar: MessagebarService
    ) {

    }

  ngOnInit() {
  }

  createAccountPage(){
    this.router.navigate(['/create-account']);
  }

  specialsPage(){
    this.router.navigate(['/specials']);
  }

  routeStorePage(){
    this.router.navigate(['/items']);
  }

  login(email, password) {
    this.isBusy = true;
    // event.preventDefault();
    this.errorMsg = null;
    try {
      this.retailCustomerService.login(email, password)
        .subscribe(
        data => {
          this.loginSuccess(data);
          this.routeStorePage();
        },
        err => this.loginError(<any>err)
        );
    }
    catch (ex) {
      console.error('Error logging in' + ex);
    }
  }

  forgot(event) {
    event.preventDefault();
    this.router.navigate(['./forgot-password']);
  }

  change(event) {
    event.preventDefault();
    this.router.navigate(['./change-password']);
  }

  private loginSuccess(data) {
    //history.back();
    this.msgBar.addAlert('Welcome ' + this.retailCustomerService.firstName(), 'success');
    this.isBusy = false;
    this.router.navigate(['/specials']);
  }

  private loginError(err) {
    let errMsg = 'Unable to login';
     if (err instanceof Response) {
            let resp: Response = err;
            let body = resp.json();
            if (body.Message) {
                errMsg = body.Message;
            }
        }
    this.errorMsg = errMsg;
    this.msgBar.addAlert(errMsg, 'warning');
    this.retailCustomerService.loginSet(false);
    this.isBusy = false;
  }

}
