import { Component, OnInit, Pipe, Renderer } from '@angular/core';

import { RetailUserService } from '../../service/retailuser.service';
import { APIService } from '../../service/api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MessagebarService } from '../../service/messagebar.service';

import * as moment from 'moment';


@Component({
  selector: 'app-maintenance-request',
  templateUrl: './maintenance-request.component.html',
  styleUrls: ['./maintenance-request.component.scss'],
  providers: [APIService]
})
export class MaintenanceRequestComponent implements OnInit {
  public title = 'Request Maintenance';

  // form variables
  // public company: string;
  // public name: string;
  // public email: string;
  // public customDate: Date;
  // public location: string;
  // public directions: string;
  // public equipmentNumber: string;
  // public birchOwned: string = '1';
  // public equipmentType: string;
  // public serialNumber: string;
  // public issue: string;

  isBusy = false;

  maintenanceRequestForm: FormGroup;

  controls = {
    txtCompany: new FormControl(''),
    txtName: new FormControl('', Validators.required),
    txtEmail: new FormControl('', Validators.required), // Validators.compose([Validators.required, ValidationService.emailValidator))))
    txtCustomDate: new FormControl('', Validators.required),
    txtLocation: new FormControl('', Validators.required),
    txtDirections: new FormControl('', Validators.required),
    txtEquipmentNumber: new FormControl('', Validators.required),
    equipOwner: new FormControl('', Validators.required),
    txtEquipmentType: new FormControl('', Validators.required),
    txtSerialNumber: new FormControl(''),
    txtIssue: new FormControl('', Validators.required)
  };

  constructor(
    private router: Router,
    private apiService: APIService,
    private retailCustomerService: RetailUserService,
    private renderer: Renderer,
    private msgBar: MessagebarService) {
    this.maintenanceRequestForm = new FormGroup(this.controls);
    this.maintenanceRequestForm.get('equipOwner').valueChanges.subscribe((val: string) => {
      var validators = [];
      if (val === '1') {
        validators.push(Validators.required);
      }
      this.controls.txtEquipmentNumber.setValidators(validators);
      this.controls.txtEquipmentNumber.updateValueAndValidity();
      this.controls.txtEquipmentType.setValidators(validators);
      this.controls.txtEquipmentType.updateValueAndValidity();
    });
  }


  ngOnInit() {
    try {
      let retailUserJson: JSON = this.retailCustomerService.retailUser;
      if (retailUserJson != null) {
        this.controls.txtName.setValue(retailUserJson['FirstName'] + ' ' + retailUserJson['LastName']);
        
        this.controls.txtEmail.setValue(retailUserJson['Email']);
        this.controls.txtCompany.setValue(retailUserJson['Company']);

      }
    }
    catch (error) {
      let msg = 'Error loading maintenance request form ';
      console.log(<any>error);
      if (typeof error === 'string' && error.length > 0) {
        msg += error;
      }
      this.msgBar.addAlert(msg, 'danger');
    }
  }

  onSubmit() {
    try {
      this.isBusy = true;
      let retailUserEmail = '';
      let retailUserPhone = '';
      let retailUserAltPhone = '';

      // Only get this if the uer is logged in
      if (this.retailCustomerService.retailUser !== null) {
        retailUserEmail = this.retailCustomerService.retailUser['Email'];
        retailUserPhone = this.retailCustomerService.retailUser['Phone'];
        retailUserAltPhone = this.retailCustomerService.retailUser['AltPhone'];
      }

      let json = `{
      "name" : "${this.maintenanceRequestForm.get('txtName').value}",
      "company" : "${this.maintenanceRequestForm.get('txtCompany').value}",
      "email" : "${this.maintenanceRequestForm.get('txtEmail').value}",
      "customDate": "${this.maintenanceRequestForm.get('txtCustomDate').value}",
      "location": "${this.maintenanceRequestForm.get('txtLocation').value}",
      "directions": "${this.maintenanceRequestForm.get('txtDirections').value}",
      "equipmentNumber": "${this.maintenanceRequestForm.get('txtEquipmentNumber').value}",
      "birchOwned" : "${this.maintenanceRequestForm.get('equipOwner').value}",
      "equipmentType" : "${this.maintenanceRequestForm.get('txtEquipmentType').value}",  
      "serialNumber" : "${this.maintenanceRequestForm.get('txtSerialNumber').value}",  
      "issue" : "${this.maintenanceRequestForm.get('txtIssue').value}"
    }`;

      console.log(json);
      this.apiService.Post('birchequipment/maintenancerequest', json)
        .subscribe(data => {
          this.msgBar.addAlert('Maintenance request submitted', 'success');
          // this.router.navigate(['/specials']);
          history.back();
        },
        err => {
          this.msgBar.addAlert('Unable to submit maintenance request', 'danger');
          this.isBusy = false;
        },
        () => {
          this.isBusy = false;
        });
    }
    catch (error) {
      let msg = 'Error creating maintenance request ';
      console.log(<any>error);
      if (typeof error === 'string' && error.length > 0) {
        msg += error;
      }
      this.msgBar.addAlert(msg, 'danger');
      this.isBusy = false;
    }
  }

  onCancel() {
    if (this.maintenanceRequestForm.dirty) {
      // warn about leaving page
      let leave: boolean = confirm('You have unsaved data! <br/> Are you sure you want to leave?');
      if (!leave) {
        return;
      }
    }
    history.back();
  }

  // get the customer (company) from the current customer
  customerLoad(id) {
    let customer = this.retailCustomerService.retailUser;
    if (customer) {
      this.controls.txtCompany.setValue(customer['CustomerName'].trim());
    }
    else {
      let cmd = 'customer/' + id;
      this.apiService.Get(cmd).subscribe(
        data => this.controls.txtCompany.setValue(data['CustomerName'].trim()),
        err => console.log(<any>err)
      );
    }
  }

  minDate(): Date {
    return new Date();
  }
}
