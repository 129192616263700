import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shiftPipe'
})
export class ShiftPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        let shiftText = 'Single';
        switch (value) {
            case 0:
                shiftText = 'Standby';
                break;
            case 1:
                shiftText = 'Single';
                break;
            case 2:
                shiftText = 'Double';
                break;
            case 3:
                shiftText = 'Triple';
                break;

            default:
                shiftText = 'Single';
                break;
        }
        return shiftText;
    }
}