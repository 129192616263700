import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessagebarService } from '../service/messagebar.service';
import { RetailUserService } from '../service/retailuser.service';
import { APIService } from '../service/api.service';
import { Response } from '@angular/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BirchUtil } from '../service/birchutil';

import { Location, LocationService } from '../service/location.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
  providers: [LocationService]
})

export class CreateAccountComponent implements OnInit {

  // private userLatitude: number = 0;
  // private userLongitude: number = 0;
  // private nearByLocationsSet: boolean = false;
  // private Locations: Location[] = locations;
  // private selected: Location;
  stores: Location[] = LocationService.locations;

  // [
  //   {StoreID:1,Branch:"Anacortes"},
  //   {StoreID:2,Branch:"Bellingham"},
  //   {StoreID:3,Branch:"Everett"},
  //   {StoreID:4,Branch:"Vernon"},
  //   {StoreID:5,Branch:"Sitka"},
  // ];

  isEdit = false;
  isBusy = false;
  errorMsg: string = null;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  phone: string;
  altPhone: string;
  defaultStore: Location;
  password: string;
  passwordConfirm: string;
  fleetUpdates: boolean;
  userAccountForm: FormGroup;
  // flags so phone will get validate on loose focus
  checkAltPhone = false;
  checkPhone = false;

  constructor(
    private router: Router,
    private apiService: APIService,
    private retailUserService: RetailUserService,
    private msgBar: MessagebarService,
    private locationService: LocationService,
  ) {
    this.defaultStore = this.stores[0];
    this.userAccountForm = new FormGroup({
      'txtFirstName': new FormControl('', Validators.required),
      'txtLastName': new FormControl('', Validators.required),
      'txtCompany': new FormControl(''),
      'txtEmail': new FormControl('', Validators.required),
      'txtPhone': new FormControl('', [Validators.required, BirchUtil.phoneValidator]),
      'txtAltPhone': new FormControl('', BirchUtil.phoneValidator),
      'txtDefaultStore': new FormControl(''),
      'txtPassword': new FormControl('', Validators.required),
      'txtPasswordConfirm': new FormControl('', Validators.required),
      'chkFleetUpdates': new FormControl('')
    });
  }

  ngOnInit() {
    // if a user is logged in then we are editing an existing
    if (this.retailUserService.retailUserID) {
      this.isEdit = true;
      let user = this.retailUserService.retailUser;
      this.firstName = user['FirstName'];
      this.lastName = user['LastName'];
      this.company = user['Company'];
      this.email = user['Email'];
      this.phone = user['Phone'];
      this.altPhone = user['AltPhone'];
      this.defaultStore = user['DefaultStore'];
      this.fleetUpdates = user['FleetUpdates'];
      this.userAccountForm.controls['txtPassword'].clearValidators();
      this.userAccountForm.controls['txtPasswordConfirm'].clearValidators();
      let stores = this.stores.filter(x => x.Branch == user['DefaultStore']);
      if (stores.length > 0) {
        this.defaultStore = stores[0];
      }
    }
  }

  loginPage() {
    this.router.navigate(['/login']);
  }

  specialsPage() {
    this.router.navigate(['/specials']);
  }

  createAccount() {
    // verify password and confirm
    // verify the new and confirm are the same
    this.errorMsg = null;
    if (!this.isEdit && this.password.localeCompare(this.passwordConfirm) !== 0) {
      this.msgBar.addAlert('Your new password and confirm password do not match', 'warning');
      return;
    }
    this.isBusy = true;
    if (this.fleetUpdates === undefined) {
      this.fleetUpdates = false;
    }

    let json = `{
    "FirstName" : "${this.firstName}",
    "LastName" : "${this.lastName}",
    "Company" : "${this.company}",
    "Email" : "${this.email}",
    "Phone" : "${this.phone}",
    "AltPhone" : "${this.altPhone}",
    "Password": "${this.password}",
    "DefaultStore": "${this.defaultStore.Branch}",
    "SendEmail": "${this.fleetUpdates}"
    }`;

    json = json.replace(/undefined/g, '');
    console.log(json);
    if (this.isEdit) {
      try {
        this.apiService.Put('retailuser/' + this.retailUserService.retailUserID, json).subscribe(data => {
          sessionStorage.setItem('retailuser', JSON.stringify(data));
          this.retailUserService.retailUser = data;
          this.retailUserService.retailUserID = data['RetailUserID'];
          this.retailUserService._firstName = data['FirstName'];

          this.msgBar.addAlert('Account Updated', 'info');

        },
          err => {
            let errMsg = 'Unable to update your account! ';
            if (err instanceof Response) {
              if (json['message'] !== undefined && json['message'].length > 0) {
                errMsg += json['message'];
              }
            }
            this.msgBar.addAlert(errMsg, 'danger');
            this.isBusy = false;
          },
          () => {
            this.isBusy = false;
          });
      }
      catch (error) {
        let msg = 'Error updating account ';
        console.log(<any>error);
        if (typeof error === 'string' && error.length > 0) {
          msg += error;
        }
        this.msgBar.addAlert(msg, 'danger');
      }
    }
    else {
      // Its new
      try {
        this.apiService.Post('retailuser', json).subscribe(data => {
          try {
            this.retailUserService.login(this.email, this.password).subscribe(
              logindata => {
                this.loginSuccess(logindata);
              },
              err => this.loginError(<any>err)
            );
          }
          catch (ex) {
            console.error('Error logging in' + ex);
          }
        },
          err => {
            let errMsg = 'Unable to create your account! ';
            if (err instanceof Response) {
              if (err['message'] !== undefined && err['message'].length > 0) {
                errMsg += err['message'];
                this.errorMsg = errMsg;
              }
            }
            this.msgBar.addAlert(errMsg, 'danger');
            this.isBusy = false;
          },
          () => {
            this.isBusy = false;
          });
      }
      catch (error) {
        let msg = 'Error creating account ';
        console.log(<any>error);
        if (typeof error === 'string' && error.length > 0) {
          msg += error;
        }
        this.msgBar.addAlert(msg, 'danger');
      }
    }
  }

  private loginSuccess(data) {
    this.msgBar.addAlert('Welcome ' + this.retailUserService.firstName(), 'success');
    this.isBusy = false;
    this.router.navigate(['/specials']);
  }

  private loginError(err) {
    let errMsg = 'Unable to login';
    if (err instanceof Response) {
      let resp: Response = err;
      let body = resp.json();
      if (body.Message) {
        errMsg = body.Message;
      }
    }
    this.msgBar.addAlert(errMsg, 'warning');
    this.retailUserService.loginSet(false);
    this.isBusy = false;
  }

  setLocation() {
    let self = this;
    this.locationService.setNearByLocations().subscribe((locations)=>{
      self.defaultStore = this.stores[0];
    });
  }

  onCancel() {
    if (this.userAccountForm.dirty) {
      // warn about leaving page
      let leave: boolean = confirm('You have unsaved data! Are you sure you want to leave?');
      if (!leave) {
        return;
      }
    }
    history.back();
  }
}
