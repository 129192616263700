import { ActivatedRoute } from '@angular/router';
import { Component, ViewChild, OnInit } from '@angular/core';
import { NewItems } from '../items/new-item';
import { Item, ItemService } from '../service/item.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MessagebarService } from '../service/messagebar.service';
import { RetailUserService } from '../service/retailuser.service';
import { APIService } from '../service/api.service';
import { States } from '../service/states';
import { BirchUtil } from '../service/birchutil';
import { LocationService } from '../service/location.service';
// import { BsModalComponent } from 'ng2-bs3-modal';
import { MatDialog } from '@angular/material';
import { PageLeaveConfirmDialog } from '../page-leave-confirm-dialog/page-leave-confirm-dialog';

@Component({
  selector: 'app-purchase-quote',
  templateUrl: './purchase-quote.component.html',
  styleUrls: ['./purchase-quote.component.scss'],
  providers: [APIService]
})
export class PurchaseQuoteComponent implements OnInit {
  // @ViewChild('ConfirmModal', { static: false }) ConfirmModal: BsModalComponent;
  private states: Array<string> = States;
  private stores: Array<any> = LocationService.locations;

  public loadingEquipment = true;
  isBusy = false;

  public categories: Array<any>;
  public departments: Array<any>;
  public groups: Array<any>;
  public items: Array<Item>;
  // Filtered lists based on parent list selections
  public filteredDepartments: Array<any>;
  public filteredGroups: Array<any>;
  public filteredItems: Array<Item>;

  // currently selected values
  // private categoryNbr: number;
//   private company: string;
  // private category: string;
//   private departmentNbr: number;
//   private department: string;
//   private controls.ddlGroup.value['GroupNm']: string;
//   private itemKey: string;
//   private itemName: string;
//   private model: string;
//   private year: string;
//   private approxHours: string;
//   private equipmentComment: string;
//   private name: string;
//   private phone: string;
//   private email: string;
//   private comment: string;
  private deliveryRequired: string;
//   private Address1: string;
//   private City: string;
//   private state: string;
//   private zip: string;
//   private store: string;
  purchaseQuoteForm: FormGroup;
  
  controls = {
    //Left
    'ddlCategory': new FormControl(''),
    'ddlDepartment': new FormControl(''),
    'ddlGroup': new FormControl(''),
    'ddlItem': new FormControl(''),
    'txtModel': new FormControl(''),
    'txtYear': new FormControl(''),
    'txtApproxHours': new FormControl(''),
    'txtequipmentComment': new FormControl(''),
    //Right
    'txtName': new FormControl('', Validators.required),
    'txtCompany': new FormControl(''),
    'txtPhone': new FormControl('', [Validators.required, BirchUtil.phoneValidator]),
    'txtEmail': new FormControl('', [Validators.required, BirchUtil.emailValidator]), // Validators.compose([Validators.required, ValidationService.emailValidator))))
    'txtComment': new FormControl(''),
    'ddlDelivery': new FormControl('', Validators.required),
    //Expandables
    'txtAddress1': new FormControl('', (this.deliveryRequired === 'delivery') ? Validators.required : null),
    'txtCity': new FormControl('', (this.deliveryRequired === 'delivery') ? Validators.required : null),
    'ddlState': new FormControl('', (this.deliveryRequired === 'delivery') ? Validators.required : null),
    'txtZip': new FormControl('', (this.deliveryRequired === 'delivery') ? Validators.required : null),
    'ddlStore': new FormControl('', (this.deliveryRequired === 'pickup') ? Validators.required : null)
  };

 // flags so phone will get validate on loose focus
  private checkPhone = false;

  constructor(
    private router: Router,
    private apiService: APIService,
    private route: ActivatedRoute,
    private retailCustomerService: RetailUserService,
    private itemService: ItemService,
    private msgBar: MessagebarService,
    private dialog: MatDialog
  ) {
    this.categories = new Array();
    this.departments = new Array();
    this.groups = new Array();
    this.items = new Array();
    // Don't show the loading indicator if service is already loaded.
    this.loadingEquipment = !this.itemService.isLoaded;

    this.purchaseQuoteForm = new FormGroup(this.controls);
  }

  setData() {
    this.categories = NewItems.categories;
    this.filteredDepartments = this.departments = NewItems.departments;
    this.filteredGroups = this.groups = NewItems.groups;
    this.filteredItems = this.items = NewItems.newItems;
    this.loadingEquipment = false;
  }

  setParameters() {
    this.route.params.map(params => params['cat'] || 0).subscribe((num) => {
      this.controls.ddlCategory.setValue({CategoryNbr: +num});

      this.filterDepartments();
      
      this.route.params.map(params => params['dpt'] || 0).subscribe((dnum) => {
        this.controls.ddlDepartment.setValue({DepartmentNbr: +dnum});

        this.filterGroups();

        this.route.params.map(params => params['grp'] || '').subscribe((gname) => {
          this.controls.ddlGroup.setValue({GroupNm: gname.split('"').join('')});

          this.filterItems();

          this.route.params.map(params => params['itm'] || '').subscribe((iname) => { 
              this.controls.ddlItem.setValue({ItemKey: iname.split('"').join('')}); 
            });
        });
      });
    });
    console.log('Request Quote ' + 
    this.controls.ddlCategory.value['CategoryNbr'] + ', ' + 
    this.controls.ddlDepartment.value['DepartmentNbr'] + ', ' + 
    this.controls.ddlGroup.value['GroupNm'] + ', ' + 
    this.controls.ddlItem.value['ItemKey']);
  }

  ngOnInit() {
    try {
      // if data isn't loaded subscribe to the itemService so we know when the equpment data has been loaded
      if (this.itemService.isLoaded) {
        this.setData();
        this.setParameters();
      }
      else {
        this.itemService.loaded$.subscribe(
          data => {
            this.setData();
            this.setParameters();
          });
      }
      let retailUserJson: JSON = this.retailCustomerService.retailUser;
      if (retailUserJson != null) {
        this.controls.txtName.setValue(retailUserJson['FirstName'] + ' ' + retailUserJson['LastName']);
        this.controls.txtEmail.setValue(retailUserJson['Email']);
        this.controls.txtPhone.setValue(retailUserJson['Phone']);
        this.controls.txtCompany.setValue(retailUserJson['Company']);
      }

      // subscrib to user changes
      this.controls.ddlCategory.valueChanges.subscribe(() => {
        this.filterDepartments();
        if (this.controls.ddlDepartment.value !== null) {
          this.controls.ddlDepartment.setValue({DepartmentNbr: 0});
        }
      });
  
      this.controls.ddlDepartment.valueChanges.subscribe(() => {
          this.filterGroups();
          if (this.controls.ddlGroup.value !== null) {
            this.controls.ddlGroup.setValue({GroupNm: ''});
          }
      });
  
      this.controls.ddlGroup.valueChanges.subscribe(() => {
          this.filterItems();
      });
    }
    catch (error) {
      let msg = 'Error loading purchase quote form ';
      console.log(<any>error);
      if (typeof error === 'string' && error.length > 0) {
        msg += error;
      }
      this.msgBar.addAlert(msg, 'danger');
    }
  }


  private filterDepartments() {
    if (this.controls.ddlCategory.value['CategoryNbr'] !== undefined && +this.controls.ddlCategory.value['CategoryNbr'] !== 0) {
      this.filteredDepartments = this.departments.filter(item => item.CategoryNbr === +this.controls.ddlCategory.value['CategoryNbr']);
      return this.filteredDepartments;
    }
    else {
      this.filteredDepartments = this.departments;
      return this.filteredDepartments;
    }
  }

  private filterGroups() {
    if (this.controls.ddlDepartment.value['DepartmentNbr'] !== undefined && +this.controls.ddlDepartment.value['DepartmentNbr'] !== 0) {
        this.filteredGroups = this.groups.filter(item => item.DepartmentNbr === +this.controls.ddlDepartment.value['DepartmentNbr']);
        return this.filteredGroups;
    } else {
      this.filteredGroups = this.groups;
      return this.filteredGroups;
    }
  }

  private filterItems() {
    if (this.controls.ddlGroup.value['GroupNm'] !== undefined && this.controls.ddlGroup.value['GroupNm'].length > 0) {
      this.filteredItems = this.items.filter(item => item.ItemGroupNm === this.controls.ddlGroup.value['GroupNm']);
      return this.filteredItems;
    } else {
      this.filteredItems = this.items;
      return this.filteredItems;
    }
  }

  exitForm() {
    //this.router.navigate(['/specials']);
    history.back();
  }

  private onCancel() {
    if (this.purchaseQuoteForm.dirty) {
        const dialogRef = this.dialog.open(PageLeaveConfirmDialog, { width: '40%' });
        dialogRef.afterClosed().subscribe(result => {console.log(`Dialog result: ${result}`); });
      } else {
        this.exitForm();
      }
  }

  purchaseRequest(value) {
    try {
      this.isBusy = true;
      let retailUserEmail = '';
      let retailUserPhone = '';

      // Only get this if the uer is logged in
      if (this.retailCustomerService.retailUser !== null) {
        retailUserEmail = this.retailCustomerService.retailUser['Email'];
        retailUserPhone = this.retailCustomerService.retailUser['Phone'];
      }

      let json = `{
      "category" : "${this.controls.ddlCategory.value['CategoryNbr']}",
      "department" : "${this.controls.ddlDepartment.value}",
      "group" : "${this.controls.ddlGroup.value}",
      "itemKey" : "${this.controls.ddlItem.value['ItemKey']}",
      "item" : "${this.controls.ddlItem.value['ItemNm']}",      
      "model" : "${this.controls.txtModel.value}",
      "year" : "${this.controls.txtYear.value}",
      "approxHours" : "${this.controls.txtApproxHours.value}",
      "equipmentComment" : "${this.controls.txtequipmentComment.value}",
      "name" : "${this.controls.txtName.value}",
      "company" : "${this.controls.txtCompany.value}",
      "phone" : "${this.controls.txtPhone.value}",
      "email" : "${this.controls.txtEmail.value}",
      "comment" : "${this.controls.txtComment.value}",
      "deliveryRequired" : "${this.controls.ddlDelivery.value}",
      "Address1" : "${this.controls.txtAddress1.value}",
      "City" : "${this.controls.txtCity.value}",
      "state" : "${this.controls.ddlState.value}",
      "zip" : "${this.controls.txtZip.value}",
      "store" : "${this.controls.ddlStore.value['Branch']}",
      "customerUserEmail" : "${retailUserEmail}", 
      "customerUserPhone" : "${retailUserPhone}" 
      }`;

      json = json.replace(/undefined/g, '');
      console.log(json);
      this.apiService.Post('birchequipment/purchaserequest', json)
        .subscribe(data => {
          this.msgBar.addAlert('Purchase request submitted', 'success');
          history.back();
        },
        err => {
          this.msgBar.addAlert('Unable to submit submit request', 'danger');
        },
        () => {
          this.isBusy = false;
        });
    } catch (error) {
      let msg = 'Error submitting purchase quote. ';
      console.log(<any>error);
      if (typeof error === 'string' && error.length > 0) {
        msg += error;
      }
      this.msgBar.addAlert(msg, 'danger');
    }
  }
}
