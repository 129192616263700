import { Component, OnInit, OnChanges, EventEmitter, OnDestroy, Input, Output } from '@angular/core';
import { NgClass, NgIf, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CalendarModule, Calendar } from 'primeng/primeng';

import { Project, ProjectItem, ProjectService } from '../../service/project.service';
import { RetailUserService } from '../../service/retailuser.service';
import { MessagebarService } from '../../service/messagebar.service';
import { Item } from '../../service/item.service';

import { ShiftPipe } from '../../pipe/shift-pipe';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { MatSpinner } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';

import { BirchUtil } from '../../service/birchutil';

@Component({
  selector: 'app-item-settings',
  templateUrl: './item-settings.component.html',
  styleUrls: ['./item-settings.component.scss']
})

export class ItemSettingsComponent implements OnInit {
  @Input() projectItem?: ProjectItem;
  @Input() item?: Item;
  @Input() disabled?: boolean;
  @Output() onChange = new EventEmitter<ProjectItem>();

  private itemSettingsForm: FormGroup;
  private shiftOptions = ['Standby', 'Single', 'Double', 'Triple'];
    public minDate: Date = new Date();
    public itemStartDate: Date = new Date();
    public itemEndDate: Date = new Date();
    public invalidEndDate = false;
    public invalidStartDate = false;

  constructor(
    private _router: Router,
    private projectService: ProjectService,
    private retailCustomerService: RetailUserService,
    private msgBar: MessagebarService
  ) {

  }


  ngOnInit() {
    // If the project item or item didn't get created via @Input then create one
    if (this.projectItem === undefined || this.projectItem == null) {
      this.projectItem = new ProjectItem(null, '', 1, 1, new Date(), new Date(), '', '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '', '', '', '', '', -1, false, [], [], 0, '', 0, 0, 0);
    }
    if (this.item === undefined) {
      this.item = new Item('', 1, '', '', -1, '', '', '', '', '', null, null, '', false, false, false, false, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
    }
    if (this.disabled === undefined || this.disabled == null) {
      this.disabled = false;
    }

    // Not sure this is the best way to do this but for now.  I was having problem binding a Date() to the calendar control
    // so we bind to a string here and update it below in updateValues() which must be called before using the new value
    if (this.projectItem.ProjectionItemStartDate === undefined)    {
      this.projectItem.ProjectionItemStartDate = new Date();
    }
    if (this.projectItem.ProjectionItemEndDate === undefined)  {
      // var tomorrow = new Date(); // Today
      // tomorrow.setDate(tomorrow.getDate() + 1); // Add 1 to today's date
      // console.log(tomorrow);
      this.projectItem.ProjectionItemEndDate  = new Date();
    }
  }

  // just forward the event to our common checker
  checkQty(event) {
    return BirchUtil.checkQty(event);
  }

  minStartDate(): Date {
    return new Date();
  }

  minEndDate() {
    if (this.projectItem.ProjectionItemStartDate !== undefined || this.projectItem.ProjectionItemStartDate !== null) {
      return this.projectItem.ProjectionItemStartDate;
    }
    else {
      return new Date();
    }
  }

  setEndDate(){
    console.log("Fixing End Date");
    let temp = this;
    setTimeout(function() {
      if(temp.projectItem.ProjectionItemStartDate > temp.projectItem.ProjectionItemEndDate){
        temp.projectItem.ProjectionItemEndDate = temp.projectItem.ProjectionItemStartDate;
      }
    }, 100); // So when the change event fires the value hasn't changed

  }

    dateSelect(event, whichDate) {
        this.invalidStartDate = false;
        this.invalidEndDate = false;

        if (whichDate === 'startDate') {
            this.projectItem.ProjectionItemStartDate = new Date(event);
        }
        else if (whichDate === 'endDate') {
            this.projectItem.ProjectionItemEndDate = new Date(event);
        }
        if (this.itemStartDate > this.itemEndDate) {
            this.msgBar.addAlert('Invalid End date must be after Start date', 'warning');
            this.invalidEndDate = true;
            this.projectItem.ProjectionItemEndDate = this.itemEndDate = null;
        }
    }

  isCertRequired(): boolean {
    return (this.item.ItemGroupNm.toLowerCase().search('boom lift') !== -1
      || this.item.ItemGroupNm.toLowerCase().search('scissor lift') !== -1
      || this.item.ItemGroupNm.toLowerCase().search('forklift, reach') !== -1);
  }

  fix(index){
    document.getElementsByClassName("ui-datepicker")[index].children[0].parentElement.style.top = "-209px";
  }
}
