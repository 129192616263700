import { Component, OnInit } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

@Component({

  selector: 'app-train',
  templateUrl: './train.component.html',
  styleUrls: ['./train.component.scss']
})

export class TrainComponent implements OnInit {

  // TODO: get data from API customer/{id}/certifications
  private data: Array<any> = [
    {
      'certification': 'Genie Boom',
      'operator': 'Bob Smith',
      'completed': '2015/08/19',
      'expires': '2016/08/19',
      'actions': ''
    }, {
      'certification': 'Gehl Reach Forklift',
      'operator': 'Jane Doe',
      'completed': '2015/04/19',
      'expires': '2016/10/19',
      'actions': ''
    }, {
      'certification': 'Genie Scissor',
      'operator': 'Barb Wire',
      'completed': '2015/09/19',
      'expires': '2017/08/19',
      'actions': ''
    }];

  public rows: Array<any> = [];
  public columns: Array<any> = [
    { title: 'Certification', name: 'certification', sort: '' },
    { title: 'Operator/Trainer', name: 'operator', sort: '' },
    { title: 'Completed', name: 'completed', sort: 'desc' },
    { title: 'Expires.', name: 'expires', sort: '' },
    { title: 'Actions', name: 'actions' }
  ];
  public page: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '', columnName: 'certification' }
  };

  ngOnInit() {

  }




}
