import { Component, ViewChild, OnInit, Renderer } from '@angular/core';
import { Router } from '@angular/router';

import { FormsModule, FormGroup, FormControl, Validators } from '@angular/forms';

import { RetailUserService } from '../service/retailuser.service';
import { APIService } from '../service/api.service';

import { Observable } from 'rxjs';
import { MessagebarService } from '../service/messagebar.service';

// import {AlertComponent} from 'ng2-bootstrap/ng2-bootstrap';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatInput } from '@angular/material/input';
import { MatList } from '@angular/material/list';
import { MatSpinner } from '@angular/material/progress-spinner';
import { States } from '../service/states';
import { BirchUtil } from '../service/birchutil';
import { LocationService } from '../service/location.service';
import { PageLeaveConfirmDialog } from '../page-leave-confirm-dialog/page-leave-confirm-dialog';
import { MatDialog } from '@angular/material';

@Component({

  selector: 'app-parts-request',
  templateUrl: './parts-request.component.html',
  styleUrls: ['./parts-request.component.scss'],
  providers: [APIService],
})

export class PartsRequestComponent implements OnInit {

  states: Array<string> = States;
  stores: Array<any> = LocationService.locations;

  isBusy = false;
  // form variables
  partsRequestForm: FormGroup;
  name: string;
  company: string;
  phone: string;
  email: string;
  partDesc: string;
  qty: number;
  unitMake: string;
  unitModel: string;
  unitSerialNumber: string;
  engineMake: string;
  engineModel: string;
  engineSerialNumber: string;
  mfrProductCode: string;
  store: string;
  address1: string;
  city: string;
  state: string;
  zip: string;
  shipToIsSame = true;
  shipToAddress1: string;
  shipToCity: string;
  shipToState: string;
  shipToZip: string;
  urgency: string;
  comment: string;

  checkPhone = false;

  constructor(
    private router: Router,
    private apiService: APIService,
    private retailCustomerService: RetailUserService,
    private renderer: Renderer,
    private msgBar: MessagebarService,
    private dialog: MatDialog) {

    this.partsRequestForm = new FormGroup({
      // Left
      'txtPartDesc': new FormControl('', Validators.required),
      'txtMfrProductCode': new FormControl(''),
      'txtQty': new FormControl('', Validators.required),
      'txtUnitMake': new FormControl('', Validators.required),
      'txtUnitModel': new FormControl('', Validators.required),
      'txtUnitSerialNumber': new FormControl(''),
      'txtEngineMake': new FormControl(''),
      'txtEngineModel': new FormControl(''),
      'txtEngineSerialNumber': new FormControl(''),
      // Right
      'txtName': new FormControl('', Validators.required),
      'txtCompany': new FormControl(''),
      'txtPhone': new FormControl('', [Validators.required, BirchUtil.phoneValidator]),
      'txtEmail': new FormControl('',  [Validators.required, BirchUtil.emailValidator]),
      'txtComment': new FormControl(''),
      'ddlUrgency': new FormControl('', Validators.required),
      // Expandables
      'ddlStore': new FormControl('', (this.urgency === 'stockOrder') ? Validators.required : null),

      'txtAddress1': new FormControl('', (this.urgency === 'ground' || this.urgency === 'nextDay') ? Validators.required : null),
      'txtCity': new FormControl('', (this.urgency === 'ground' || this.urgency === 'nextDay') ? Validators.required : null),
      'ddlState': new FormControl('', (this.urgency === 'ground' || this.urgency === 'nextDay') ? Validators.required : null),
      'txtZip': new FormControl('', (this.urgency === 'ground' || this.urgency === 'nextDay') ? Validators.required : null),
      'chkShipToIsSame': new FormControl('', (this.urgency === 'ground' || this.urgency === 'nextDay') ? Validators.required : null),
      'txtShipToAddress1': new FormControl('', ((this.urgency === 'ground' || this.urgency === 'nextDay') && this.shipToIsSame === false) ? Validators.required : null),
      'txtShipToCity': new FormControl('', ((this.urgency === 'ground' || this.urgency === 'nextDay') && this.shipToIsSame === false) ? Validators.required : null),
      'ddlShipToState': new FormControl('', ((this.urgency === 'ground' || this.urgency === 'nextDay') && this.shipToIsSame === false) ? Validators.required : null),
      'txtShipToZip': new FormControl('', ((this.urgency === 'ground' || this.urgency === 'nextDay') && this.shipToIsSame === false) ? Validators.required : null)
    });
  }

  ngOnInit() {
    try {
      this.isBusy = true;
      // pre-populate name, company, email
      let retailUserJson: JSON = this.retailCustomerService.retailUser;
      if (retailUserJson != null) {
        this.name = retailUserJson['FirstName'] + ' ' + retailUserJson['LastName'];
        this.email = retailUserJson['Email'];
        this.phone = retailUserJson['Phone'];
        this.company = retailUserJson['Company'];

      }
    } catch (error) {
      let msg = 'Error loading parts request form ';
      console.log(<any>error);
      if (typeof error === 'string' && error.length > 0) {
        msg += error;
      }
      this.msgBar.addAlert(msg, 'danger');
    }
    this.isBusy = false;
  }

  partsRequest(value) {
    try {
      this.isBusy = true;
      let dateNow = Date();
      let retailUserEmail = '';
      let retailUserPhone = '';

      // Only get this if the uer is logged in
      if (this.retailCustomerService.retailUser !== null) {
        retailUserEmail = this.retailCustomerService.retailUser['Email'];
        retailUserPhone = this.retailCustomerService.retailUser['Phone'];
      }

      let json = `{
      "name" : "${this.name}",
      "company" : "${this.company}",
      "phone" : "${this.phone}",
      "email" : "${this.email}",
      "partDesc" : "${this.partDesc}",
      "qty" : "${this.qty}",
      "unitMake" : "${this.unitMake}",
      "unitModel" : "${this.unitModel}",
      "unitSerialNumber" : "${this.unitSerialNumber}",
      "engineMake" : "${this.engineMake}",
      "engineModel" : "${this.engineModel}",
      "engineSerialNumber" : "${this.engineSerialNumber}",
      "mfrProductCode" : "${this.mfrProductCode}",
      "store" : "${this.store}",
      "address1" : "${this.address1}",
      "city" : "${this.city}",
      "state" : "${this.state}",
      "zip" : "${this.zip}",
      "shipToIsSame" : "${this.shipToIsSame}",
      "shipToAddress1" : "${this.shipToAddress1}",
      "shipToCity" : "${this.shipToCity}",
      "shipToState" : "${this.shipToState}",
      "shipToZip" : "${this.shipToZip}",
      "urgency" : "${this.urgency}",
      "comment" : "${this.comment}",
      "poNumber" : "''",
      "requestedOn": "${dateNow}",
      "customerUserEmail" : "${retailUserEmail}",
      "customerUserPhone" : "${retailUserPhone}",
      "birchCustomerUserID" : "",
      "birchContactID" : "",
      "birchCustomerID" : ""
    }`;

      json = json.replace(/undefined/g, '');
      console.log(json);
      this.apiService.Post('birchequipment/partsrequest', json)
        .subscribe(data => {
          this.msgBar.addAlert('Parts request submitted', 'success');
          // this.router.navigate(['/specials']);
          history.back();
        },
        err => {
          this.msgBar.addAlert('Unable to submit parts request', 'danger');
        },
        () => {
          this.isBusy = false;
        });
    } catch (error) {
      let msg = 'Error requeting part ';
      console.log(<any>error);
      if (typeof error === 'string' && error.length > 0) {
        msg += error;
      }
      this.msgBar.addAlert(msg, 'danger');
    }
  }

  exitForm() {
    this.router.navigate(['/specials']);
  }

  onCancel() {
    if (this.partsRequestForm.dirty) {
      const dialogRef = this.dialog.open(PageLeaveConfirmDialog, { width: '40%' });
      dialogRef.afterClosed().subscribe(result => {console.log(`Dialog result: ${result}`); });
    } else {
      this.exitForm();
    }
  }
}
