import { Component, OnInit, TemplateRef, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'detail-confirm-dialog',
    templateUrl: 'detail-confirm-dialog.html',
    styleUrls: ['detail-confirm-dialog.scss'],
})

export class DetailConfirmDialog {

    constructor(public dialogRef: MatDialogRef<DetailConfirmDialog>) { }
    onNoClick(): void {
        this.dialogRef.close();
      }
}


