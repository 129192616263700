import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CartSubmitDialog } from '../cart-submit-dialog';
import { CartLoginDialog } from '../cart-login-dialog';

import { specialRentalItems, specialSaleItems, SpecialItem } from '../../service/examples.service';
import { CartItemService, CartItem } from '../../service/cart.service';

import { CalendarModule, Calendar } from 'primeng/primeng';
// import { BsModalComponent } from 'ng2-bs3-modal';

import { MessagebarService } from '../../service/messagebar.service';
import { RetailUserService } from '../../service/retailuser.service';
import {CartComponent} from '../cart.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss'],
  providers: [CartItemService],
})
export class DeliveryComponent implements OnInit {

  private cartItems: CartItem[] = CartItemService.currentItems;
  private request_delivery = true;
  private email: string;

  deliveryRequestForm: FormGroup;
  controls = {
      txtFirstName: new FormControl('', Validators.required),
      txtLastName: new FormControl('', Validators.required),
      txtAddress1: new FormControl('', Validators.required),
      txtAddress2: new FormControl(''),
      txtCity: new FormControl('', Validators.required),
      txtStateProvRegion: new FormControl('', Validators.required),
      txtZip: new FormControl('', Validators.required),
      txtDeliveryInstructions: new FormControl(''),
      txtSiteContactFirst: new FormControl(''),
      txtSiteContactLast: new FormControl(''),
      txtSiteContactPhone: new FormControl('')
  };

  constructor(
    private router: Router,
    private retailUserService: RetailUserService,
    private cartItemService: CartItemService,
    private msgBar: MessagebarService,
    private dialog: MatDialog
  ) {
    this.deliveryRequestForm = new FormGroup(this.controls);
    // this.country = retailUserService.country();
  }

  ngOnInit() {

    try {
      const retailUserJson: JSON = this.retailUserService.retailUser;
      if (retailUserJson != null) {

        this.controls.txtFirstName.setValue(retailUserJson['FirstName']);
        this.controls.txtLastName.setValue(retailUserJson['LastName']);
        this.email = retailUserJson['Email'];
      }
    } catch (error) {
      let msg = 'Error loading purchase quote form ';
      console.log(<any>error);
      if (typeof error === 'string' && error.length > 0) {
        msg += error;
      }
      this.msgBar.addAlert(msg, 'danger');
    }
  }

  loginPage() {
    this.router.navigate(['/login']);
  }

  specialsPage() {
    this.router.navigate(['/specials']);
  }

  routeCart() {
    this.router.navigate(['/cart']);
  }

  routeStore() {
    // TODO: wire this up onthis.SubmitModal.close();
    const temp = this;
    setTimeout(function () {temp.router.navigate(['/items']); }, 1000);
  }

  updateUrl(event) {
    event.path[0].src = 'http://i.imgur.com/hNcsOlE.png';
  }

  submitCart() {
    if (this.retailUserService.isLoggedIn()) {
      if (this.cartItems.length > 0 && this.deliveryRequestForm.valid) {
        // CartItems.clearItems();
        const delivery = {
          FirstName: this.controls.txtFirstName.value,
          LastName: this.controls.txtLastName.value,
          Email: this.email,
          Phone: this.retailUserService.phone(),
          Address1: this.controls.txtAddress1.value,
          Address2: this.controls.txtAddress2.value,
          City: this.controls.txtCity.value,
          State: this.controls.txtStateProvRegion.value,
          Zip: this.controls.txtZip.value,
          DeliveryInstructions: this.controls.txtDeliveryInstructions.value,
          SiteContactFirstName: this.controls.txtSiteContactFirst.value,
          SiteContactLastName: this.controls.txtSiteContactLast.value,
          SiteContactPhone: this.controls.txtSiteContactPhone.value
        };

        try {
          this.cartItemService.AddDeliveryToCart(delivery).subscribe(
            deliveryInfo => {
              console.log(deliveryInfo);
              this.showSubmitModal();  // Open Confirmation Modal
              this.cartItemService.PutCartNotes(CartComponent.notes).subscribe(
                cart => {
                  console.log('Updated Notes: ' + cart);
                  this.cartItemService.SubmitCart(CartComponent.store).subscribe(
                    cart => {
                      console.log('Submitted: ' + cart);
                      this.cartItemService.GetUserCurrentCart();
                      CartComponent.notes = '';
                    },
                    error => {
                      const msg = 'Unable to submit cart';
                      console.log(<any>error);
                    }
                  );
                },
                error => {
                  const msg = 'Unable to update notes';
                  console.log(<any>error);
                }
              );
              console.log('Submitting Cart:');
            },
            error => {
              let msg = 'Unable to add delivery information';
              console.log(<any>error);
              if (typeof error === 'string' && error.length > 0) {
                msg = error;
              }
              this.msgBar.addAlert(msg, 'danger');
            });
        } catch (error) {
          let msg = 'Unable to Submit Cart';
          console.log(<any>error);
          if (typeof error === 'string' && error.length > 0) {
            msg = error;
          }
          this.msgBar.addAlert(msg, 'danger');
        }

      }
    } else {
      this.showLoginModal();
    }
  }
  showSubmitModal(): void {
    const dialogRef = this.dialog.open(CartSubmitDialog, { width: '40%' });
    dialogRef.afterClosed().subscribe(result => {console.log(`Dialog result: ${result}`); });
  }
  showLoginModal(): void {
    const dialogRef = this.dialog.open(CartLoginDialog, { width: '40%' });
    dialogRef.afterClosed().subscribe(result => {console.log(`Dialog result: ${result}`); });
  }

  submitEdit() {

  }
}
