import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { Http, Response } from '@angular/http';
import { environment } from '../environments/environment';
import { APIService } from './api.service';
import { RetailUserService } from './retailuser.service';

export class Project {
  private items: ProjectItem[];

  constructor(
    public ProjectionID: string,
    public ProjectionName: string,
    public ProjectionLocation: string,
    public ProjectionStartDate: Date,
    public ProjectionEndDate: Date,
    public ProjectionJobComments: string,
    public ProjectionJobSite: string,
    public ProjectionJobNo: string,
    public ProjectionPONo: string,
    public ProjectionStatus: string,
    public ProjectionCreatorName: string
  ) {
    this.items = new Array();
  }

  public addItem(projectItem: ProjectItem) {
    this.items.push(projectItem);
  }

  public removeItem(id: string) {
    this.items.splice(this.getIndex(id), 1);
  }

  public getItem(id: string) {
    return this.items[this.getIndex(id)];
  }

  public getItems() {
    return this.items;
  }

  public setItems(projectItems: ProjectItem[]) {
    this.items = projectItems;
  }

  private getIndex(id: string) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].ProjectionItemEquipment == id) {
        return i;
      }
    }
  }
}

export class ProjectItem {
  public IsDeleted: boolean = false;
  public ProjectionItemRevisionDate: string = '';
  public ProjectionItemID: number = -1;

  constructor(
    public ProjectionID: number,
    public ProjectionItemEquipment: string,
    public ProjectionItemQuantity: number,
    public ProjectionItemShifts: number,
    public ProjectionItemStartDate: Date,
    public ProjectionItemEndDate: Date,
    public ProjectionItemOperatorCertification: string,
    public ProjectionItemDelivery: string,
    public ProjectionItemContractRateDay: number,
    public ProjectionItemContractRateWeek: number,
    public ProjectionItemContractRateMonth: number,
    public ProjectionItemListRateDay: number,
    public ProjectionItemListRateWeek: number,
    public ProjectionItemListRateMonth: number,
    public ProjectionItemCustomRateDay: number,
    public ProjectionItemCustomRateWeek: number,
    public ProjectionItemCustomRateMonth: number,
    public ProjectionItemMarketRateDay: number,
    public ProjectionItemMarketRateWeek: number,
    public ProjectionItemMarketRateMonth: number,
    public ProjectionItemEstCost: number,
    public ProjectionItemEquipmentDescription: string,
    public PONo: string,
    public JobNo: string,
    public PickedUpBy: string,
    public OrderedBy: string,
    public ParentProjectionItemID: number,
    public IsOptionalItem: boolean,
    public SuggestedItems: Array<any>,
    public OptionalItems: Array<any>,
    public SalePrice: number,
    public HierarchyTypNm: string,
    public DailyRate: number,
    public WeeklyRate: number,
    public MonthlyRate: number
  ) { }
}

@Injectable()
export class ProjectService {
  private baseUrl = environment.birchAPI;
  static tempProjectItemHelper: number;

  constructor(
    private http: Http,
    private retailCustomerService: RetailUserService, private apiService: APIService) {
  }

  // Get Requests

  public GetProjects(dateParams) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets';
    if (dateParams.length > 0) {
      dateParams = dateParams.replace('&', '?');
      cmd += dateParams;
    }
    return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProjects)
      .catch(this.handleError);
  }

  public GetActiveProjects(dateParams) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheetsactive';
    if (dateParams.length > 0) {
      dateParams = dateParams.replace('&', '?');
      cmd += dateParams;
    }
    return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProjects)
      .catch(this.handleError);
  }

  public GetHistoricalProjects(dateParams) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheetshistorical';
    if (dateParams.length > 0) {
      dateParams = dateParams.replace('&', '?');
      cmd += dateParams;
    }
    return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProjects)
      .catch(this.handleError);
  }

  public GetProjectItem(id: string, projectItemId: number) {
    ProjectService.tempProjectItemHelper = projectItemId;
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + id + '/items';
    return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProjectItem)
      .catch(this.handleError);
  }

  public GetProjectItemEdit(id: string, projectItemId: number) {
    console.log('customeruser' + this.retailCustomerService.retailUserID);
    console.log('planningsheets' + id);
    console.log('items' + projectItemId);
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + id + '/items/' + projectItemId;
    return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProjectItem)
      .catch(this.handleError);
  }

  public GetProjectItems(id: string) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + id + '/items';
    return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProjectItems)
      .catch(this.handleError);
  }

  public GetProject(id: string) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + id;
    return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProject)
      .catch(this.handleError);
  }

  public GetSharedUsers(id: string, status: string) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + id + '/users?status=' + status;
    return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .map(this.extractData)
      .catch(this.handleError);
  }

  public GetLockedProjects() {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheetsgetlocked';
    return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProjects)
      .catch(this.handleError);
  }

  // Post Requests

  public PostProject(project: Project) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets';
    return this.http.post(this.baseUrl + cmd, project, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProject)
      .catch(this.handleError);
  }

  public PostProjectItem(pId: string, projectItem: ProjectItem) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + pId + '/items';
    return this.http.post(this.baseUrl + cmd, projectItem, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProjectItem)
      .catch(this.handleError);
  }

  public PostSharedUsers(id: string, users: string[]) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + id + '/users';
    return this.http.post(this.baseUrl + cmd, users, { headers: this.apiService.httpHeaders() })
      .map(this.extractData)
      .catch(this.handleError);
  }

  // Put Requests

  public PutProject(project: Project) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + project.ProjectionID;
    return this.http.put(this.baseUrl + cmd, project, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProject)
      .catch(this.handleError);
  }

  public PutProjectItem(pId: string, projectItem: ProjectItem) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + pId + '/items/' + projectItem.ProjectionItemID;
    return this.http.put(this.baseUrl + cmd, projectItem, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProjectItem)
      .catch(this.handleError);
  }

  public CopyProject(project: Project) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + project.ProjectionID + '/copy';
    return this.http.post(this.baseUrl + cmd, project, { headers: this.apiService.httpHeaders() })
      .map(this.extractDataProject)
      .catch(this.handleError);
  }

  public SubmitProject(id: string) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + id + '/submit';
    return this.http.put(this.baseUrl + cmd, id, { headers: this.apiService.httpHeaders() })
      .map(this.extractData)
      .catch(this.handleError);
  }

  public ChangeRequest(id: string, requestMsg: JSON) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + id + '/changerequest';
    return this.http.post(this.baseUrl + cmd, requestMsg, { headers: this.apiService.httpHeaders() })
      .map(this.extractData)
      .catch(this.handleError);
  }


  public LockProject(id: string) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + id + '/lock';
    return this.http.put(this.baseUrl + cmd, id, { headers: this.apiService.httpHeaders() })
      .map(this.extractData)
      .catch(this.handleError);
  }

  // Delete Requests

  public DeleteProject(id: string) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + id;
    return this.http.delete(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .catch(this.handleError);
  }

  public DeleteProjectItem(pid: string, id: string) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + pid + '/items/' + id;
    return this.http.delete(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .catch(this.handleError);
  }

  public DeleteSharedUser(id: string, userid: string) {
    let cmd = '/customeruser/' + this.retailCustomerService.retailUserID + '/planningsheets/' + id + '/users/' + userid;
    return this.http.delete(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .catch(this.handleError);
  }

  // Extracting Data from responses

  private extractData(res: Response) {
    let body = res.json();
    if (res.status === 200) {

      return body;
    }
    return null;
  }

  private extractDataProject(res: Response) {
    let body = res.json();
    if (res.status === 200) {
      return body;
    }
    return null;
  }

  private extractDataProjects(res: Response) {
    let body = res.json();
    if (res.status === 200) {
      return body;
    }
    return null;
  }

  private extractDataProjectItem(res: Response) {
    let body = res.json();
    if (res.status === 200) {
      // see if we got an array or just a single object
      if (body instanceof Array) {
        let projectItems = ProjectService.objToProjectItems(body);
        // TODO Change this to use endpoint
        for (let i = 0; i < projectItems.length; i++) {
          if (projectItems[i].ProjectionItemID === ProjectService.tempProjectItemHelper) {
            return ProjectService.objToProjectItem(projectItems[i]);
          }
        }
        // If we just have a single return it we need the id for adding suggested and optional items
        if (projectItems.length === 1) {
          return projectItems[0];
        }
      }
      else {
        // single element just return it as item
        return ProjectService.objToProjectItem(body);
      }

    }

    return null;
  }

  private extractDataProjectItems(res: Response) {
    let body = res.json();
    if (res.status === 200) {
      return ProjectService.objToProjectItems(body);
    }
    return null;
  }

  // Static helper functions
  static objToProjectItem(obj: Object) {
    let result = new ProjectItem(null, '', 1, 1, new Date(), new Date(), '', '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '', '', '', '', '', -1, false, [], [], 0, '', 0, 0, 0);
    for (let property in result) {
      // If its a date field, format it correctly
      //if (property.endsWith('Date')) {
      //  let temp = new Date(obj[property]);
      //  result[property] = ProjectService.formatDateString(temp);
      //} else 
      if (property === 'ProjectionItemOperatorCertification' || property === 'ProjectionItemDelivery') {
        result[property] = (obj[property] === true) ? 'Yes' : '';
      }
      else {
        result[property] = obj[property];
      }
    }
    return result;
  }

  static objToProjectItems(obj: Object[]) {
    let projectItems: ProjectItem[] = new Array();
    for (let projectItem of obj) {
      projectItems.push(this.objToProjectItem(projectItem));
    }
    return projectItems;
  }

  // Error Handling
  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    if (error instanceof Response) {
      let resp: Response = error;
      let body = resp.json();
      if (body.Message) {
        errMsg = body.Message;
      }
    }

    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }
}
