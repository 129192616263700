
import {throwError as observableThrowError,  Observable ,  forkJoin, Observer } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
// import { Subject } from  'rxjs/Subject';
// import 'rxjs/add/operator/share';

import { Http, Headers, Response } from '@angular/http';
import { environment } from '../environments/environment';
import { APIService } from './api.service';
import { RetailUserService } from './retailuser.service';
import {NewItems} from '../items/new-item';
import {ItemService} from './item.service';

// import { AppComponent } from '../app.component';

export class SubmitableCartItem {
  constructor(
    public CartID: number,
    public ItemNum: number,
    public Quantity: number,
    public StartDate: Date,
    public EndDate: Date,
    public OperatorCertification: boolean,
    public Delivery: boolean,
    public DayRate: number,
    public WeekRate: number,
    public MonthRate: number,
    public EquipmentDescription: string,
    public CategoryDescription: string,
    public IsDeleted: boolean,
    public PickedUpBy: string,
    public ParentItemID: number,
    public IsOptionalItem: boolean,
    public SalePrice: number,
    public HierarchyTypNm: string,
  ) {
  }
}

export class CartItem {
  constructor(
    public CartID: string,
    public CartItemID: string,

    public ItemImageURL: string,
    public ItemNm: string,
    public ItemNum: string,
    public ItemKey: string,
    public StartDate: Date,
    public DisplayStartDate: string,
    public EndDate: Date,
    public DisplayEndDate: string,

    public Quantity: number,
    public PONo: string,
    public JobNo: string,
    public PickedUpBy: string,
    public OrderedBy: string,
    public IsOptionalItem: boolean,
    public SuggestedItems: Array<any>,
    public OptionalItems: Array<any>,
    public SalePrice: number,
    public HierarchyTypNm: string,
    public DailyRate: number,
    public WeeklyRate: number,
    public MonthlyRate: number,

    public ParentCartItemID: string,

    public CartItemEquipment: string,
    public CartItemShifts: number,
    public OperatorCertification: string,
    public Delivery: string,
    public CartItemContractRateDay: number,
    public CartItemContractRateWeek: number,
    public CartItemContractRateMonth: number,
    public CartItemListRateDay: number,
    public CartItemListRateWeek: number,
    public CartItemListRateMonth: number,
    public CartItemCustomRateDay: number,
    public CartItemCustomRateWeek: number,
    public CartItemCustomRateMonth: number,
    public CartItemMarketRateDay: number,
    public CartItemMarketRateWeek: number,
    public CartItemMarketRateMonth: number,
    public CartItemEstCost: number,
    public DisplayCartItemEstCost: string,
    public CartItemEquipmentDescription: string,

    public CategoryNm: string = '',
    public ItemDesc: string = '',
  ) {
  }
}

@Injectable()
export class CartItemService {
    static currentItems: CartItem[] = [];
    static cartId: number;
    static self: any;

    private errorMessage: string;

    private baseUrl = environment.birchAPI;

    constructor(private http: Http,
                private apiService: APIService,
                private retailUserService: RetailUserService,
                private itemService: ItemService,
    ) {
      CartItemService.self = this;
    }

// Get Requests --------------------------------------------------------------------------------------------------------
  public GetAllCarts() {
      let cmd = 'retailuser/' + this.retailUserService.retailUserID + '/cart/history';
      return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
          .map(this.extractAllCartsData)
          .catch(this.handleError);
  }

  public GetUserCurrentCart() {
    // IF LOGGED IN
    if (this.retailUserService.retailUserID != undefined) {
      console.log('Get Cart For User: ');
      console.log(this.retailUserService.retailUserID);
      let cmd = 'retailuser/' + this.retailUserService.retailUserID + '/cartcurrent';
      return this.http.get(this.baseUrl + cmd, {headers: this.apiService.httpHeaders()})
        .map(this.extractCartData)
        .catch(this.handleError);
    }
    // IF NOT LOGGED IN
    else {
      return Observable.create(observer => {
        let newCartItems = JSON.parse(localStorage.getItem('CartItems'));
        if (newCartItems == null) {
          newCartItems = [];
        }

        let cart = {
          CartItems : newCartItems
        };

        this.loadDataFromCart(cart, true);

        observer.next();
        observer.complete();
      });
    }
  }

  public SyncLocalToServer() {
    return Observable.create(observer => {
      this.GetUserCurrentCart().subscribe(
        () => {
          let observables = [];

          let localCartItems = JSON.parse(localStorage.getItem('CartItems')) || [];

          if (localCartItems.length > 0) {
            for (let item of CartItemService.currentItems) {
              let cartItem = item as CartItem;
            }

            if (localCartItems) {
              for (let item of localCartItems) {
                let cartItem = item as CartItem;
                observables.push(this.PostUserCartItem(cartItem));
              }
            }
          } else {
            observables.push(Observable.create(observe => {observe.next(); observe.complete();}));
          }

          // When all requests have completed
          forkJoin(observables).subscribe(
            () => {
              localStorage.removeItem('CartItems');
              console.log('Synced Server\'s Cart');
              observer.complete();
            }
          );
        },
        error => {
          this.handleError(error);
        }
      )
    });
  }
// Post or Put Requests ------------------------------------------------------------------------------------------------

  public PutUserCartItem(CartItem) {
    if (typeof(CartItem.StartDate) === typeof('')) {
      CartItem.StartDate = new Date(CartItem.StartDate);
    }
    if (typeof(CartItem.EndDate) == typeof('')) {
      CartItem.EndDate = new Date(CartItem.EndDate);
    }
    if(typeof CartItem.Delivery === 'string') {
      CartItem.Delivery = (CartItem.Delivery === 'true');
    }

    // IF LOGGED IN
    if (this.retailUserService.retailUserID !== undefined) {
      let SentItem = new SubmitableCartItem(
        +CartItemService.cartId, // public CartID: number,
        +CartItem.ItemNum, // public ItemNum: number,
        +CartItem.Quantity, // public Quantity: number,
        CartItem.StartDate.toISOString(), // public StartDate: Date,
        CartItem.EndDate.toISOString(), // public EndDate: Date,
        CartItem.OperatorCertification === 'true', // public OperatorCertification: boolean,
        CartItem.Delivery, // public Delivery: boolean,
        CartItem.DailyRate, // public DailyRate: number,
        CartItem.WeeklyRate, // public WeeklyRate: number,
        CartItem.MonthlyRate, // public MonthlyRate: number,
        CartItem.ItemNm, // public EquipmentDescription: string,
        CartItem.CategoryNm, // public CategoryDescription: string,
        false, // public IsDeleted: boolean,
        CartItem.PickedUpBy, // public PickedUpBy: string,
        +CartItem.ParentCartItemID, // public ParentItemID: number,
        CartItem.IsOptionalItem, // public IsOptionalItem: boolean,
        CartItem.SalePrice, // public SalePrice: number,
        CartItem.HierarchyTypNm, // public HierarchyTypNm: string,
      );
      console.log('Putting Cart Item Changes:');
      console.log(SentItem);
      let cmd = 'retailuser/' + this.retailUserService.retailUserID + '/cart/' + CartItemService.cartId + '/items/' + CartItem.CartItemID;
      return Observable.create (observer => {
        return this.http.put(this.baseUrl + cmd, SentItem, {headers: this.apiService.httpHeaders()})
          .map(this.extractItemData)
          .catch(this.handleError)
          .subscribe(
            (item) => {
              // REFRESH
              this.GetUserCurrentCart()
                .subscribe(() => {
                  observer.next(item);
                  observer.complete();
                });
          });
      });
    } else {
      // IF NOT A CHILD ITEM
      if (!CartItem.ParentCartItemID) {
        localStorage.setItem('CartItems', JSON.stringify(CartItemService.currentItems));
      } else {
        let index = 1;
        for (let item of CartItemService.currentItems) {
          if (+item.CartItemID === +CartItem.ParentCartItemID) {
            break;
          } else {
            ++index;
          }
        }
        localStorage.setItem('CartItems', JSON.stringify(CartItemService.currentItems));
      }
      // REFRESH
      return Observable.create( observer => {
        this.GetUserCurrentCart()
          .subscribe(() => {
            observer.next(CartItem);
            observer.complete();
          });
      });
    }
  }

  public PostUserCartItem(CartItem) {
    if (typeof(CartItem.StartDate) === typeof('')) {
      CartItem.StartDate = new Date(CartItem.StartDate);
    }
    if (typeof(CartItem.EndDate) === typeof('')) {
      CartItem.EndDate = new Date(CartItem.EndDate);
    }
    if (typeof CartItem.Delivery === 'string') {
      CartItem.Delivery = (CartItem.Delivery === 'true');
    }

    // IF LOGGED IN
    if (this.retailUserService.retailUserID !== undefined) {
      let SentItem = new SubmitableCartItem(
          +CartItemService.cartId, // public CartID: number,
          +CartItem.ItemNum, // public ItemNum: number,
          +CartItem.Quantity, // public Quantity: number,
          CartItem.StartDate.toISOString(), // public StartDate: Date,
          CartItem.EndDate.toISOString(), // public EndDate: Date,
          CartItem.OperatorCertification === 'true', // public OperatorCertification: boolean,
          CartItem.Delivery, // public Delivery: boolean,
          // CartItem.OperatorCertification==="true", // public OperatorCertification: boolean,
          // CartItem.Delivery==="true", // public Delivery: boolean,
          CartItem.DailyRate, // public DailyRate: number,
          CartItem.WeeklyRate, // public WeeklyRate: number,
          CartItem.MonthlyRate, // public MonthlyRate: number,
          CartItem.ItemNm, // public EquipmentDescription: string,
          CartItem.CategoryNm, // public CategoryDescription: string,
          false, // public IsDeleted: boolean,
          CartItem.PickedUpBy, // public PickedUpBy: string,
          +CartItem.ParentCartItemID, // public ParentItemID: number,
          CartItem.IsOptionalItem, // public IsOptionalItem: boolean,
          CartItem.SalePrice, // public SalePrice: number,
          CartItem.HierarchyTypNm, // public HierarchyTypNm: string,
      );
      // console.log("Posting Cart Item:");
      // console.log(SentItem);
      let cmd = 'retailuser/' + this.retailUserService.retailUserID + '/cart/' + CartItemService.cartId + '/items';
      return Observable.create (observer => {
        this.http.post(this.baseUrl + cmd, SentItem, {headers: this.apiService.httpHeaders()})
          .map(this.extractItemData)
          .catch(this.handleError)
          .subscribe(
            (item) => {
            // REFRESH
            this.GetUserCurrentCart()
              .subscribe(() => {
                observer.next(item);
                observer.complete();
              });
          });
      });
    } else {
      let uniq = 'id' + (new Date()).getTime();
      CartItem.CartItemID = uniq;

      CartItemService.currentItems.push(CartItem);
      localStorage.setItem('CartItems', JSON.stringify(CartItemService.currentItems));
      // REFRESH

      return Observable.create( observer => {
        this.GetUserCurrentCart()
          .subscribe(() => {
            observer.next(CartItem);
            observer.complete();
          });
      });
    }
  }

  public PutCartNotes(notes) {
    // IF LOGGED IN
    if (this.retailUserService.retailUserID !== undefined) {
      let cmd = 'retailuser/' + this.retailUserService.retailUserID + '/cart/' + CartItemService.cartId + '/notes';
      let notesObj = {
        notes: notes
      };
      return this.http.post(this.baseUrl + cmd, notesObj, {headers: this.apiService.httpHeaders()})
        .catch(this.handleError);
    }
  }

  public AddDeliveryToCart(deliveryObject) {
    // IF LOGGED IN
    if (this.retailUserService.retailUserID !== undefined) {
      let cmd = 'retailuser/' + this.retailUserService.retailUserID + '/cart/' + CartItemService.cartId + '/delivery';
      return this.http.post(this.baseUrl + cmd, deliveryObject, {headers: this.apiService.httpHeaders()})
        .catch(this.handleError);
    }
  }

  public SubmitCart(store) {
    // IF LOGGED IN
    if (this.retailUserService.retailUserID !== undefined) {
      let cmd = 'retailuser/' + this.retailUserService.retailUserID + '/cart/' + CartItemService.cartId;
      return Observable.create (observer => {
      return this.http.put(this.baseUrl + cmd, store, {headers: this.apiService.httpHeaders()})
        .catch(this.handleError)
        .subscribe(() => {
          // REFRESH
          this.GetUserCurrentCart()
            .subscribe(() => {
              observer.next();
              observer.complete();
            });
        });
      });
    }
  }

// Delete Requests -----------------------------------------------------------------------------------------------------

  public DelCartItem(cartItem) {
    // IF LOGGED IN
    if (this.retailUserService.retailUserID !== undefined) {
      let cmd = 'retailuser/' + this.retailUserService.retailUserID + '/cart/' + CartItemService.cartId + '/items/' + cartItem.CartItemID;
      return Observable.create (observer => {
        this.http.delete(this.baseUrl + cmd, {headers: this.apiService.httpHeaders()})
          .catch(this.handleError)
          .subscribe(() => {
            // REFRESH
            this.GetUserCurrentCart()
              .subscribe(() => {
                observer.next();
                observer.complete();
              });
          });
      });
    } else {
      for (let i = 0; i < CartItemService.currentItems.length; i++) {
        if (CartItemService.currentItems[i].ItemKey === cartItem.ItemKey) {
          CartItemService.currentItems.splice(i, 1);
        }
      }
      localStorage.setItem('CartItems', JSON.stringify(CartItemService.currentItems));
      // REFRESH
      return this.GetUserCurrentCart();
    }
  }

// Helper Functions ----------------------------------------------------------------------------------------------------

  private extractItemData(res: Response) {
      let body: any = res.json();
      if (res.status === 200) {
          return body;
      }
      return null;
  }

  private extractCartData(res: Response) {
    let body: any = res.json();
    if (res.status === 200) {
      let cart = body[0];
      CartItemService.cartId = cart.CartID;
      return CartItemService.self.loadDataFromCart(cart, true);
      // CartItemService.currentItems.length = 0;
      // for (let item of cart.CartItems) {
      //   CartItemService.currentItems.push(item);
      // }
      // return cart;
    }
  }



  // private extractCurrentCartData(res: Response) {

  //   let Carts = CartItemService.self.extractCartData(res);
  //   for (let item of Carts.CartItems) {
  //     CartItemService.currentItems.push(item);
  //   }
  //   console.log("Carts:");
  //   console.log(Carts);
  //   return Carts;
  // }

  private loadDataFromCart(cart, refresh= false) {
    if (refresh) {
      CartItemService.currentItems.length = 0;
    }

    for (let i = 0; i < cart.CartItems.length; i++) {
      let item = null;
      for (let searchingItem of NewItems.newItems) {
        if (+searchingItem.ItemNum === cart.CartItems[i].ItemNum) {
          item = searchingItem;
          break;
        }
      }

      let cartItem = new CartItem(
        CartItemService.cartId + '',
        cart.CartItems[i].CartItemID,
        item ? item.ItemImageURL : cart.CartItems[i].ItemImageURL,
        cart.CartItems[i].EquipmentDescription || cart.CartItems[i].CartItemEquipmentDescription || cart.CartItems[i].ItemNm || item.ItemNm,
        item ? item.ItemNum : cart.CartItems[i].ItemNum,
        item ? item.ItemKey : cart.CartItems[i].ItemKey,
        new Date(cart.CartItems[i].StartDate),
        cart.CartItems[i].StartDate,
        new Date(cart.CartItems[i].EndDate),
        cart.CartItems[i].EndDate,
        cart.CartItems[i].Quantity,
        cart.CartItems[i].PONo,
        cart.CartItems[i].JobNo,
        cart.CartItems[i].PickedUpBy,
        cart.CartItems[i].OrderedBy,
        cart.CartItems[i].IsOptionalItem,
        cart.CartItems[i].SuggestedItems,
        cart.CartItems[i].OptionalItems,
        item ? item.SalePrice : cart.CartItems[i].SalePrice,
        cart.CartItems[i].HierarchyTypNm,
        item ? item.DailyRate : cart.CartItems[i].DailyRate,
        item ? item.WeeklyRate : cart.CartItems[i].WeeklyRate, // Weekly Rate
        item ? item.MonthlyRate : cart.CartItems[i].MonthlyRate, // Monthly Rate

        cart.CartItems[i].ParentCartItemID, // Parent Item ID

        '1', // Equipment ID?
        -1,
        cart.CartItems[i].OperatorCertification, // OperatorCertification
        cart.CartItems[i].Delivery, // Delivery
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        cart.CartItems[i].EstimatedCost ? cart.CartItems[i].EstimatedCost : 0, // Estimated Cost #
        cart.CartItems[i].EstimatedCost ? cart.CartItems[i].EstimatedCost + '' : 0 + '',
        item ? item.ItemNm : cart.CartItems[i].CartItemEquipmentDescription,
        item ? item.CategoryNm : cart.CartItems[i].CategoryNm,
        item ? item.ItemNm : cart.CartItems[i].ItemDesc
      );

      if (item != null) {
        if (cartItem.HierarchyTypNm !== 'RENTAL') {
          if (item ? item.FeatureItemDiscountPercent : null != null) {
            cartItem.CartItemEstCost = item.SaleFeatureValue ? item.SaleFeatureValue : 0;
            cartItem.DisplayCartItemEstCost = cartItem.CartItemEstCost.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            });
            // console.log('Special:'+Item.SaleFeatureValue);
          } else {
            cartItem.CartItemEstCost = cartItem.SalePrice ? cartItem.SalePrice : 0;
            cartItem.DisplayCartItemEstCost = cartItem.CartItemEstCost.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            });
            // console.log('Sale:'+Item.SalePrice);
          }
        } else {
          cartItem.CartItemEstCost = CartItemService.self.GetEstimateCost(item, cartItem.StartDate, cartItem.EndDate, cartItem.DailyRate, cartItem.WeeklyRate, cartItem.MonthlyRate, cartItem.SalePrice, cartItem.HierarchyTypNm);
          cartItem.DisplayCartItemEstCost = cartItem.CartItemEstCost.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          });
        }
      } else {
        cartItem.DisplayCartItemEstCost = 'TBD';
      }

      cart.CartItems.splice(i, 1, cartItem);

      if (refresh) {
        CartItemService.currentItems.push(cartItem);
      }
    }

    return cart;
  }

  private extractAllCartsData(res: Response) {
    let Carts: CartItem[] = res.json();
    for (let Cart of Carts) {
      Cart = CartItemService.self.loadDataFromCart(Cart);
    }
    console.log('Carts:');
    console.log(Carts);

    if (res.status === 200) {
        return Carts;
    }
    return null;
  }

  // private extractCurrentCartData(res: Response) {
  // }

  private handleError(error: any) {
      let errMsg = (error.message) ? error.message :
          error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      if (error instanceof Response) {
          let resp: Response = error;
          let body = resp.json();
          if (body.Message) {
              errMsg = body.Message;
          }
      }

      console.error(errMsg);
      return observableThrowError(errMsg);
  }

  public GetEstimateCost(item, StartDate, EndDate, dailyRate: number, weeklyRate: number, monthlyRate: number, SalePrice, HierarchyTypNm) {

    StartDate.setHours(0, 0, 0, 0);
    EndDate.setHours(0, 0, 0, 0);

    if (item.HierarchyTypNm === 'USED' && HierarchyTypNm === 'RENTAL') {
      return -1;
    } else {
      if (HierarchyTypNm !== 'RENTAL') {
        return SalePrice;
      } else {
        let daysOnRent = 0;
        // Server Code Adapted
        if (EndDate.getTime() - StartDate.getTime() === 0) {
          daysOnRent = 1;
          console.log('Rental Days: ' + daysOnRent);
        } else {
          daysOnRent = ((EndDate.getTime() - StartDate.getTime()) / 86400000); // 86400000ms in a day
          console.log('Rental Days: ' + daysOnRent);
        }
        let estCost = 0;


        if (dailyRate === 0) {
          if (weeklyRate !== 0) {
            dailyRate = weeklyRate;
          } else {
            dailyRate = monthlyRate;
          }
        }
        if (weeklyRate === 0) {
          if (dailyRate !== 0) {
            weeklyRate = dailyRate * 7;
          } else {
            weeklyRate = monthlyRate;
          }
        }
        if (monthlyRate === 0) {
          if (dailyRate !== 0) {
            monthlyRate = weeklyRate * 4;
          } else {
            monthlyRate = dailyRate * 28;
          }
        }

        let MonthsRemainder: number = daysOnRent % 28;
        let decimal_Months: number = daysOnRent / 28;
        let whole_Months: number = Math.floor(decimal_Months);

        let WeeksRemainder: number = MonthsRemainder % 7;
        let decimal_Weeks: number = MonthsRemainder / 7;
        let whole_Weeks: number = Math.floor(decimal_Weeks);

        let whole_Days: number = WeeksRemainder;

        // console.log("Days:"+whole_Days);
        // console.log("Weeks:"+whole_Weeks);
        // console.log("Months:"+whole_Months);

        let daysTotal: number = whole_Days * dailyRate;
        // console.log("Days Cost:"+daysTotal);
        let weeksTotal: number = whole_Weeks * weeklyRate;
        // console.log("Weeks Cost:"+weeksTotal);
        let monthTotal: number = whole_Months * monthlyRate;
        // console.log("Months Cost:"+monthTotal);

        // Roll things up to the next unit if its more at the lower time interval
        if (daysTotal > weeklyRate) {
          daysTotal = 0;
          whole_Weeks++;
          weeksTotal = whole_Weeks * weeklyRate;
        }
        if (weeksTotal + daysTotal > monthlyRate) {
          daysTotal = 0;
          weeksTotal = 0;
          whole_Months++;
          monthTotal = whole_Months * monthlyRate;
        }

        estCost = daysTotal + weeksTotal + monthTotal;
        estCost = (estCost * ((100 - item.FeatureItemDiscountPercent) / 100)); // Add discount percent
        return estCost;
      }
    }
  }
}
