import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'equipmentTypePipe'
})
export class EquipmentTypePipe implements PipeTransform {

  transform(values: any, filterValue?: string): any {
    //debugger;
    //if we have a filter value then apply it otherwise return everything.
    if (filterValue && filterValue.length > 0) {
      return values.filter(item => (item.includes(filterValue) || item.includes('---- Select')));
    }
    else {
      return values;
    }
  }
}
