import { Routes } from '@angular/router';


import { ItemsComponent } from './';
import { ItemsAllComponent } from './all';
import { ItemsDetailComponent } from './detail';

import { RetailUserService } from '../service/retailuser.service';
import { AuthGuard} from '../app.auth.guard';

export const ItemsRoutes: Routes = [
  { path: 'items', component: ItemsComponent, children: [
    { path: '', component: ItemsAllComponent,  },
    { path: ':id', component: ItemsDetailComponent, },
    { path: 'all', component: ItemsAllComponent, },
    { path: 'detail/:id', component: ItemsDetailComponent, }
    //{ path: '**', component: ItemsAllComponent, }
  ] }
];
