import { Component, OnInit, TemplateRef, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'cart-login-dialog',
    templateUrl: 'cart-login-dialog.html',
    styleUrls: ['cart-login-dialog.scss'],
})

export class CartLoginDialog {

    constructor(public dialogRef: MatDialogRef<CartLoginDialog>) { }
    onNoClick(): void {
        this.dialogRef.close();
      }

      createAccount(): void {
        this.dialogRef.close('/create-account');
      }
      login(): void {
        this.dialogRef.close('/login');
      }
}


