import { Component, OnInit } from '@angular/core';
import {NewItems} from "../items/new-item";
import {SpecialItem} from "../service/specials.service";
import {ItemService} from "../service/item.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-used',
  templateUrl: './used.component.html',
  styleUrls: ['./used.component.scss']
})
export class UsedComponent implements OnInit {
  usedcategories: Array<any> = new Array();
  usedItems: SpecialItem[] = NewItems.usedItems;

  public refreshed: boolean = NewItems.refreshed;

  constructor(
    private router: Router,
    private itemService: ItemService,) { }

  ngOnInit() {
    this.sortUsedItems();


    if (this.itemService.isLoaded) {
      this.Loading();
    }
    else {
      this.itemService.loaded$.subscribe(
        data => {
          this.Loading();
        });
    }
  }

  itemImage(imgUrl: string): string {
    let img = 'http://i.imgur.com/hNcsOlE.png';
    if (imgUrl !== null) {
      img = imgUrl;
    }
    return img;
  }

  sortUsedItems(){
    let Items = this.usedItems;
    let Categories = new Array();

    for (let i = 0; i < Items.length; i++) {
      // Check if Category has been added yet
      let hasCategory = false;
      for (let j = 0; j < Categories.length; j++) {
        if (Categories[j].CategoryNm == Items[i].DepartmentNm) {
          hasCategory = true;
          break;
        }
      }
      // If Category hasn't been added, add it.
      if (!hasCategory) {
        // if (Items[i].DefaultCatItemKeyInd == true) {
        Categories.push({
          ImageURL: Items[i].ItemImageURL,
          CategoryNm: Items[i].DepartmentNm,
        });
        // }
      }
    }

    this.usedcategories = Categories.sort(function (a, b) { return (a.CategoryNm > b.CategoryNm) ? 1 : ((b.CategoryNm > a.CategoryNm) ? -1 : 0); });
    console.log(this.usedcategories);
  }

  updateUrl(event) {
    event.path[0].src = 'http://i.imgur.com/hNcsOlE.png';
  }

  public download() {

    // Open used in new window
    let element = document.getElementsByClassName("Items-Container")[0];
    let tempElement = document.createElement('div');
    tempElement.appendChild(element);
    let data = tempElement.innerHTML;

    let html = '<html><head><title>Used items</title><style>'+UsedComponent.getStyle('.Items-Container')+'</style></head>'+data+'<body>';
    let newWindow = window.open("data:text/html," + encodeURIComponent(html),
      "_blank");
    newWindow.focus();
  }

  private static getStyle(className) {
    let style = "";
    let sheets = document.styleSheets;
    for (let i = 0; i < sheets.length; i++) {
      let classes = (sheets[i] as CSSStyleSheet).cssRules;
      if (classes) {
        for (let j = 0; j < classes.length; j++) {
          let selector = (classes[j] as CSSStyleRule).selectorText;
          if (selector) {
            if (selector.indexOf(className) >= 0) {
              style += classes[j].cssText;
            }
          }
        }
      }
    }
    style += "p {margin-top: 0;} .Item-Name{margin-bottom: 0 !important;} .Item-Column, .Item-Description {overflow: visible !important} .Item-Image {display: block !important} .Item {max-height: 500 !important}";
    return style;
  }

  routeItem(itemNum){
    this.router.navigate(['/items/'+itemNum]);
  }

    routeCategory(CategoryNm){
        this.router.navigate(['/items', {cat: "Sales - Used Equipment", dep: CategoryNm}]);
    }


    Loading() { // {Check For Items Arrival, Refresh When Available}
    if (NewItems.categories.length > 1 && NewItems.refreshed === false) { // NewItems Is Static Class Holding Items/Categorys/Departments/Groups

      NewItems.refreshed = true; // Stop All Loops From Refreshing Again Some Do Not Have Timers (My Poor Design Choice)
      this.refreshed = true;

      // Run ngOnInit() stuff again
      this.usedItems = NewItems.usedItems;
      this.sortUsedItems();
    }
  }

  formatDollar(num) {
    var p = num.toFixed(2).split(".");
    return "$" + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
        return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
      }, "") + "." + p[1];
  }
}
