import { Item } from '../service/item.service';
import { SpecialItem } from '../service/specials.service';
import { EventEmitter } from '@angular/core';
import { ItemsAllComponent } from './all';

export class NewItems {
    static viewedItem: Item;

    static newItems: Array<Item> = NewItems.SetUpItems();
    static categories: Array<any> = new Array();
    static departments: Array<any> = new Array();
    static groups: Array<any> = new Array();
    static categorizedItems: Array<Item> = new Array();     // Category Items
    static departmentedItems: Array<Item> = new Array();    // Category>Department Items
    static groupItems: Array<Item> = new Array();           // Category>Department>Group Items
    static searchedItems: Array<Item> = new Array();        // Search Terms Filter Items
    static currentItems: Array<Item> = new Array();         // Displayed Items

    static usedItems: SpecialItem[] = new Array();          // Displayed UsedItems
    static specialItems: SpecialItem[] = new Array();       // Displayed SpecialItems

    static defaultItems: Array<Item> = new Array();         // Category>Department>Group Items

    static searchTerms: string[] = new Array();
    static page: number = 1;
    static pageSize: number = 40;

    static refreshed: boolean = false;

    static category: any;
    static categoryChange: EventEmitter<any> = new EventEmitter<any>();

    static categoryUpdate = {
        update: false
    }

    private static defaultImage: string = 'http://i.imgur.com/hNcsOlE.png';

    constructor() {
        NewItems.clearItems();
    }

    static clearItems() {
        this.newItems = new Array<Item>();
        this.usedItems = new Array<SpecialItem>();
        this.specialItems = new Array<SpecialItem>();
    }

    // static debugFind (item) {return item.ItemKey=="0020-4600"}
    // static debugFind (item) {return item.HierarchyTypNm!="USED"&&item.HierarchyTypNm!="RENTAL"&&item.HierarchyTypNm!="NEW"}
    static debugFind(item) { item.ItemKey == "0020-4600#07" }
    static usedFind(item) { return item.HierarchyTypNm == "USED" }
    static specialFind(item) { return item.FeatureItemDiscountPercent != null }


    static calcSales() {
        for (let item of this.specialItems) {
            item.SaleFeatureValue = +(item.SalePrice - (item.SalePrice * item.FeatureItemDiscountPercent / 100)).toFixed(2);
            item.DailyFeatureValue = +(item.DailyRate - (item.DailyRate * item.FeatureItemDiscountPercent / 100)).toFixed(2);
            item.WeeklyFeatureValue = +(item.WeeklyRate - (item.WeeklyRate * item.FeatureItemDiscountPercent / 100)).toFixed(2);
            item.MonthlyFeatureValue = +(item.MonthlyRate - (item.MonthlyRate * item.FeatureItemDiscountPercent / 100)).toFixed(2);
            item.FeatureItemDiscountPercentDisplay = item.FeatureItemDiscountPercent;
        }
        for (let item of this.specialItems) {
            item.SaleFeatureValue = +(item.SalePrice - (item.SalePrice * item.FeatureItemDiscountPercent / 100)).toFixed(2);
            item.DailyFeatureValue = +(item.DailyRate - (item.DailyRate * item.FeatureItemDiscountPercent / 100)).toFixed(2);
            item.WeeklyFeatureValue = +(item.WeeklyRate - (item.WeeklyRate * item.FeatureItemDiscountPercent / 100)).toFixed(2);
            item.MonthlyFeatureValue = +(item.MonthlyRate - (item.MonthlyRate * item.FeatureItemDiscountPercent / 100)).toFixed(2);
            item.FeatureItemDiscountPercentDisplay = item.FeatureItemDiscountPercent;
        }
    }

    static setItems(arr: Array<any>) {
        console.log('Example Of Incoming Items: ');
        console.log(arr[0]);
        this.clearItems();
        arr = arr.sort(function (a, b) { return (a.ItemNmSortPrefix > b.ItemNmSortPrefix) ? 1 : ((b.ItemNmSortPrefix > a.ItemNmSortPrefix) ? -1 : 0); });
        arr = arr.sort(function (a, b) { return (a.Order > b.Order) ? 1 : ((b.Order > a.Order) ? -1 : 0); });

        this.newItems = arr;    // All Items Available
        this.categorizedItems = this.newItems;
        this.departmentedItems = this.newItems;
        this.groupItems = this.newItems;

        let itemCategories = new Array();
        let itemDepartments = new Array();
        let itemGroups = new Array();

        // console.log("Debug Search:");
        // console.log(arr.filter(this.debugFind));

        this.usedItems = arr.filter(this.usedFind);
        this.specialItems = arr.filter(this.specialFind);

        this.specialItems = this.sortSpecials(this.specialItems);
        this.calcSales();

        // ----------------------------------------------------------------------------------------------------------------------------------------------
        // Createing Category/Department/Group Lists ----------------------------------------------------------------------------------------------------
        // ----------------------------------------------------------------------------------------------------------------------------------------------

        // Add Categories/Departments/Groups
        for (let i = 0; i < arr.length; i++) {

            // Development Testing ----------------------------------------------------------------
            if (arr[i].DepartmentNbr == 0) { // Temp Fix to display all sales items. They all use dep 0 which skips dep sort by my code logic.
                arr[i].DepartmentNbr = 1000
            }
            // if (arr[i].ItemKey=='0030-1285'){ // Find Double
            //     console.log("Array Index:" + i);
            //     console.log(arr[i]);
            // }
            // Development Testing ----------------------------------------------------------------


            // Check if Category has been added yet
            let hasCategory = false;
            for (let j = 0; j < itemCategories.length; j++) {
                if (itemCategories[j].CategoryNbr == arr[i].CategoryNbr) {
                    hasCategory = true;
                    break;
                }
            }
            // If Category hasn't been added, add it.
            if (!hasCategory) {
                if (arr[i].ItemImageURL == null) {
                    arr[i].ItemImageURL = this.defaultImage;
                }
                if (arr[i].DefaultCatItemKeyInd == true) {
                    itemCategories.push({
                        ImageURL: arr[i].ItemImageURL,
                        HierarchyTypNm: arr[i].HierarchyTypNm,
                        CategoryNbr: arr[i].CategoryNbr,
                        CategoryNm: arr[i].CategoryNm,
                        CategoryNmSortPrefix: arr[i].CategoryNmSortPrefix,
                        Order:arr[i].Order
                    });
                }
            }


            // Check if Department has been added yet
            // Check if Duplicate Departments under different Category
            let hasDepartment = false;
            for (let j = 0; j < itemDepartments.length; j++) {
                if (itemDepartments[j].DepartmentNbr == arr[i].DepartmentNbr && itemDepartments[j].CategoryNbr == arr[i].CategoryNbr) {
                    hasDepartment = true;
                    break;
                }
            }

            // If Department hasn't been added, add it.
            if (!hasDepartment) {
                if (arr[i].DefaultDeptItemKeyInd == true) {
                    if (arr[i].ItemImageURL == null) {
                        arr[i].ItemImageURL = this.defaultImage;
                    }
                    // Duplicate Department Fixes - Adding a space to the end of the name to distinguish the two

                    // if (arr[i].CategoryNm=="Trucks & Trailers"&&(arr[i].DepartmentNm=="Vans")) {
                    //     arr[i].DepartmentNm=arr[i].DepartmentNm.concat(" ");
                    // }
                    // if (arr[i].HierarchyTypNm=="RENTAL"&&(arr[i].DepartmentNm=="Lawn & Garden"||arr[i].DepartmentNm=="Safety & PPE")) {
                    //     arr[i].DepartmentNm=arr[i].DepartmentNm.concat(" ");
                    // }
                    // if (arr[i].HierarchyTypNm=="USED"&&(arr[i].DepartmentNm=="Pumps & Accessories"||arr[i].DepartmentNm=="Pressure Washers")) {
                    //     arr[i].DepartmentNm=arr[i].DepartmentNm.concat(" ");
                    // }

                    if (arr[i].HierarchyTypNm == "RENTAL") {
                        itemDepartments.push({
                            // Hold previous vales to backtrack
                            ImageURL: arr[i].ItemImageURL,
                            HierarchyTypNm: arr[i].HierarchyTypNm,
                            CategoryNbr: arr[i].CategoryNbr,
                            CategoryNm: arr[i].CategoryNm,
                            DepartmentNbr: arr[i].DepartmentNbr,
                            DepartmentNm: arr[i].DepartmentNm,
                            Order:arr[i].Order
                        });
                    } else {
                        itemDepartments.push({
                            // Hold previous vales to backtrack
                            ImageURL: arr[i].ItemImageURL,
                            HierarchyTypNm: arr[i].HierarchyTypNm,
                            CategoryNbr: arr[i].CategoryNbr,
                            CategoryNm: arr[i].CategoryNm,
                            DepartmentNbr: arr[i].DepartmentNbr,
                            DepartmentNm: arr[i].DepartmentNm,
                            Order:arr[i].Order
                        });
                    }
                }
            }


            // Check if Group has been added yet
            let hasGroup = false;

            for (let j = 0; j < itemGroups.length; j++) {
                if (itemGroups[j].GroupNm === arr[i].ItemGroupNm && itemGroups[j].DepartmentNbr == arr[i].DepartmentNbr && itemGroups[j].CategoryNbr == arr[i].CategoryNbr) {
                    hasGroup = true;
                    break;
                }
            }
            // If Group hasn't been added, add it.
            if (!hasGroup) {
                if (arr[i].DefaultGrpItemKeyInd === true) {
                    if (arr[i].ItemImageURL == null) {
                        arr[i].ItemImageURL = this.defaultImage;
                    }
                    itemGroups.push({
                        // Hold previous vales to backtrack
                        ImageURL: arr[i].ItemImageURL,
                        HierarchyTypNm: arr[i].HierarchyTypNm,
                        CategoryNbr: arr[i].CategoryNbr,
                        CategoryNm: arr[i].CategoryNm,
                        DepartmentNbr: arr[i].DepartmentNbr,
                        DepartmentNm: arr[i].DepartmentNm,
                        GroupNm: arr[i].ItemGroupNm,
                        GroupNmSortPrefix: arr[i].ItemGroupNmSortPrefix,
                        ShowAddButtonAtGroupInd: arr[i].ShowAddButtonAtGroupInd,
                        Order:arr[i].Order
                    });
                }
            }
        }


        // Alphabetical Sort By Name (.CategoryNm/.DepartmentNm/.GroupNm)
        // this.categories = itemCategories.sort(
        //     function (a, b) { return (a.CategoryNm > b.CategoryNm) ? 1 : ((b.CategoryNm > a.CategoryNm) ? -1 : 0); }
        // );
        // this.categories = itemCategories.filter(e=> e.HierarchyTypNm != 'USED')
        this.categories =  itemCategories.sort(
            function (a, b) { return (Number(a.Order) > Number(b.Order)) ? 1 : ((Number(b.Order) > Number(a.Order)) ? -1 : 0); }
        );
        // this.categories = this.sortCatPrefix(this.categories);
        // console.log(this.categories)
        // this.departments = itemDepartments.sort(
            //     function (a, b) { return (a.DepartmentNm > b.DepartmentNm) ? 1 : ((b.DepartmentNm > a.DepartmentNm) ? -1 : 0); }
            // );
            // this.departments = itemCategories.filter(e=> e.HierarchyTypNm != 'USED')
        this.departments =  itemDepartments.sort(
            function (a, b) { return (Number(a.Order) > Number(b.Order)) ? 1 : ((Number(b.Order) > Number(a.Order)) ? -1 : 0); }
        );
       
        // this.groups = itemGroups.sort(
        //     function (a, b) { return (a.GroupNm > b.GroupNm) ? 1 : ((b.GroupNm > a.GroupNm) ? -1 : 0); }
        // );

        // this.groups = this.groups.sort(
        //     function (a, b) { return (a.GroupNmSortPrefix > b.GroupNmSortPrefix) ? 1 : ((b.GroupNmSortPrefix > a.GroupNmSortPrefix) ? -1 : 0); }
        // );
        // this.groups = itemGroups.filter(e=> e.HierarchyTypNm != 'USED')

        this.groups = itemGroups.sort(
            function (a, b) { return (Number(a.Order) > Number(b.Order)) ? 1 : ((Number(b.Order) > Number(a.Order)) ? -1 : 0); }
        );

        this.searchedItems = this.searchedItems.sort(function (a, b) {
            return (a.ItemGroupNmSortPrefix > b.ItemGroupNmSortPrefix) ? 1 : ((b.ItemGroupNmSortPrefix > a.ItemGroupNmSortPrefix) ? -1 : 0);
        });
        // this.searchedItems = this.searchedItems.sort(function (a, b) {
        //     return (a.Order > b.Order) ? 1 : ((b.Order > a.Order) ? -1 : 0);        });
        // this.groups = this.sortGrpPrefix(this.groups);

        // this.departments = this.sortPrefix(this.departments);

        console.log('%cGot Updated Items', 'color: #00e600; text-decoration: underline;');
        console.log('%cCategories: ' + '%c' + this.categories.length, 'font-weight: bold;', 'font-weight: normal;');
        console.log('%cDepartments: ' + '%c' + this.departments.length, 'font-weight: bold;', 'font-weight: normal;');
        console.log('%cGroups: ' + '%c' + this.groups.length, 'font-weight: bold;', 'font-weight: normal;');
        console.log('%cItems: ' + '%c' + this.newItems.length, 'font-weight: bold;', 'font-weight: normal;');
    }

    static sortSpecials(items) {
        let tempItems: SpecialItem[] = [];
        tempItems = items.sort(
            function (a, b) { return (a.FeatureItemSortNbr > b.FeatureItemSortNbr) ? 1 : ((b.FeatureItemSortNbr > a.FeatureItemSortNbr) ? -1 : 0); }
        );
        // console.log(tempItems);
        (tempItems);
        return tempItems;
    }

    static sortCatPrefix(Items) {
        var result = [];
        var loop = Items.length; // Length changes as we remove a category from stack
        for (var i = 0; i < loop; i++) {
            var j = 0;
            var lowestItem = null;
            var lowestIndex = null;
            for (let Item of Items) {
                if (lowestItem === null) {
                    lowestItem = Item;
                    lowestIndex = j;
                } else if (+Item.CategoryNmSortPrefix < +lowestItem.CategoryNmSortPrefix) {
                    lowestItem = Item;
                    lowestIndex = j;
                }
                j++;
            }
            result.push(lowestItem);
            Items.splice(lowestIndex, 1) // Find REal Way
        }
        return result;
    }

    // static sortGrpPrefix(Items){
    //     var result = [];
    //     var loop = Items.length; // Length changes as we remove a group from stack
    //     for (var i=0; i<loop; i++) {
    //         var j = 0;
    //         var lowestGroup = null;
    //         var lowestIndex = null;
    //         for (let Item of Items){
    //             if (lowestGroup === null) {
    //                 lowestGroup = Item;
    //                 lowestIndex = j;
    //             } else if (+Item.GroupNmSortPrefix < +lowestGroup.GroupNmSortPrefix) {
    //                 lowestGroup = Item;
    //                 lowestIndex = j;
    //             }
    //             j++;
    //         }
    //         result.push(lowestGroup);
    //         Items.splice(lowestIndex, 1) // Find REal Way
    //     }
    //     return result;
    // }
    // ----------------------------------------------------------------------------------------------------------------------------------------------
    // Filter Items Process -------------------------------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------------------------------------------------

    // Grab all items within given Category.
    static setCategory(category: any) {
        this.category = category;

        this.categorizedItems = new Array();

        // If category NAME is not empty/null/undefined. Find category items with NAME
        if ((category.CategoryNm != null) || (category.CategoryNm != undefined) || (category.CategoryNm != '')) {
            this.searchTerms = new Array(); // Clear Search
            for (let i = 0; i < this.newItems.length; i++) {
                if (this.newItems[i].CategoryNm == category.CategoryNm && this.newItems[i].HierarchyTypNm == category.HierarchyTypNm) {
                    if (this.newItems[i].ItemImageURL == null) {
                        this.newItems[i].ItemImageURL = this.defaultImage;
                    }
                    this.categorizedItems.push(this.newItems[i]);
                }
            }
            // console.log('CategoryName: ' + category.CategoryNm + ' ' + category.CategoryNbr + ', Number Of Items: ' +  this.categorizedItems.length);
        } else {
            // If category NUMBER is not 0. Find category items with NUMBER
            if (category.CategoryNbr === 0) {
                this.categorizedItems = this.newItems;
            } else {
                this.searchTerms = new Array(); // Clear Search
                for (let i = 0; i < this.newItems.length; i++) {
                    if (this.newItems[i].CategoryNbr == category.CategoryNbr && this.newItems[i].HierarchyTypNm == category.HierarchyTypNm) {
                        if (this.newItems[i].ItemImageURL == null) {
                            this.newItems[i].ItemImageURL = this.defaultImage;
                        }
                        this.categorizedItems.push(this.newItems[i]);
                    }
                }
                // console.log('CategoryName: ' + category.CategoryNm + ' ' + category.CategoryNbr + ', Number Of Items: ' +  this.categorizedItems.length);
            }
        }
        this.setDepartment(0, null); // Cycle Down The List
    }

    // Grab all items within given Department.
    static setDepartment(id: number, cat: any) {
        this.departmentedItems = new Array();
        // If department NUMBER is not 0. Find department items with NUMBER
        if (id == 0) {
            this.departmentedItems = this.categorizedItems;
        } else {

            this.newItems.forEach(element => { // Also check category NUMBER incase there's a dep in 2 categories
                if (element.DepartmentNbr == id && element.CategoryNbr == cat) {
                    if (element.ItemImageURL == null) {
                        element.ItemImageURL = this.defaultImage;
                    }
                    this.departmentedItems.push(element);
                }
            });
            // this.departmentedItems.sort(
            //     function (a, b) {
            //         return (a.ItemGroupNmSortPrefix < b.ItemGroupNmSortPrefix) ? 1 : ((b.ItemGroupNmSortPrefix < a.ItemGroupNmSortPrefix) ? -1 : 0);
            //     }
            // );
            // console.log('DepartmentID: ' + id + ', Number Of Items: ' +  this.departmentedItems.length);
        }
        this.setGroup(0, null, null); // Cycle Down The List
    }

    // Grab all items within given Group.
    static setGroup(id: any, dep: any, cat: any) {
        this.groupItems = new Array();
        // If group NUMBER is not 0. Find group items with NUMBER
        if (id == 0) {
            this.groupItems = this.departmentedItems;
        } else {
            this.newItems.forEach(element => {
                if (element.ItemGroupNm.toString() == id.toString() && element.DepartmentNbr == dep && element.CategoryNbr == cat) {
                    if (element.ItemImageURL == null) {
                        element.ItemImageURL = this.defaultImage;
                    }
                    this.groupItems.push(element);
                }
            });
            this.groupItems = this.groupItems.sort(
                function (a, b) {
                    return (a.ItemGroupNmSortPrefix).localeCompare(b.ItemGroupNmSortPrefix);
                });
        }
        this.setSearch(this.searchTerms); // Cycle Down The List
    }

    static setSearch(search: string[]) {
        // Scroll Page Up
        // console.log("Scroll Reset");
        window.scrollTo(0, 0);
        //
        this.searchTerms = search;

        // Clear current items
        while (this.searchedItems.length > 0) {
            this.searchedItems.pop();
        }
        if (this.searchTerms.length == 0) { // If there is no search terms.
            for (let i = 0; i < this.groupItems.length; i++) {
                let test = true;
                for (let j = 0; j < this.searchTerms.length; j++) {
                    if (this.groupItems[i].ItemNm.toLowerCase().search(this.searchTerms[j].toLowerCase()) == -1) {
                        test = false;
                        break;
                    }
                }
                if (test) {
                    this.searchedItems.push(this.groupItems[i]);
                }
            }
        } else { // If item is in search, add/push it.
            for (let i = 0; i < this.newItems.length; i++) {
                let test = true;
                for (let j = 0; j < this.searchTerms.length; j++) {
                    if (this.newItems[i].ItemNm.toLowerCase().search(this.searchTerms[j].toLowerCase()) == -1
                        && this.newItems[i].CategoryNm.toLowerCase().search(this.searchTerms[j].toLowerCase()) == -1
                        && this.newItems[i].DepartmentNm.toLowerCase().search(this.searchTerms[j].toLowerCase()) == -1
                        && this.newItems[i].ItemGroupNm.toLowerCase().search(this.searchTerms[j].toLowerCase()) == -1
                        && this.newItems[i].HierarchyTypNm.toLowerCase().search(this.searchTerms[j].toLowerCase()) == -1) {
                        test = false;
                        break;
                    }
                }
                if (test) {
                    this.searchedItems.push(this.newItems[i]);
                }
            }
            this.searchedItems = this.searchedItems.sort(function (a, b) {
                return (a.ItemGroupNmSortPrefix).localeCompare(b.ItemGroupNmSortPrefix);
            });
        }
        this.setPage(0, this.pageSize); // Cycle Down The List
    }

    // Finally Set Current Items Displayed
    static setPage(page: number, pageSize: number) {
        this.pageSize = pageSize;
        this.page = page;
        if (this.newItems == undefined) {
            return null;
        }

        // Clear Current Items
        while (this.currentItems.length > 0) {
            this.currentItems.pop();
        }
        // Push Search onto Current Items
        for (let i = page * pageSize; i < page * pageSize + pageSize; i++) {
            if (this.searchedItems[i] == undefined) {
                break;
            }
            this.currentItems.push(this.searchedItems[i]);
        }
    }

    private static SetUpItems() {
        NewItems.clearItems();
        return NewItems.newItems;
    }

    static getGroupDefault(id: any, dep: any, cat: any) {
        this.defaultItems = new Array();
        // console.log('Group: ' + id);
        // console.log('DepID: ' + dep);
        // console.log('CatID: ' + cat);

        this.newItems.forEach(element => {
            if (element.ItemGroupNm.toString() == id.toString() && element.DepartmentNbr == dep && element.CategoryNbr == cat) {
                if (element.ItemImageURL == null) {
                    element.ItemImageURL = this.defaultImage;
                }
                this.defaultItems.push(element);
            }
        });
        // console.log(this.defaultItems);
        return this.defaultItems;
    }
}
