
import {throwError as observableThrowError,  Observable ,  Observer } from 'rxjs';

import {share} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Http, Headers, Response } from '@angular/http';
import { environment } from '../environments/environment';
import { APIService } from './api.service';
import { NewItems } from '../items/new-item';
import {EmptyObservable} from "rxjs/observable/EmptyObservable";

export class Item {
    constructor(
        // public DivisionNbr: number = -1,
        // public DivisionNm: string = '',
        // public HierarchyTypNm: string,
        public HierarchyTypNm: string = '',
        public CategoryNbr: number = -1,
        public CategoryNm: string = '',
        public CategoryNmSortPrefix: string = '',
        public DepartmentNbr: number = -1,
        public DepartmentNm: string = '',
        public ItemGroupNm: string = '',
        public ItemGroupNmSortPrefix: string = '',
        public ItemNm: string = '',
        public ItemNum: string = '',
        // public ItemDesc: string = '',
        public ItemNmSortPrefix: string = '',
        public ItemImageURL: string = '',
        public ItemKey: string = '',
        public ShowAddButtonAtGroupInd: boolean = false,
        public DefaultCatItemKeyInd: boolean = false,
        public DefaultDeptItemKeyInd: boolean = false,
        public DefaultGrpItemKeyInd: boolean = false,
        public FeatureItemDiscountPercent: number,
        public FeatureItemDiscountPercentDisplay: number,
        public SalePrice: number,
        public SaleFeatureValue: number,
        public DailyRate: number,
        public DailyFeatureValue: number,
        public WeeklyRate: number,
        public WeeklyFeatureValue: number,
        public MonthlyRate: number,
        public MonthlyFeatureValue: number,
    ) { }
}

@Injectable()
export class ItemService {
    private errorMessage: string;

    private baseUrl = environment.birchAPI;
    public isLoaded: boolean = false;
    loaded$: Observable<any>;
    private _observer: Observer<boolean>;
    private isLoading = false;

    constructor(private http: Http, private apiService: APIService) {
        this.loaded$ = new Observable(observer => this._observer = observer).pipe(share());
    }

    public RefreshItems() {
      return Observable.create(observer => {
        if (!this.isLoading && !this.isLoaded) {
          this.isLoading = true;

          let cmd = "retail/item/lastUpdated";
          let promise = this.http.get(this.baseUrl + cmd, {headers: this.apiService.httpHeaders()})
            .catch(this.handleError);

          promise.subscribe(
            date => {
              let serverDate = new Date((<any>date)._body);

              let cachedItems = localStorage.getItem('retailItemCache');

              let cacheDateStr = localStorage.getItem('retailItemCacheDate');
              let cacheDate = new Date(cacheDateStr);

              if (cachedItems === null || cacheDateStr === null || serverDate > cacheDate) {
                let cmd = 'retail/item';
                // let cmd = 'retail/item';
                // console.log(this.baseUrl + cmd);
                // let itemsPromise = this.http.get('https://qc.birchequipment.com/BirchAPI_QC/retail/item') //this.baseUrl + cmd)

                let itemsPromise = this.http.get(this.baseUrl + cmd, {headers: this.apiService.httpHeaders()})
                  .map(this.extractData)
                  .catch(this.handleError);

                itemsPromise.subscribe(
                  items => {
                    NewItems.setItems(items);
                    this.isLoaded = true;
                    if (this._observer) {
                      this._observer.next(this.isLoaded);
                    }
                    // save them to the cache
                    try {
                      localStorage.setItem('retailItemCacheDate', serverDate.toISOString());
                      localStorage.setItem('retailItemCache', JSON.stringify(items));
                    } catch (e) {

                    }
                    this.isLoading = false;

                    observer.next();
                    observer.complete();
                  },
                  error => {
                    this.errorMessage = error;
                  }
                );
              } else {
                NewItems.setItems(JSON.parse(cachedItems));

                this.isLoaded = true;
                if (this._observer) {
                  this._observer.next(this.isLoaded);
                }
                this.isLoading = false;

                observer.next();
                observer.complete();
                return;
              }
            },
            error => {
              this.errorMessage = error;
            }
          );

          this.isLoaded = false;
        }
      });
    }

    public RefreshSpecialItems() {

    }

    public GetItem(id: string, Type: string='') {
        // let cmd = "/birchequipment/availableitems&id=" + id;
        // return itemsPromise.then(this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders()));
        if (Type == 'Name') {
          // console.log(id);
          return Promise.resolve(NewItems.newItems.filter(i => i.ItemNm == id)[0]);
        }
        if (Type == 'Number') {
          // console.log(id);
          return Promise.resolve(NewItems.newItems.filter(i => +i.ItemNum == +id)[0]);
        }
        else {
          console.log(id);
          return Promise.resolve(NewItems.newItems.filter(i => i.ItemKey === id)[0]);
        }
    }

    public GetByGroup(group: string) {
        return Promise.resolve(NewItems.newItems.filter(i => i.ItemGroupNm === group)[0]);
    }

    public GetCategories() {
        let cmd = 'birchequipment/category'; // HTTP/1.1";
        return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
            .map(this.extractCategoryData)
            .catch(this.handleError);
    }

    public GetSuggestedItems(id: string) {
        let idx = id.indexOf('#');
        if (idx > 0 ) {
            id = id.substring(0, idx);
        }

        // id = id.replace('#', '%23');
        let cmd = 'retail/item/' + id + '/suggesteditems'; // HTTP/1.1";
        return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
            .map(this.extractData)
            .catch(this.handleError);
    }

    public GetOptionalItems(id: string) {
        let idx = id.indexOf('#');
        if (idx > 0 ) {
            id = id.substring(0, idx);
        }

        // id = id.replace('#', '%23');
        let cmd = 'retail/item/' + id + '/optionalitems'; // HTTP/1.1";
        return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
            .map(this.extractData)
            .catch(this.handleError);
    }

    private extractData(res: Response) {
        let body: Item[] = res.json();
        if (res.status === 200) {
            return body;
        }
        return null;
    }

    private extractCategoryData(res: Response) {
        let body = res.json();
        if (res.status === 200) {
            return body;
        }
        return null;
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        if (error instanceof Response) {
            let resp: Response = error;
            let body = resp.json();
            if (body.Message) {
                errMsg = body.Message;
            }
        }

        console.error(errMsg); // log to console instead
        return observableThrowError(errMsg);
    }
}
