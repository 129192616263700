import { Component, OnInit, TemplateRef, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  templateUrl: './page-leave-confirm-dialog.html',
  styleUrls: ['./page-leave-confirm-dialog.scss']
})
export class PageLeaveConfirmDialog implements OnInit {

  constructor(public dialogRef: MatDialogRef<PageLeaveConfirmDialog>) { }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

}

