// Copyright ©2016 Birch Equimpent All Rights Reserved
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';

import { environment } from './environments/environment';

import { MenubarModule, MenuModule, Menubar, MenuItem } from 'primeng/primeng';
import { MatRadioButton } from '@angular/material/radio';

// app components
import { RetailUserService } from './service/retailuser.service';
import { CartItem, CartItemService } from './service/cart.service';
import { MessagebarService } from './service/messagebar.service';
import { ItemService } from './service/item.service';
import { ReportService, Reports } from './service/report.service';

import { NewItems } from './items/new-item';
import { ItemsAllComponent } from './items/all/all.component';

// Dummy Data Import
import { Location, LocationService } from './service/location.service'; // Top of page location service
import { specialRentalItems, specialSaleItems, SpecialItem } from './service/examples.service'; // Items to populate carts & shops

// var ua = require('universal-analytics');



// Declare ga function as ambient
declare let ga: Function;

// import {GoogleAnalyticsEventsService} from "./service/google-analytics-events.service";

@Component({
    selector: 'product-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [CartItemService, ItemService, LocationService],
})

export class AppComponent implements OnInit, OnDestroy {
    birchBaseURL = environment.birchBaseURL;
    birchAPI = environment.birchAPI;

    // private userLatitude: number = 0;
    // private userLongitude: number = 0;
    // private nearByLocationsSet: boolean = false;
    Locations: Location[] = LocationService.locations;

    cartItems: CartItem[] = CartItemService.currentItems;
    private cartTotalCost: number = 0;
    displayCartTotalCost: string = '0';

    private menuToggled = false;

    private msgbarSubscription: any;
    private retailUserSubscription: any;
    loggedIn = false;
    public alerts: Array<Object> = new Array<Object>(0);

    private notes: string;

    constructor(
        private router: Router,
        private retailUserService: RetailUserService,
        private cartItemService: CartItemService,
        private msgBar: MessagebarService,
        private itemService: ItemService,
        private ref: ChangeDetectorRef,
        private locationService: LocationService,
        private reportService: ReportService, 
        // public googleAnalyticsEventsService: GoogleAnalyticsEventsService
    ) {
        
        let self = this;

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');
            }
        });

        this.msgbarSubscription = this.msgBar.alerts$.subscribe(
            data => {
                this.alerts = data;
            });

        try {
            this.retailUserSubscription = this.retailUserService.loggedIn$.subscribe(
                (data) => {
                  this.loggedIn = data;
                  if (this.loggedIn) {
                    this.cartItemService.SyncLocalToServer().subscribe(
                      () => {
                        this.loadCart();
                      });
                  } else {
                    this.loadCart();
                  }
                });
        }
        catch (error) {
            let msg = 'Error checking login';
            console.log(<any>error);
            if (typeof error === 'string' && error.length > 0) {
                msg = error;
            }
            this.msgBar.addAlert(msg, 'danger');
        }

        // Refresh login
        this.retailUserService.isLoggedIn();
    }

    // submitEvent() {
    //   this.googleAnalyticsEventsService.emitEvent("testCategory", "testAction", "testLabel", 10);
    // }

    curYear(): string {
        return (new Date()).getFullYear().toString();
    }

    ngOnInit(): void {
        this.locationService.loadLocations();
        this.itemService.RefreshItems().subscribe(() => {
          this.loadCart();
        });
        this.itemService.RefreshSpecialItems();
        this.updatePrice();
    }

    menuClear(MenuID){
        document.getElementById(MenuID).style.cssText="display: none;";
        setTimeout(function () { document.getElementById(MenuID).style.cssText="display: flex;" }, 1000);
    }

    accountMenuToggle(){
        document.getElementById('account-dropdown').classList.toggle('account-dropdown-closed');
    }

    accountMenuClose(){
        document.getElementById('account-dropdown').classList.add('account-dropdown-closed');
        // document.getElementById('account-dropdown').classList.remove('account-dropdown-closed');
    }

    logout(){
      this.retailUserService.logout();
    }

    ngOnDestroy(): void {
    }

    homeRoute() {
        this.router.navigate(['/items']);
    }

    bargainRoute() {
        this.router.navigate(['/specials']);
    }

    loginRoute() {
        this.router.navigate(['/login']);
    }

    cartRoute() {
        this.router.navigate(['/cart']);
    }

    maintenanceRoute() {
        this.router.navigate(['/maintenance-request']);
    }

    partsRoute() {
        this.router.navigate(['/parts-request']);
    }

    trainingRoute() {
        this.router.navigate(['/training-request']);
    }

    purchaseRoute() {
        this.router.navigate(['/purchase-quote']);
    }

    createAccountRoute() {
        this.router.navigate(['/create-account']);
    }

    orderHistoryRoute() {
        this.router.navigate(['/order-history']);
    }

    LinkItem(item: string = '0') {
        this.router.navigate(['/items', {cat: item}]);
    }

    // Cart Controls --------------------------------------------------------------------------------------------------------------------------------
    public toggleCart() {
        document.getElementById('Cart').classList.toggle('cart-container-closed');
        document.getElementById('Arrow-SVG').classList.toggle('arrow-svg-active');
        document.getElementById('Cart-Close').classList.toggle('cart-close-hide');
    }

    public hideCart() {
        document.getElementById('Cart').classList.add('cart-container-closed');
        document.getElementById('Arrow-SVG').classList.remove('arrow-svg-active');
        document.getElementById('Cart-Close').classList.add('cart-close-hide');
    }

    public showCart() {
        document.getElementById('Cart').classList.remove('cart-container-closed');
        document.getElementById('Arrow-SVG').classList.add('arrow-svg-active');
        document.getElementById('Cart-Close').classList.remove('cart-close-hide');
    }


    static toggleCart() {
        document.getElementById('Cart').classList.remove('cart-container-closed');
        document.getElementById('Arrow-SVG').classList.add('arrow-svg-active');
        document.getElementById('Cart-Close').classList.remove('cart-close-hide');
    }

    public removeCartItem(index) {
        let selectedItem = this.cartItems[index];
        console.log(this.cartItems);

        for (let item of this.cartItems.concat()) { // Suggested Search/Delete
            if (item.IsOptionalItem==false && item.ParentCartItemID != null && item.ParentCartItemID == selectedItem.CartItemID) {
                console.log("Parent Item Index:"+index);
                this.cartItemService.DelCartItem(item).subscribe(oCI => {
                    console.log("Successfully Removed Suggested Item: ");
                    console.log(oCI);
                });
            }

            if (item.IsOptionalItem==true && item.ParentCartItemID!=null && item.ParentCartItemID == selectedItem.CartItemID) {
                console.log("Parent Item Index:"+index);

                this.cartItemService.DelCartItem(item).subscribe(CI => {
                  console.log("Successfully Removed Item: ");
                  console.log(CI);
                });
            }
        }

        this.cartItemService.DelCartItem(selectedItem).subscribe(CI => {
            console.log("Successfully Removed Item: ");
            console.log(CI);
        });

        this.updatePrice(); // Now the view Updates Price
    }

    public updateItem(item) {
      this.cartItemService.PutUserCartItem(item).subscribe(() => {});
    }

    public updatePrice() {
        this.cartTotalCost = 0;
        this.cartItems.forEach(item => { this.cartTotalCost += (item.CartItemEstCost * item.Quantity); });
        this.cartTotalCost = +this.cartTotalCost.toFixed(2);
        this.displayCartTotalCost = this.cartTotalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    public saveItem(cartItem) {
        this.cartItemService.PutUserCartItem(cartItem).subscribe(CI => {
        console.log("Successfully Updated Item: ");
        console.log(CI);
        });
    }

    updateUrl(event) {
        event.path[0].src = "http://i.imgur.com/hNcsOlE.png";
    }

    logCart() {
        console.log("Cart Items:");
        console.log(this.cartItems);
    }

    loadCart(){
        this.cartItemService.GetUserCurrentCart().subscribe(
          () => {
            if (CartItemService.currentItems.length > 0) {
              this.showCart();
              this.logCart();
            }
          },
          error => {
            console.error(error);
          });
    }
    // Cart Controls --------------------------------------------------------------------------------------------------------------------------------

    // Menu Controls --------------------------------------------------------------------------------------------------------------------------------

    public hideMenu() {
        if (!this.menuToggled) {
            this.menuToggled=true;
            let self = this;
            document.getElementById('Menu').classList.add('menu-container-closed');
            document.getElementById('Menu-Mask').classList.add('mask-fade');
            setTimeout(function () { document.getElementById('Menu-Mask').classList.add('mask-closed'); self.menuToggled=false;}, 500);
        }
    }

    public clearSearch() {
        // console.log("Clearing Search Terms: "+NewItems.searchTerms);
		// while (ItemsAllComponent.searchTerms.length > 0) {
		// 	ItemsAllComponent.searchTerms.pop();
		// }
		// NewItems.setSearch(ItemsAllComponent.searchTerms);
    }

    public showMenu() {
            document.getElementById('Menu').classList.remove('menu-container-closed');
            document.getElementById('Menu-Mask').classList.remove('mask-closed');
            setTimeout(function () { document.getElementById('Menu-Mask').classList.remove('mask-fade'); }, 10);
    }

    // Menu Controls --------------------------------------------------------------------------------------------------------------------------------


  setLocation() {
    // let self = this;
    this.locationService.setNearByLocations().subscribe((locations) => {
        setTimeout(function () {
            document.getElementById('Locations').children[0].classList.add('card-location-highlight');
        }, 1000);
    });
  }

// String.prototype.insertAt=function(index, string) { 
//   return this.substr(0, index) + string + this.substr(index);
// }

  PhoneFormat(Number){ // ghetto fix because standard pipe breaks google crawler
      if(Number.length == 10){ // Normal with area code
        return Number.substr(0, 3) + "-" + Number.substr(3,3) + "-" + Number.substr(6);
      }
      if(Number.length == 11){ // If they add country code
        return Number.substr(0, 1) + "-" + Number.substr(1, 3) + "-" + Number.substr(3,3) + "-" + Number.substr(6);
      }
    return Number;
  }
}
