import { Component, OnInit, ViewChild, Pipe, PipeTransform } from '@angular/core';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { DetailConfirmDialog } from './detail-confirm-dialog/detail-confirm-dialog';
// import { DetailNoProjectDialog } from './detail-no-project-dialog/detail-no-project-dialog';

import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { Item, ItemService } from '../../service/item.service';
import { Project, ProjectItem, ProjectService } from '../../service/project.service';
import { MessagebarService } from '../../service/messagebar.service';
import { LargeImagePipe } from '../../pipe/large-image-pipe';
import { CalendarModule, Calendar, Dropdown, DropdownModule } from 'primeng/primeng';

import { NewItems } from '../new-item';

import { AppComponent } from '../../app.component';
import { CartItemService, CartItem } from '../../service/cart.service';

import { RetailUserService } from '../../service/retailuser.service';


@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  providers: [CartItemService],
})
export class ItemsDetailComponent implements OnInit {
 
  public project: Project;
  public editting: boolean = false;
  public defaultOptSet: boolean = false;
  public defaultSuggSet: boolean = false;

  public editingItem: boolean = false;

  public item: Item;
  public projectItem: ProjectItem;

  public optionalItems: Item[] = null;
  public optionalItemsEnabled: boolean[];
  public selectedOptionalItem: Item = null;

  public suggestedItems: Item[] = null;
  public suggestedItemsEnabled: boolean[];
  public selectedSuggestedItem: Item = null;

  public suggestedItemsQuantity: number[];
  public qChanging: boolean;

  projectForm: FormGroup;

  public quantityOptions: any[];
  public shiftOptions: string[];
  public shiftNumberOptions: number[];

  public category: any;
  public department: any;
  public group: any;

  private sub: any;
  private subOptional: any;

  public errorMessage: string;

  constructor(
    // private route: ActivatedRoute,
    private router: Router,
    private itemService: ItemService,
    private projectService: ProjectService,
    private retailUserService: RetailUserService,
    private cartItemService: CartItemService,
    private msgBar: MessagebarService,
    private route: ActivatedRoute
    // ,private dialog: MatDialog  Doesn't appear we need the popup
    ) {

    this.projectForm = new FormGroup({
      shift: new FormControl('', Validators.required),
      // certificationRequired: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      poNumber: new FormControl('', Validators.required),
      jobNumber: new FormControl('', Validators.required),
      orderedBy: new FormControl('', Validators.required),
      pickedUpBy: new FormControl('', Validators.required),
      deliveryRequired: new FormControl('', Validators.required),
      quantity: new FormControl('', Validators.required),

    });
    this.quantityOptions = [
      { label: 1, value: 1 },
      { label: 2, value: 2 },
      { label: 3, value: 3 },
      { label: 4, value: 4 },
      { label: 5, value: 5 },
      { label: 6, value: 6 },
      { label: 7, value: 7 },
      { label: 8, value: 8 },
      { label: 9, value: 9 },
      { label: 10, value: 10 }]

      this.shiftOptions = ['Standby', 'Single', 'Double', 'Triple'];
    this.projectItem = new ProjectItem(null, '', 1, 1, new Date(), new Date(), '', '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, '', '', '', '', '', -1, false, [], [], 0, '', 0, 0, 0);
  }

  ngOnInit() {
    if (this.itemService.isLoaded) {
      this.setData();
    }
    else {
      this.itemService.loaded$.subscribe(
        data => {
          this.setData();
        });
    }
    //    if (NewItems.newItems.length === 0) {
    //      this.router.navigate(['/items']);
    //    }

    this.sessionLoad(); // adds default item data (Pickup, Ordered, Shift, Delivery)
  }

  setData() {
    // this won't fire until the item data is loaded
    this.sub = this.route.params.subscribe(params => {
      // debugger;
      let piid = params['piid'];
      if (piid !== undefined) {
        this.editting = true;
        this.itemService.GetItem(this.projectItem.ProjectionItemEquipment).then(
          item => {
            if (item === undefined) {
              let msg = 'Item Not Found, Returning To Categories';
              this.msgBar.addAlert(msg, 'danger');
              this.router.navigate(['/items']);
            }
            this.item = item;
            this.projectItem.HierarchyTypNm = item.HierarchyTypNm;
            console.log('Project Item: ');
            console.log(item);
            this.getSuggestedItems();
            this.getOptionalItems();

            this.editingItem = true;

            // NewItems.viewedItem = this.item;
          });
      } else {
        let id = params['id'];
        if (id !== undefined) {
          this.itemService.GetItem(id).then(item => {
            if (item === undefined) {
              let msg = 'Item Not Found, Returning To Categories';
              this.msgBar.addAlert(msg, 'warning');
              this.router.navigate(['/items']);
            }
            this.item = item;
            console.log('Item: ');
            console.log(item);
            this.getSuggestedItems();
            this.getOptionalItems();

            NewItems.viewedItem = this.item;
          });
        }
      }
    }
    );
  }

  getOptionalItems() {

    this.itemService.GetOptionalItems(this.item.ItemKey).subscribe(
      items => {
        if (items.length > 0) {
          this.setOptionalItems(items);
          console.log('Optional Items: ');
          console.log(this.optionalItems);
        }
      },
      error => {
        console.log(<any>error);
      });
  }

  // showNoProjectModal(): void {
  //   const dialogRef = this.dialog.open(DetailNoProjectDialog, { width: '40%' });
  //   dialogRef.afterClosed().subscribe(result => {alert('TODO: handle button clicks'); console.log(`Dialog result: ${result}`); });
  // }
  // showConfimModal(): void {
  //   const dialogRef = this.dialog.open(DetailConfirmDialog, { width: '40%' });
  //   dialogRef.afterClosed().subscribe(result => {
  //     alert('TODO: handle button clicks'); console.log(`Dialog result: ${result}`); 
      
  //   });
  // }

  chosenOptionalItems() {
    this.projectItem.OptionalItems;
  }

  setOptionalItems(items: Item[]) {
    this.optionalItems = items;
    this.optionalItemsEnabled = Array(this.optionalItems.length);

    // Set Default Selected Option
    this.optionalItemsEnabled[items[0].ItemKey] = true;

  }

  setDefaultOption() { // Pulls item's currently selected Default Option and sets it
    if (!this.defaultOptSet && document.getElementById('Item-Options').children[0]) {
      if (this.projectItem.OptionalItems.length > 0) {
        for (let SetOptionalItem of this.projectItem.OptionalItems) {
          let temp = 0;
          for (let OptionalItem of this.optionalItems) {
            if (SetOptionalItem.ProjectionItemEquipment === OptionalItem.ItemKey) {
              this.optionalItemsEnabled[this.optionalItems[temp].ItemKey] = true;
              document.getElementById('Item-Options').children[temp].classList.add('Selected');
              document.getElementsByClassName('Item-Optional-Subtitle')[temp].classList.remove('Item-Optional-Subtitle-Hide');
              this.defaultOptSet = true;
            }
            temp++;
          }
        }
      } else {
        document.getElementById('Item-Options').children[0].classList.add('Selected');
        document.getElementsByClassName('Item-Optional-Subtitle')[0].classList.remove('Item-Optional-Subtitle-Hide');
        this.defaultOptSet = true;
      }
    }
  }


  setDefaultSuggested() { // Pulls item's currently selected Suggedsted Items and sets them
    if (!this.defaultSuggSet && this.projectItem.SuggestedItems.length > 0 && document.getElementById('Suggested-Items').children[0]) {
      for (let SetSuggestedItem of this.projectItem.SuggestedItems) {
        let temp = 0;
        for (let SuggestedItem of this.suggestedItems) {
          if (SetSuggestedItem.ProjectionItemEquipment === SuggestedItem.ItemKey) {
            this.suggestedItemsEnabled[SetSuggestedItem.ProjectionItemEquipment] = true;
            this.suggestedItemsQuantity[SetSuggestedItem.ProjectionItemEquipment] = SetSuggestedItem.ProjectionItemQuantity;
            document.getElementById('Suggested-Items').children[temp].classList.add('Selected');
            document.getElementsByClassName('Item-Add')[temp].classList.add('Item-Add-Checked');
            document.getElementsByClassName('Item-Quantity')[temp].classList.add('Item-Quantity-Checked');
          }
          temp++;
        }
      }
      this.defaultSuggSet = true;
    }
  }

  getSuggestedItems() {
    this.itemService.GetSuggestedItems(this.item.ItemKey).subscribe(
      items => {
        if (items.length > 0) {
          this.setSuggestedItems(items);
          console.log('Suggested Items: ');
          console.log(this.suggestedItems);
        }
      },
      error => {
        console.log(<any>error);
      });
  }


  setSuggestedItems(items: Item[]) {
    this.suggestedItems = items;
    this.suggestedItemsEnabled = Array(this.suggestedItems.length);
    this.suggestedItemsQuantity = Array(this.suggestedItems.length);
  }

  RequestQuote() {
    console.log('Request Quote ' + this.item.CategoryNbr + ', ' + this.item.DepartmentNbr + ', ' + this.group + ', ' + this.item.ItemKey);

    this.router.navigate(['/purchase-quote', {
      cat: JSON.stringify(this.item.CategoryNbr),
      dpt: JSON.stringify(this.item.DepartmentNbr),
      grp: JSON.stringify(this.item.ItemGroupNm),
      itm: JSON.stringify(this.item.ItemKey)
    }]);
  }

  addCartItem(Item) {
    AppComponent.toggleCart();

    let cartItem = new CartItem(
      null, // CartID
      null, // CartItemID
      Item.ItemImageURL, // Image URL src
      Item.ItemNm, // Name
      Item.ItemNum, // Name
      Item.ItemKey, // Key
      this.projectItem.ProjectionItemStartDate, // Start Date
      this.projectItem.ProjectionItemStartDate.getMonth().toString() + "/" + this.projectItem.ProjectionItemStartDate.getDate().toString() + "/" + this.projectItem.ProjectionItemStartDate.getFullYear().toString(), // Start Date
      this.projectItem.ProjectionItemEndDate, // End Date
      this.projectItem.ProjectionItemEndDate.getMonth().toString() + "/" + this.projectItem.ProjectionItemEndDate.getDate().toString() + "/" + this.projectItem.ProjectionItemEndDate.getFullYear().toString(), // End Date

      this.projectItem.ProjectionItemQuantity, // Quantity 1, //
      this.projectItem.PONo, // Purchase Order Number
      this.projectItem.JobNo, // Job Number
      this.projectItem.PickedUpBy, // Picked Up By
      this.projectItem.OrderedBy, // Ordered By
      false, // Is Optional Item
      [], // Attached Suggested Items
      [], // Attached Optional Items
      Item.SalePrice, // Sale Price
      Item.HierarchyTypNm, // Hierarchy Type Name
      Item.DailyRate, // Daily Rate,
      Item.WeeklyRate, // Weekly Rate
      Item.MonthlyRate, // Monthly Rate

      null, // Parent Item ID

      '1', //Equipment ID?
      this.projectItem.ProjectionItemShifts,
      this.projectItem.ProjectionItemOperatorCertification,
      this.projectItem.ProjectionItemDelivery,
      this.projectItem.ProjectionItemContractRateDay,
      this.projectItem.ProjectionItemContractRateWeek,
      this.projectItem.ProjectionItemContractRateMonth,
      this.projectItem.ProjectionItemListRateDay,
      this.projectItem.ProjectionItemListRateWeek,
      this.projectItem.ProjectionItemListRateMonth,
      this.projectItem.ProjectionItemCustomRateDay,
      this.projectItem.ProjectionItemCustomRateWeek,
      this.projectItem.ProjectionItemCustomRateMonth,
      this.projectItem.ProjectionItemMarketRateDay,
      this.projectItem.ProjectionItemMarketRateWeek,
      this.projectItem.ProjectionItemMarketRateMonth,
      0, // Estimated Cost #
      '0',
      Item.ProjectionItemEquipmentDescription,
      Item.CategoryNm,
      Item.ItemDesc,
    );

    if (cartItem.HierarchyTypNm != 'RENTAL') {
      if (Item.FeatureItemDiscountPercent != null) {
        cartItem.CartItemEstCost = Item.SaleFeatureValue;
        cartItem.DisplayCartItemEstCost = cartItem.CartItemEstCost.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
      } else {
        cartItem.CartItemEstCost = cartItem.SalePrice;
        cartItem.DisplayCartItemEstCost = cartItem.CartItemEstCost.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
      }
    } else {
      if (Item.FeatureItemDiscountPercent != null) {
        cartItem.CartItemEstCost = this.cartItemService.GetEstimateCost(this.item, new Date(cartItem.StartDate), new Date(cartItem.EndDate), cartItem.DailyRate, cartItem.WeeklyRate, cartItem.MonthlyRate, cartItem.SalePrice, cartItem.HierarchyTypNm);
        cartItem.CartItemEstCost = (cartItem.CartItemEstCost*((100-Item.FeatureItemDiscountPercent)/100)); // Add discount percent
        cartItem.DisplayCartItemEstCost = cartItem.CartItemEstCost.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
      } else{
        cartItem.CartItemEstCost = this.cartItemService.GetEstimateCost(this.item, new Date(cartItem.StartDate), new Date(cartItem.EndDate), cartItem.DailyRate, cartItem.WeeklyRate, cartItem.MonthlyRate, cartItem.SalePrice, cartItem.HierarchyTypNm);
        cartItem.DisplayCartItemEstCost = cartItem.CartItemEstCost.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
      }
    }

    this.cartItemService.PostUserCartItem(cartItem).subscribe(
      dataItem => {
        console.log("Successfully Added Item:");
        console.log(dataItem);
        if (this.optionalItems != null) { // If optional items exist
          for (let key in this.optionalItemsEnabled) { // Look at each optional item to see if it was enabled
            if (this.optionalItemsEnabled[key]) { // If current optional item is enabled add it to cart with Parent
              for (let item of this.optionalItems) {
                if (item.ItemKey === key) {
                  this.selectedOptionalItem = item;
                }
              } // Select optional item using key
                // Prepare Optional Item For Cart
              let optionalCartItem = new CartItem(
                null, // CartID
                null, // CartItemID
                this.selectedOptionalItem.ItemImageURL, // Image URL src
                this.selectedOptionalItem.ItemNm, // Name
                this.selectedOptionalItem.ItemNum, // Name
                this.selectedOptionalItem.ItemKey, // Key
                this.projectItem.ProjectionItemStartDate, // Start Date
                this.projectItem.ProjectionItemStartDate.getMonth().toString() + "/" + this.projectItem.ProjectionItemStartDate.getDate().toString() + "/" + this.projectItem.ProjectionItemStartDate.getFullYear().toString(), // Start Date
                this.projectItem.ProjectionItemEndDate, // End Date
                this.projectItem.ProjectionItemEndDate.getMonth().toString() + "/" + this.projectItem.ProjectionItemEndDate.getDate().toString() + "/" + this.projectItem.ProjectionItemEndDate.getFullYear().toString(), // End Date

                1, // this.projectItem.ProjectionItemQuantity, // Quantity
                this.projectItem.PONo, // Purchase Order Number
                this.projectItem.JobNo, // Job Number
                this.projectItem.PickedUpBy, // Picked Up By
                this.projectItem.OrderedBy, // Ordered By
                true, // Is Optional Item
                [], // Attached Suggested Items
                [], // Attached Optional Items
                0, // Sale Price
                this.selectedOptionalItem.HierarchyTypNm, // Hierarchy Type Name
                0, // Daily Rate,
                0, // Weekly Rate
                0, // Monthly Rate

                dataItem.CartItemID, // Parent Item ID

                '1',
                this.projectItem.ProjectionItemShifts,
                this.projectItem.ProjectionItemOperatorCertification,
                this.projectItem.ProjectionItemDelivery,
                this.projectItem.ProjectionItemContractRateDay,
                this.projectItem.ProjectionItemContractRateWeek,
                this.projectItem.ProjectionItemContractRateMonth,
                this.projectItem.ProjectionItemListRateDay,
                this.projectItem.ProjectionItemListRateWeek,
                this.projectItem.ProjectionItemListRateMonth,
                this.projectItem.ProjectionItemCustomRateDay,
                this.projectItem.ProjectionItemCustomRateWeek,
                this.projectItem.ProjectionItemCustomRateMonth,
                this.projectItem.ProjectionItemMarketRateDay,
                this.projectItem.ProjectionItemMarketRateWeek,
                this.projectItem.ProjectionItemMarketRateMonth,
                0, // Estimated Cost #
                '0',
                this.projectItem.ProjectionItemEquipmentDescription
              );
              console.log('Added Cart Optional Item:');
              console.log(optionalCartItem);
              this.cartItemService.PostUserCartItem(optionalCartItem).subscribe(
                data => {},
                error => {
                  console.error(error);
                });
            }
          }
        }

        this.scrollToTop();


        // Suggested Items
        if (this.suggestedItems != null) { // If optional items exist
          for (let key in this.suggestedItemsEnabled) { // Look at each optional item to see if it was enabled
            if (this.suggestedItemsEnabled[key]) { // If current optional item is enabled add it to cart with Parent
              // Prepare Optional Item For Cart
              for (let item of this.suggestedItems) {
                if (item.ItemKey === key) {

                  this.itemService.GetItem(key).then(suggestedItem => {
                    if (suggestedItem === undefined) {
                      let msg = 'Suggested Item Not Found, "Undefined"';
                      this.msgBar.addAlert(msg, 'warning');
                    }

                    this.selectedSuggestedItem = suggestedItem;

                    let suggestedCartItem = new CartItem(
                      null, // CartID
                      null, // CartItemID
                      this.selectedSuggestedItem.ItemImageURL, // Image URL src
                      this.selectedSuggestedItem.ItemNm, // Name
                      this.selectedSuggestedItem.ItemNum, // Name
                      this.selectedSuggestedItem.ItemKey, // Key
                      this.projectItem.ProjectionItemStartDate, // Start Date
                      this.projectItem.ProjectionItemStartDate.getMonth().toString() + "/" + this.projectItem.ProjectionItemStartDate.getDate().toString() + "/" + this.projectItem.ProjectionItemStartDate.getFullYear().toString(), // Start Date
                      this.projectItem.ProjectionItemEndDate, // End Date
                      this.projectItem.ProjectionItemEndDate.getMonth().toString() + "/" + this.projectItem.ProjectionItemEndDate.getDate().toString() + "/" + this.projectItem.ProjectionItemEndDate.getFullYear().toString(), // End Date

                      this.suggestedItemsQuantity[key], // Quantity
                      this.projectItem.PONo, // Purchase Order Number
                      this.projectItem.JobNo, // Job Number
                      this.projectItem.PickedUpBy, // Picked Up By
                      this.projectItem.OrderedBy, // Ordered By
                      false, // Is Optional Item
                      [], // Attached Suggested Items
                      [], // Attached Optional Items
                      suggestedItem.SalePrice, // Sale Price
                      this.selectedSuggestedItem.HierarchyTypNm, // Hierarchy Type Name
                      suggestedItem.DailyRate, // Daily Rate,
                      suggestedItem.WeeklyRate, // Weekly Rate
                      suggestedItem.MonthlyRate, // Monthly Rate

                      dataItem.CartItemID, // Parent Item ID

                      '', // Cart Item Equipment
                      this.projectItem.ProjectionItemShifts,
                      this.projectItem.ProjectionItemOperatorCertification,
                      this.projectItem.ProjectionItemDelivery,
                      this.projectItem.ProjectionItemContractRateDay,
                      this.projectItem.ProjectionItemContractRateWeek,
                      this.projectItem.ProjectionItemContractRateMonth,
                      this.projectItem.ProjectionItemListRateDay,
                      this.projectItem.ProjectionItemListRateWeek,
                      this.projectItem.ProjectionItemListRateMonth,
                      this.projectItem.ProjectionItemCustomRateDay,
                      this.projectItem.ProjectionItemCustomRateWeek,
                      this.projectItem.ProjectionItemCustomRateMonth,
                      this.projectItem.ProjectionItemMarketRateDay,
                      this.projectItem.ProjectionItemMarketRateWeek,
                      this.projectItem.ProjectionItemMarketRateMonth,

                      this.cartItemService.GetEstimateCost(this.item, 
                        this.projectItem.ProjectionItemStartDate,
                        this.projectItem.ProjectionItemEndDate,
                        suggestedItem.DailyRate,
                        suggestedItem.WeeklyRate,
                        suggestedItem.MonthlyRate,
                        suggestedItem.SalePrice,
                        suggestedItem.HierarchyTypNm), // Estimated Cost
                      '0',

                      this.projectItem.ProjectionItemEquipmentDescription
                    );

                    if (suggestedCartItem.HierarchyTypNm != 'RENTAL') {
                      if(suggestedCartItem.CartItemEstCost == -1){
                        suggestedCartItem.DisplayCartItemEstCost = "TBD";
                      }else{
                        suggestedCartItem.CartItemEstCost = suggestedCartItem.SalePrice;
                        suggestedCartItem.DisplayCartItemEstCost = suggestedCartItem.CartItemEstCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                      }
                    } else {
                      if(suggestedCartItem.CartItemEstCost == -1){
                        suggestedCartItem.DisplayCartItemEstCost = "TBD";
                      }else{
                        suggestedCartItem.CartItemEstCost = this.cartItemService.GetEstimateCost(this.item, new Date(suggestedCartItem.StartDate), new Date(suggestedCartItem.EndDate), suggestedCartItem.DailyRate, suggestedCartItem.WeeklyRate, suggestedCartItem.MonthlyRate, suggestedCartItem.SalePrice, suggestedItem.HierarchyTypNm);
                        suggestedCartItem.DisplayCartItemEstCost = suggestedCartItem.CartItemEstCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                      }
                    }

                    // if (suggestedCartItem.HierarchyTypNm != 'RENTAL') {
                    //   suggestedCartItem.CartItemEstCost = suggestedCartItem.SalePrice;
                    //   suggestedCartItem.DisplayCartItemEstCost = suggestedCartItem.CartItemEstCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                    // } else {
                    //   suggestedCartItem.CartItemEstCost = this.GetEstimateCost(new Date(suggestedCartItem.StartDate), new Date(suggestedCartItem.EndDate), suggestedCartItem.DailyRate, suggestedCartItem.WeeklyRate, suggestedCartItem.MonthlyRate, suggestedCartItem.SalePrice, suggestedItem.HierarchyTypNm);
                    //   suggestedCartItem.DisplayCartItemEstCost = suggestedCartItem.CartItemEstCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                    // }

                    console.log('Added Cart Suggested Item:');
                    console.log(suggestedCartItem);
                    this.cartItemService.PostUserCartItem(suggestedCartItem).subscribe(
                      data => {},
                      error => {
                        console.error(error);
                      });

                  });
                }
              } // Item service => {} makes 2ed bracket
            }
          }
        }
        this.setData();
      },
      error => {
        console.error(error);
      });
  }


  // Submit -------------------------------------------------------------------------------------------------------------------------------------------

  onOptionalEnabledChange(index, i, myKey) {
    // turn  on the checked and off the others
    for (let j = 0; j < this.optionalItemsEnabled.length; j++) {
      let key = this.optionalItems[j].ItemKey;
      this.optionalItemsEnabled[key] = (key === myKey);

      document.getElementById('Item-Options').children[j].classList.remove('Selected');
      document.getElementsByClassName('Item-Optional-Subtitle')[j].classList.add('Item-Optional-Subtitle-Hide');
    }
    // console.log(this.optionalItemsEnabled);

    document.getElementById('Item-Options').children[i].classList.add('Selected');
    document.getElementsByClassName('Item-Optional-Subtitle')[i].classList.remove('Item-Optional-Subtitle-Hide');
    // document.getElementById("Item-Options").children[i].classList.add("Selected");
    // document.getElementsByClassName("Item-Quantity")[i].classList.add("Item-Quantity-Checked");
  }

  onSuggestedEnabledChange(index, i, newValue) {
    if (this.qChanging != true) {
      if (this.suggestedItemsEnabled[index] === true && this.suggestedItemsQuantity[index] == null) {
        this.suggestedItemsQuantity[index] = 1;
        document.getElementById('Suggested-Items').children[i].classList.add('Selected');
        document.getElementsByClassName('Item-Add')[i].classList.add('Item-Add-Checked');
        document.getElementsByClassName('Item-Quantity')[i].classList.add('Item-Quantity-Checked');
        // document.getElementById("Suggested-Item").children[i].getElementsByClassName("Item-Add").item[0].classList.add("Item-Add-Checked");
      }
      if (this.suggestedItemsEnabled[index] === false) {
        this.suggestedItemsQuantity[index] = null;
        document.getElementById('Suggested-Items').children[i].classList.remove('Selected');
        document.getElementsByClassName('Item-Add')[i].classList.remove('Item-Add-Checked');
        document.getElementsByClassName('Item-Quantity')[i].classList.remove('Item-Quantity-Checked');
        // document.getElementById("Suggested-Item").children[i].getElementsByClassName("Item-Add").item[0].classList.remove("Item-Add-Checked");
      }
    }
  }

  onSuggestedQuantityChange(index, i, newValue) {

    newValue = newValue.replace(/[^0-9]/g, 1); // Because this function needs to wait for styling to load it sometimes lets letters through to input field. But variable stays as number.
    if (newValue === 0) { newValue = 1; }

    if (newValue != 0 && newValue != null) {
      this.qChanging = true;
      this.suggestedItemsEnabled[index] = true;
      this.suggestedItemsQuantity[index] = newValue;
      console.log(this.suggestedItemsQuantity[index]);
      document.getElementById('Suggested-Items').children[i].classList.add('Selected');
      document.getElementsByClassName('Item-Add')[i].classList.add('Item-Add-Checked');
      document.getElementsByClassName('Item-Quantity')[i].classList.add('Item-Quantity-Checked');
      this.qChanging = false;
    }
  }

  sessionLoad() {
    if (sessionStorage.getItem('orderedBy') != null) {
      this.projectItem.OrderedBy = sessionStorage.getItem('orderedBy');
    }
    if (sessionStorage.getItem('pickedUpBy') != null) {
      this.projectItem.PickedUpBy = sessionStorage.getItem('pickedUpBy');
    }
    if (sessionStorage.getItem('shift') != null) {
      this.projectItem.ProjectionItemShifts = parseInt(sessionStorage.getItem('shift'), 10);
    }
    if (sessionStorage.getItem('deliverRequired') != null) {
      if (sessionStorage.getItem('deliverRequired') === 'true') {
        this.projectItem.ProjectionItemDelivery = 'true';
      }
      if (sessionStorage.getItem('deliverRequired') === 'false') {
        this.projectItem.ProjectionItemDelivery = 'false';
      }
    }
  }

  backButton(depth: number) {
    sessionStorage.setItem('item', JSON.stringify(this.item));
    sessionStorage.setItem('itemDepth', JSON.stringify(depth));

    this.router.navigate(['/items']);
  }

  private scrollToTop() {
    document.getElementById('SMS_TOOLBAR').scrollIntoView();
  }

  itemImage(imgUrl: string): string {
    let img = 'http://i.imgur.com/hNcsOlE.png';
    if (imgUrl !== null) {
      img = imgUrl;
    }
    return img;
  }

  formatDollar(num) {
    var p = num.toFixed(2).split(".");
    return "$" + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
        return  num=="-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
      }, "") + "." + p[1];
  }
}
