import { Component, OnInit, TemplateRef, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'detail-no-project-dialog',
    templateUrl: 'detail-no-project-dialog.html',
    styleUrls: ['detail-no-project-dialog.scss'],
})

export class DetailNoProjectDialog {

    constructor(public dialogRef: MatDialogRef<DetailNoProjectDialog>) { }
    onNoClick(): void {
        this.dialogRef.close();
      }
}


