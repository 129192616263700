import { Injectable }             from '@angular/core';
import { CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot }    from '@angular/router';
import { RetailUserService } from './service/retailuser.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private retailCustomerService: RetailUserService, private router: Router) { }

  canActivate(
    // Not using but worth knowing about
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    
    if (this.retailCustomerService.isLoggedIn()) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}