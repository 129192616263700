
import {share} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
// import { Subject } from  'rxjs/Subject';

import {Observer, Observable} from 'rxjs';

export class ProductException {
  errNum: number;
  message: string;

  constructor(errNum: number, message: string) {
    this.errNum = errNum;
    this.message = message;
  }
}

@Injectable()
export class MessagebarService {

  private alerts = new Array<Object>();
  alerts$: Observable<any>;
  private _observer: Observer<Array<Object>>;

  constructor() {
    this.alerts$ = new Observable(observer => this._observer = observer).pipe(share());
  }

  public closeAlert(i: number): void {
    this.alerts.splice(i, 1);
    if (this._observer) {
      this._observer.next(this.alerts);
    }
  }

  public addAlert(message, type): void {
    // if its success of info auto dismiss after 4 secs
    this.alerts.length = 0;
    if (type === 'success' || type === 'info') {
      this.alerts.push({ msg: message, type: type, dismissible: true, timeout: 4000 });
    }
    else {
      this.alerts.push({ msg: message, type: type, dismissible: true, timeout: 10000 });
    }
    if (this._observer) {
      this._observer.next(this.alerts);
    }
  }
}
