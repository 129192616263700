import { Component, OnInit, Input, ViewChild } from '@angular/core';
// RC6 import { FORM_DIRECTIVES } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ItemsAllComponent } from './all';
import { ItemsDetailComponent } from './detail';
// import { BsModalComponent } from 'ng2-bs3-modal';

import { NewItems } from './new-item';
import { Project, ProjectItem, ProjectService } from '../service/project.service';
import { MessagebarService } from '../service/messagebar.service';
import { APIService } from '../service/api.service';

import { MatList } from '@angular/material/list';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss'],
})

export class ItemsComponent implements OnInit {
  public categories: Array<any>;

  public refreshed: boolean = NewItems.refreshed;

  public buttonBack: boolean = false;

  private timer;
  private isBusy = false;

  constructor(public router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private msgBar: MessagebarService
  ) {
    let temp = this;
    this.timer = setInterval(function () { temp.Loading(); }, 500);
  }

  ngOnInit() {
    // console.log("Router:");
    // console.log(this.router.url);
    if (this.router.url === '/'){this.router.navigate(['/items', {cat: 0}]);} // inner items/all will not load into the router when page refersh.
    this.Refresh();
  }

  Refresh() {
    this.categories = NewItems.categories;
  }

  Loading() {
    // console.log('Checking');
    if (NewItems.categories.length > 1) {
      clearInterval(this.timer);
      this.categories = NewItems.categories;
      this.refreshed = true;
    }
  }

  toggleCategories(){
    document.getElementById("Categories").classList.toggle("category-hide")
  }

  changeCategory(category: any) {
    // debugger;
    sessionStorage.setItem('navCategory', JSON.stringify(category));
    NewItems.category = category;
    NewItems.categoryChange.emit(category);

    // NewItems.setSearch([]);
    this.router.navigate(['/items', {cat: category.CategoryNm}]);

    // ItemsAllComponent.refresh();
  }

  checkBack() { // Shows / Hides button if there is no item to ge back from.
    if (NewItems.viewedItem != null) {
      this.buttonBack = true;
    } else {
      this.buttonBack = false;
    }
  }

  public backButton() { // Grabs viewed item and stores info to relocate yourself when back in item heiarchy.
    sessionStorage.setItem('item', JSON.stringify(NewItems.viewedItem));
    sessionStorage.setItem('itemDepth', JSON.stringify(4));
    NewItems.viewedItem = null; // Removes viewed item so logic does not think you're still on item page. Removes back button.

    this.router.navigate(['/items']);
  }
}
