import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import {MatButton} from '@angular/material/button';
import {MatCard} from '@angular/material/card';
import {MatInput} from '@angular/material/input';
import {MatSpinner} from '@angular/material/progress-spinner';

import { APIService } from '../../service/api.service';
import { MessagebarService } from '../../service/messagebar.service';
import { RetailUserService } from '../../service/retailuser.service';

@Component({
  selector: 'product-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [APIService]
})
export class ForgotPasswordComponent implements OnInit {

private isBusy: boolean = false;
  username: string;
  constructor(
    private apiService: APIService,
    private retailCustomerService: RetailUserService ,
    private router: Router,
    private msgBar: MessagebarService)  {
  }

  ngOnInit() {
  }

  sendPassword(userName) {
    this.isBusy = true;
    let url: string = "retailuser/sendpassword?emailaddress=" + this.username;

    this.apiService.Get(url)
      .subscribe(data => {
        this.msgBar.addAlert("Your password has been sent", "success");
        history.back();
      },
      err => {
        this.msgBar.addAlert("Unable to send your password", "danger");
      },
      () => {
        this.isBusy = false;
      });
  }

  cancel() {
    history.back();
  }
}
