import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'largeImagePipe'
})
export class LargeImagePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    //return value.replace('.jpg', '-lg.jpg');
    return value;
  }
}