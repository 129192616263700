
import {throwError as observableThrowError, Observer, Observable} from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import {APIService} from './api.service';
import {Http, Response} from '@angular/http';
import {environment} from '../environments/environment';
// import {forEach} from "@angular/router/src/utils/collection";




@Injectable()
export class LocationService {
;

  public static locations: Location[] = [];
  // public birchLocations = new Locations();
  // Locations: Location[] = new Array();
  // public static self: any;

  public userLatitude = 0;
  public userLongitude = 0;
  public nearByLocationsSet = false;
  public Locations: Location[] = LocationService.locations;
  // [
  //   {StoreID: 0,
  //     Branch: 'Anacortes',
  //     Distance: '?',
  //     Address: '8876 South March Pt. Rd.',
  //     Latitude: 48.462590,
  //     Longitude: -122.551900,
  //     LocationMap: 'https://www.google.com/maps/dir//8876 South March Pt. Rd.,+Anacortes,+WA+98221/',
  //     City: 'Anacortes',
  //     State: 'WA',
  //     Zip: '98221',
  //     Phone: '360-293-7788',
  //     PhoneHref: 'tel:360-293-7788',
  //     Fax: '360-293-8260',
  //     FaxHref: 'fax:360-293-8260',
  //     Email: 'anacortes@birchequipment.com',
  //     EmailHref: 'mailto:anacortes@birchequipment.com'},
  //
  //   {StoreID: 1,
  //     Branch: 'Bellingham',
  //     Distance: '?',
  //     Address: '1619 Kentucky St.',
  //     Latitude: 48.758107,
  //     Longitude: -122.450549,
  //     LocationMap: 'https://www.google.com/maps/dir//1619 Kentucky St.,+Bellingham,+WA+98229/',
  //     City: 'Bellingham',
  //     State: 'WA',
  //     Zip: '98229',
  //     Phone: '360-734-5717',
  //     PhoneHref: 'tel:360-734-5717',
  //     Fax: '360-738-1385',
  //     FaxHref: 'fax:360-738-1385',
  //     Email: 'bellingham@birchequipment.com',
  //     EmailHref: 'mailto:bellingham@birchequipment.com'},
  //
  //   {StoreID: 2,
  //     Branch: 'Everett',
  //     Distance: '?',
  //     Address: 'Under Construction', //"2402 Gibson Rd Everett, WA 98204",
  //     Latitude: 47.884232,
  //     Longitude: -122.266723,
  //     LocationMap: '',
  //     City: 'Everett',
  //     State: 'WA',
  //     Zip: '98204',
  //     Phone: '425-290-7788',
  //     PhoneHref: 'tel:425-290-7788',
  //     Fax: '425-347-7792',
  //     FaxHref: 'fax:425-347-7792',
  //     Email: 'everett@birchequipment.com',
  //     EmailHref: 'mailto:everett@birchequipment.com'},
  //
  //   {StoreID: 3,
  //     Branch: 'Mount Vernon',
  //     Distance: '?',
  //     Address: '2609 Old Hwy. 99 S.',
  //     Latitude: 48.400677,
  //     Longitude: -122.335495,
  //     LocationMap: 'https://www.google.com/maps/dir//2609 Old Hwy. 99 S.,+Mount Vernon,+WA+98273/',
  //     City: 'Mount Vernon',
  //     State: 'WA',
  //     Zip: '98273',
  //     Phone: '360-428-7788',
  //     PhoneHref: 'tel:360-428-7788',
  //     Fax: '360-428-5779',
  //     FaxHref: 'fax:360-428-5779',
  //     Email: 'mountvernon@birchequipment.com',
  //     EmailHref: 'mountvernon@birchequipment.com'},
  //
  //   {StoreID: 4,
  //     Branch: 'Sitka, Alaska',
  //     Distance: '?',
  //     Address: '210 Jarvis St',
  //     Latitude: 57.052015,
  //     Longitude: -135.355244,
  //     LocationMap: 'https://www.google.com/maps/dir//210 Jarvis St,+Sitka, AK,+WA+99835/',
  //     City: 'Sitka',
  //     State: 'AK',
  //     Zip: '99835',
  //     Phone: '907-747-5315',
  //     PhoneHref: 'tel:907-747-5315',
  //     Fax: '907-747-5320',
  //     FaxHref: 'fax:907-747-5320',
  //     Email: 'sitka@birchequipment.com',
  //     EmailHref: 'mailto:sitka@birchequipment.com'},
  // ];

  private baseUrl = environment.birchAPI;

  constructor(private http: Http,
              private apiService: APIService) {}
  // Geolocation Service --------------------------------------------------------------------------------------------------------------------------
  public setNearByLocations() {
    return Observable.create(observer => {
      if (this.nearByLocationsSet === true) {
          console.log('Already Sorted');
          // this.nearByLocationsSet = false;
          observer.next(this.Locations);
          observer.complete();
      } else {
        this.getMyLocation().subscribe(() => {observer.next(this.Locations); observer.complete(); });
      }
    });
  }

  public getMyLocation() {


    if (navigator.geolocation) {
        // This function is inokved asynchronously by the HTML5 geolocation API.
      return Observable.create(observer => {
        let promise = new Deferred();

        promise.resolve = (position) => {
          this.sortLocations(position); observer.next(this.Locations); observer.complete();
        };
        promise.reject = () => {
          document.getElementById('location').innerHTML = 'No Geolocation Support'; observer.next(this.Locations); observer.complete();
        };

        navigator.geolocation.getCurrentPosition(promise.resolve.bind(this), promise.reject);
      });
    } else {
      return Observable.create(observer => {
        document.getElementById('location').innerHTML = 'No Geolocation Support';
        observer.next(this.Locations);
        observer.complete();
      });
    }
  }

  public loadLocations() {
    let cmd = 'birchequipment/store';
    return this.http.get(this.baseUrl + cmd, { headers: this.apiService.httpHeaders() })
      .map(this.extractLocationData)
      .catch(this.handleError)
      .subscribe(() => {});
  }

  // public meow(){
  //   if(navigator.geolocation)
  // }

  public sortLocations(position) {

    console.log('sortLocations');
    // console.log(LocationService.self);
    this.userLatitude = position.coords.latitude;
    this.userLongitude = position.coords.longitude;
    for (let i = 0; i < this.Locations.length; i++) {
        this.Locations[i].Distance = Math.round(this.getDistanceFromLatLonInMi(this.userLatitude, this.userLongitude, this.Locations[i].Latitude, this.Locations[i].Longitude)).toString();
    }
    this.Locations.sort(function (a, b) { return (+a.Distance > +b.Distance) ? 1 : ((+b.Distance > +a.Distance) ? -1 : 0); });
    console.log('Sorted Locations:');
    console.log(this.Locations);
    this.nearByLocationsSet = true;
    // callBack();
  }

  public getDistanceFromLatLonInMi(lat1, lon1, lat2, lon2) {
    // console.log('lat1: ' + lat1);
    // console.log('lon1: ' + lon1);
    // console.log('lat2: ' + lat2);
    // console.log('lon2: ' + lon2);
    let R = 6371; // Radius of the earth in km
    let dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    let dLon = this.deg2rad(lon2 - lon1);
    let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let dkm = R * c; // Distance in km
    let dmi = dkm * 0.621371; // Distance in km
    // console.log('Distance: ' + dmi);
    return dmi;
  }

  public deg2rad(deg) {
      return deg * (Math.PI / 180)
  }
  // Geolocation Service --------------------------------------------------------------------------------------------------------------------------

  private extractLocationData(res: Response) {
    let locations = res.json() as any[];
    console.log(locations);

    LocationService.locations.length = 0;

    for (let location of locations) {
      LocationService.locations.push(location);
    }

    if (res.status === 200) {
      return LocationService.locations;
    }
    return null;
  }

  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    if (error instanceof Response) {
      let resp: Response = error;
      let body = resp.json();
      if (body.Message) {
        errMsg = body.Message;
      }
    }

    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}

class Deferred<T> {

  promise: Promise<T>;
  resolve: (value?: T | PromiseLike<T>) => void;
  reject:  (reason?: any) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject  = reject;
    });
  }
}

export class Location {
  constructor(
    public StoreID: number,
    public Branch: string,
    public Distance: string,
    public Address: string,
    public Latitude: number,
    public Longitude: number,
    public LocationMap: string,
    public City: string,
    public State: string,
    public Zip: string,
    public Phone: string,
    public PhoneHref: string,
    public Fax: string,
    public FaxHref: string,
    public Email: string,
    public EmailHref: string
  ) {
  }
}

