import { Component, OnInit, Pipe, Renderer } from '@angular/core';
import { Router } from '@angular/router';
// import {  FORM_DIRECTIVES, FormBuilder, Control, ControlGroup, Validators, NgModel, NgControl } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { EquipmentTypePipe } from '../../pipe/equipment-type.pipe';
import { RetailUserService } from '../../service/retailuser.service';
import { APIService } from '../../service/api.service';

// import { MaterialDesignDirective } from '../../material.design.directive';
import { Observable } from 'rxjs';
import { MessagebarService } from '../../service/messagebar.service';

// import {AlertComponent} from 'ng2-bootstrap/ng2-bootstrap';
import { Moment } from 'moment/moment';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatInput } from '@angular/material/input';
import { MatList, MatListModule } from '@angular/material/list';
import { MatSpinner } from '@angular/material/progress-spinner';
import { Calendar } from 'primeng/primeng';

import * as moment from 'moment';

@Component({

  selector: 'app-training-request',
  templateUrl: './training-request.component.html',
  styleUrls: ['./training-request.component.scss'],
  providers: [APIService],
})

export class TrainingRequestComponent implements OnInit {

  public title = 'Request Training';

  public trainingTypeValues: string[] = ['Aerial Work Platform Training', 'Telehandler Training', 'Aerial Work Platform Training and Telehandler Training'];

  // Available training dates
  public trainingDates: any[];

  // form variables
  public name: string;
  public email: string;
  public company: string;
  public phone: string;
  public trainingType: string;
  public numberOfPeople: number;

  trainingRequestForm: FormGroup;

  isBusy = false;

  ddlTraingDate: FormGroup;  // handle to the traingDate drop down

  constructor(
    private router: Router,
    private apiService: APIService,
    private retailCustomerService: RetailUserService,

    private renderer: Renderer,
    private msgBar: MessagebarService) {
    this.trainingRequestForm = new FormGroup({
      txtCompany: new FormControl(),
      txtName: new FormControl('', Validators.required),
      txtPhone: new FormControl('', Validators.required),
      txtEmail: new FormControl('', Validators.required),
      ddlTrainingType: new FormControl('', Validators.required),
      // ddlTrainingDate: new FormControl(),
      // chkCustomDate: new FormControl(),
      txtNumberOfPeople: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.isBusy = true;
    // pre-populate name, company, email
    let retailUserJson: JSON = this.retailCustomerService.retailUser;
    if (retailUserJson != null) {
      this.name = retailUserJson['FirstName'] + ' ' + retailUserJson['LastName'];
      this.email = retailUserJson['Email'];
      this.company = retailUserJson['Company'];
      this.phone = retailUserJson['Phone'];

    }
    // get all the training dates
    // this.trainingDatesLoadAll();
    this.isBusy = false;
  }

  // toggleCustom(chkBox) {
  //   if (chkBox.checked === true) {
  //     this.trainingDate = this.trainingDates[0];
  //   }
  //   else {
  //     this.customDate = null;
  //   }
  // }


  trainingRequest(value) {
    try {
      this.isBusy = true;
      let dateNow = moment().format('YYYY-MM-DDTHH:mm:ss');
      let retailUserEmail = '';
      let retailUserPhone = '';

      // Only get this if the uer is logged in
      if (this.retailCustomerService.retailUser !== null) {
        retailUserEmail = this.retailCustomerService.retailUser['Email'];
        retailUserPhone = this.retailCustomerService.retailUser['Phone'];
      }

      let json = `{
      "name" : "${this.name}",
      "email" : "${this.email}",
      "company" : "${this.company}",
      "phone" : "${this.phone}",
      "trainingType" : "${this.trainingType}",
      "numberOfPeople": "${this.numberOfPeople}",
      "requestedOn": "${dateNow}",
      "customerUserEmail" : "${retailUserEmail}",
      "customerUserPhone" : "${retailUserPhone}",
      }`;

      json = json.replace(/undefined/g, '');
      console.log(json);

      this.apiService.Post('birchequipment/trainingrequest', json)
        .subscribe(data => {
          this.msgBar.addAlert('Training request submitted', 'success');
          this.router.navigate(['/specials']);
        },
        err => {
          this.msgBar.addAlert('Unable to submit training request', 'danger');
          this.isBusy = false;
        },
        () => {
          this.isBusy = false;
          history.back();
        });
    } catch (error) {
      this.isBusy = false;
      let msg = 'Error requesting training ';
      console.log(<any>error);
      if (typeof error === 'string' && error.length > 0) {
        msg += error;
      }
      this.msgBar.addAlert(msg, 'danger');
      this.isBusy = false;
    }
  }

  onCancel() {
    if (this.trainingRequestForm.dirty) {
      // warn about leaving page
      let leave: boolean = confirm('You have unsaved data! Are you sure you want to leave?');
      if (!leave) {
        return;
      }
    }
    history.back();
  }
}


