import { ErrorHandler, Injectable, Inject } from "@angular/core";
import { NgModule } from "@angular/core";

import { APIService } from './service/api.service';
import { MessagebarService } from './service/messagebar.service';


export class BirchErrorHandler implements ErrorHandler {
    constructor( @Inject(APIService) private apiService: APIService,
    @Inject(MessagebarService) private msgBar: MessagebarService) {

    }
    public log(type: string, error: any, componentName: string = null, methodName: string = null) {
        //debugger;
        try {
            let json = `{
            "Type" : "${type}",
            "AppName" : "Product",
            "ComponentName" : "${componentName}",
            "MethodName" : "${methodName}",
            "ErrorMessage" : "${error}"           
            }`;

            // log it then send it to the server
            json = json.replace(/undefined/g, '');
            console.log(json);
            this.apiService.Post('birchequipment/error', json)
                .subscribe(data => {
                    console.log(data);
                },
                err => {
                    console.log(err);
                });
        }
        catch (error) {
            let msg = 'Error logging error ';
            console.log(<any>error);
            if (typeof error === 'string' && error.length > 0) {
                msg += error;
            }
            this.msgBar.addAlert(msg, 'danger');
        }
    }

    // Un handled errors
    public handleError(error: any): void {
        //debugger;
        console.log(error.toString());
        this.msgBar.addAlert('An unexpected error has occured - ' + error.toString(), 'danger');
        this.log('Error', error.toString(), 'unhandled', 'unknown');
    }
}

@NgModule({
    providers: [
        {
            provide: ErrorHandler,
            useClass: BirchErrorHandler
        }
    ],
    //imports: [APIService, MessagebarService],
    //bootstrap: [APIService, MessagebarService]
})
export class BirchErrorModule { }
