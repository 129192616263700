import { Component, OnInit, TemplateRef, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'cart-submit-dialog',
    templateUrl: 'cart-submit-dialog.html',
    styleUrls: ['cart-submit-dialog.scss'],
})

export class CartSubmitDialog {

    constructor(public dialogRef: MatDialogRef<CartSubmitDialog>) { }
    onNoClick(): void {
        this.dialogRef.close();
      }
}


