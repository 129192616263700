import { NgModule, ModuleWithProviders, Pipe, Renderer, Provider, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule  } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { Http, XHRBackend, HttpModule, RequestOptions, JsonpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf, NgClass, NgStyle } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// import { enableProdMode } from '@angular/core';
// import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
// import {createOverlayContainer} from '@angular/material/core/overlay/overlay-container';

// Third Party

// Product components
import { AppComponent } from './app.component';
import { AuthGuard } from './app.auth.guard';
import { CanDeactivateGuard} from './app.deactivate.guard';
import { routing } from './app.Routes';
import { LoginComponent } from './login';
import { CreateAccountComponent } from './create-account';
import { OrderHistoryComponent } from './order-history';
import { CartComponent } from './cart';
import { DeliveryComponent } from './cart/cart-delivery';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { ItemsComponent } from './items';
import { ItemsDetailComponent } from './items/detail';
import { ItemsAllComponent } from './items/all';
import { ItemSettingsComponent } from './items/item-settings/item-settings.component';

import { SpecialsComponent } from './specials';
// import { ReportComponent } from './+report';
import { TrainComponent } from './+train';
import { TrainingRequestComponent } from './+train/training-request';
// import { MaintenanceComponent } from './+maintenance';
import { MaintenanceRequestComponent } from './+maintenance/+maintenance-request';
import { PartsRequestComponent } from './parts-request';
import { PurchaseQuoteComponent } from './purchase-quote';

// material
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
//    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
//    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatSidenavModule,
    MatListModule
} from '@angular/material';


// import {
//     MatLiveAnnouncer,
//     MatRippleModule,
//     RtlModule,
//     PortalModule,
//     OverlayModule
// } from '@angular/material/core';

// import {MdGestureConfig} from '@angular/material/core/gestures/MdGestureConfig';
// import {MdIconRegistry} from '@angular/material/icon/icon-registry';
// import {MatSpinner} from '@angular/material/progress-circle/progress-circle';
// import {Dir} from '@angular/material/core/rtl/dir';



// UI
import {CalendarModule, DropdownModule } from 'primeng/primeng';
import {MenuModule} from 'primeng/primeng';
import {MenubarModule} from 'primeng/primeng';
import {CarouselModule} from 'primeng/primeng';
import {DataTableModule, SharedModule } from 'primeng/primeng';
import {DialogModule} from 'primeng/primeng';

import { BsModalModule  } from 'ng2-bs3-modal';
// import { MODAL_DIRECTIVES } from 'ng2-bs3-modal/ng2-bs3-modal';
// import { MaterialDesignDirective } from './material.design.directive';
import { AlertModule, BsModalService, ComponentLoaderFactory, PositioningService, ModalModule } from 'ngx-bootstrap';
// Services
import { APIService } from './service/api.service';
import { ReportService } from './service/report.service';
import { RetailUserService } from './service/retailuser.service';
import { MessagebarService } from './service/messagebar.service';
import { ItemService } from './service/item.service';
import { ProjectService } from './service/project.service';

// Pipes
import { LargeImagePipe } from './pipe/large-image-pipe';
import { EquipmentTypePipe} from './pipe/equipment-type.pipe';
import { ShiftPipe} from './pipe/shift-pipe';

import {BirchErrorHandler} from './error.module';
import {BirchErrorModule } from './error.module';
import { UsedComponent } from './used/used.component';
import { CartSubmitDialog } from './cart/cart-submit-dialog';
import { CartLoginDialog } from './cart/cart-login-dialog';
import { DetailConfirmDialog } from './items/detail/detail-confirm-dialog/detail-confirm-dialog';
import { DetailNoProjectDialog } from './items/detail/detail-no-project-dialog/detail-no-project-dialog';
import { UnlistedEquipmentDialog } from './items/all/unlisted-equipment-dialog/unlisted-equipment-dialog';
import { PageLeaveConfirmDialog } from './page-leave-confirm-dialog/page-leave-confirm-dialog';
// import {MaterialDesignDirective} from './material.design.directive';

// import {FORM_PROVIDERS} from '@angular/common';
// import { disableDeprecatedForms, provideForms } from '@angular/forms';
// import {MdLiveAnnouncer} from '@angular/material/core/live-announcer/live-announcer';

// import { GoogleAnalyticsEventsService } from "./service/google-analytics-events.service";

@NgModule({
    imports: [
        routing,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        RouterModule,
        HttpModule,
        // NgForm, NgClass, NgIf, NgStyle,

        // material
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatSelectModule,
        MatInputModule,
        MatListModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        // MdRippleModule.forRoot(),
        MatSidenavModule,
        MatTabsModule,
        MatToolbarModule,
        // PortalModule.forRoot(),
        // RtlModule.forRoot(),

        // These modules include providers.
        // MatButtonToggleModule.forRoot(),
        MatDialogModule,
        MatIconModule,
        // MdMenuModule.forRoot(),
        MatRadioModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        // MdSliderModule.forRoot(),
        // MdSlideToggleModule.forRoot(),
        // MdTooltipModule.forRoot(),
        // OverlayModule,
        // MaterialDesignDirective,
        BrowserAnimationsModule,

        // primeNg
        MenuModule,
        MenubarModule,
        CalendarModule,
        DropdownModule,
        CarouselModule,
        DataTableModule,
        DialogModule,
        SharedModule,

        AlertModule.forRoot(),
        BsModalModule,
        BirchErrorModule

        // MODAL_DIRECTIVES,
    ],
    declarations: [
        AppComponent,

        LargeImagePipe,
        ShiftPipe,
        EquipmentTypePipe,
        LoginComponent,
        CreateAccountComponent,
        OrderHistoryComponent,
        CartComponent,
        DeliveryComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        ItemsComponent,
        ItemsDetailComponent,
        ItemsAllComponent,
        ItemSettingsComponent,
        SpecialsComponent,
        TrainComponent,
        TrainingRequestComponent,
        MaintenanceRequestComponent,
        PartsRequestComponent,
        PurchaseQuoteComponent,
        UsedComponent,
        CartSubmitDialog,
        CartLoginDialog,
        DetailConfirmDialog,
        DetailNoProjectDialog,
        UnlistedEquipmentDialog,
        PageLeaveConfirmDialog,
    ],
    providers: [
        APIService,
        ReportService,
        RetailUserService,
        MessagebarService,
        ItemService,
        ProjectService,
        AuthGuard,
        CanDeactivateGuard,
        // MdLiveAnnouncer,
        BirchErrorHandler,

        // GoogleAnalyticsEventsService,

        // { provide: OVERLAY_CONTAINER_TOKEN,  useValue: createOverlayContainer() },
        // { provide: HAMMER_GESTURE_CONFIG,  useClass: MdGestureConfig }
    ],
    entryComponents: [
        CartSubmitDialog,
        CartLoginDialog,
        DetailConfirmDialog,
        DetailNoProjectDialog,
        UnlistedEquipmentDialog,
        PageLeaveConfirmDialog
    ],
    bootstrap: [AppComponent],
})

export class AppModule { }
