import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Item, ItemService } from '../../../service/item.service';

@Component({
  selector: 'app-unlisted-equipment-dialog',
  templateUrl: './unlisted-equipment-dialog.html',
  styleUrls: ['./unlisted-equipment-dialog.scss']
})
export class UnlistedEquipmentDialog implements OnInit {

  public otherItem: Item;

  constructor(public dialogRef: MatDialogRef<UnlistedEquipmentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.otherItem = data;
  }

  ngOnInit() {

  }

  CancelClick() {
    this.dialogRef.close();
  }

  AddOtherToCart() {
    this.dialogRef.close(this.otherItem);
  }
}
